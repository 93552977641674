import { actionTypes } from "./../../types/editor/actions.types";
import { useState } from "react";
import * as MonacoCollabExt from "@convergencelabs/monaco-collab-ext";
import { useSelector, useDispatch } from "react-redux";
//store
import { editorSelectors } from "../../store/editor/editor.selectors";
import {
  setContentManager,
  setLocalAction,
} from "../../store/editor/editor.actions";
import * as types from "../../types/editor";

const useSharedData = () => {
  const dispatch = useDispatch();
  const { isReadOnly, editor } = useSelector(editorSelectors.getAllState);

  const initSharedData = (): void => {
    const contentManager = new MonacoCollabExt.EditorContentManager({
      editor: editor as any,

      onInsert(index, text) {
        editor?.updateOptions({ readOnly: false });
        //handle and dispatch actions to state
        !isReadOnly &&
          dispatch(
            setLocalAction({
              actionType: types.EditorActionsEnum.insert,
              data: { index, text },
            })
          );
        editor?.updateOptions({ readOnly: isReadOnly });
      },
      onReplace(index, length, text) {
        editor?.updateOptions({ readOnly: false });
        //handle and dispatch actions to state
        !isReadOnly &&
          dispatch(
            setLocalAction({
              actionType: types.EditorActionsEnum.replace,
              data: { index, length, text },
            })
          );
        editor?.updateOptions({ readOnly: isReadOnly });
      },
      onDelete(index, length) {
        editor?.updateOptions({ readOnly: false });
        //handle and dispatch actions to state
        !isReadOnly &&
          dispatch(
            setLocalAction({
              actionType: types.EditorActionsEnum.delete,
              data: { index, length },
            })
          );
        editor?.updateOptions({ readOnly: isReadOnly });
      },
    });

    dispatch(setContentManager(contentManager));
  };

  return [initSharedData];
};

export default useSharedData;
