import React from 'react';

function KeyboardShortcuts(props) {
    
    return(
        <>
            <div className="keyboardShortcuts__content">
                <div className="keyboardShortcuts__content__labels">
                    <span>{props.textData.run}</span>
                    <span className='keyboardShortcuts__content__labels__shortcut'>
                        Ctrl + Enter
                    </span>
                </div>
                <div className="keyboardShortcuts__content__labels">
                    <span>{props.textData.submit}</span>
                    <span className='keyboardShortcuts__content__labels__shortcut'>
                        Ctrl + Alt + Enter
                    </span>
                </div>
                <div className="keyboardShortcuts__content__labels">
                    <span>{props.textData.save}</span>
                    <span className='keyboardShortcuts__content__labels__shortcut'>
                        Ctrl + S
                    </span>
                </div>
            </div>
        </>
    )
}

export default KeyboardShortcuts