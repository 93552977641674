import {
  RemoteSelectionManager,
  RemoteCursorManager,
  EditorContentManager,
} from "@convergencelabs/monaco-collab-ext";

import * as monaco from "monaco-editor";

export enum actionTypes {
  SET_EDITOR = "LONG_BLOB/live-coding/SET_EDITOR",
  SET_SELECTION_MANAGER = "LONG_BLOB/live-coding/SET_SELECTION_MANAGER",
  SET_CURSOR_MANAGER = "LONG_BLOB/live-coding/SET_CURSOR_MANAGER",
  SET_CONTENT_MANAGER = "LONG_BLOB/live-coding/SET_CONTENT_MANAGER",
  SET_READ_ONLY = "live-coding/SET_READ_ONLY",
  SET_LOCAL_ACTION = "live-coding/SET_LOCAL_ACTION",
  RESET_EDITOR_STATE = "live-coding/RESET_EDITOR_STATE",
  SET_REMOTE_PARTICIPANTS = "LONG_BLOB/live-coding/SET_REMOTE_PARTICIPANTS",
  SET_LOCAL_PARTICIPAN = "live-coding/SET_LOCAL_PARTICIPAN",
  SET_ROOM_ID = "live-coding/SET_ROOM_ID",
  SET_MODAL = "live-coding/SET_MODAL",
  SET_MODAL_CLOSE = "live-coding/SET_MODAL_CLOSE",
  SET_SETTING = "live-coding/SET_SETTING",
  SET_MARKERS = "live-coding/SET_MARKERS",
  SET_USER_ROLE = "live-coding/SET_USER_ROLE",
  SET_USER_NAME = "live-coding/SET_USER_NAME",
  SET_OUTPUT = "live-coding/SET_OUTPUT",
  SET_CURRENT_USER = "live-coding/SET_CURRENT_USER",
}

export type Actions =
  | SetEditor
  | SetSelectionManager
  | SetCursorManager
  | SetContentManager
  | SetReadOnly
  | SetLocalAction
  | ResetEditorState
  | SetRemoteParticipants
  | SetLocalParticipant
  | SetRoomId
  | SetModal
  | SetSetting
  | SetModalClose
  | SetMarkers
  | SetUserRole
  | SetUserName
  | SetOutput
  | SetCurrentUser;


export interface SetEditor {
  type: actionTypes.SET_EDITOR;
  payload: monaco.editor.IStandaloneCodeEditor;
}

export interface SetSelectionManager {
  type: actionTypes.SET_SELECTION_MANAGER;
  payload: RemoteSelectionManager;
}

export interface SetCursorManager {
  type: actionTypes.SET_CURSOR_MANAGER;
  payload: RemoteCursorManager;
}

export interface SetContentManager {
  type: actionTypes.SET_CONTENT_MANAGER;
  payload: EditorContentManager;
}

export interface SetReadOnly {
  type: actionTypes.SET_READ_ONLY;
  payload: boolean;
}

export interface SetLocalAction {
  type: actionTypes.SET_LOCAL_ACTION;
  payload: any;
}

export interface ResetEditorState {
  type: actionTypes.RESET_EDITOR_STATE;
}

export interface SetRemoteParticipants {
  type: actionTypes.SET_REMOTE_PARTICIPANTS;
  payload: any;
}

export interface SetLocalParticipant {
  type: actionTypes.SET_LOCAL_PARTICIPAN;
  payload: any;
}

export interface SetRoomId {
  type: actionTypes.SET_ROOM_ID;
  payload: any;
}

export interface SetModal {
  type: actionTypes.SET_MODAL;
  payload: any | null;
}

export interface SetModalClose {
  type: actionTypes.SET_MODAL_CLOSE;
  payload: Boolean;
}

export interface SetSetting {
  type: actionTypes.SET_SETTING;
  payload: any | null;
}

export interface SetMarkers {
  type: actionTypes.SET_MARKERS;
  payload: monaco.editor.IMarkerData[];
}

export interface SetUserRole {
  type: actionTypes.SET_USER_ROLE;
  payload: any | null;
}

export interface SetUserName {
  type: actionTypes.SET_USER_NAME;
  payload: any | null;
}

export interface SetOutput {
  type: actionTypes.SET_OUTPUT;
  payload: any | null;
}

export interface SetCurrentUser {
  type: actionTypes.SET_CURRENT_USER;
  payload: any | null;
}
