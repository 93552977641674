import axios from "axios";
const token: any = localStorage.getItem('token')
const config = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    },
}
const fetchData = async (api) => {
    const res = await axios.get(api, config)
    if (res && res.data) {
        return res.data
    }
}
export const postData=async (api,data)=>{
    const res = await axios.post(api,data, config)
    if (res && res.data) {
        return res.data
    }
}
export const deleteData=async (api,data)=>{
    const res = await axios.delete(api, {...config,data:data})

    if (res && res.data) {
        return res.data
    }
}
export const patchData=async (api,data)=>{
    const res = await axios.patch(api, data,config)
    if (res && res.data) {
        return res.data
    }
}
export default fetchData
