import { Provider } from "react-redux";
import { makeStore } from "../config/store";
//pages
import { LiveCodingPage } from "../pages/live-coding";
import { VideoInterview } from "../pages/video-interview";
import OfflineCodingPage from "../pages/offline-coding";
import { PlaybackPage } from "../pages/playback";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { EditorModals } from "../components/code-editor/modals/editor-modals";
import "../styles/main.scss";
import { permissionRoutesType, UnAuthorizePageComponent, useHasPermission, NotFoundPageComponent, useHasSubscription } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const companyRoutes: Array<permissionRoutesType> = [
  {
    path: '/live-coding/playback/:collectionId',
    element: <PlaybackPage />,
    module: ["assessment"],
    permissions: "view"
  },
]

const candidateRoutes: Array<permissionRoutesType> = [
  {
    path: '/live-coding/offline/:jobId/:assessmentId',
    element: <OfflineCodingPage />,
    module: ["candidate"],
    permissions: ""
  },
];

const candidateCompanyRoutes: Array<permissionRoutesType> = [
  {
    path: "/live-coding/interview/:room",
    element: <VideoInterview />,
    module: ["recruiter", "candidate"],
    permissions: ""
  },
  {
    path: '/live-coding/online/:room',
    element: <LiveCodingPage />,
    module: ["recruiter", "candidate"],
    permissions: ""
  },
  {
    path: '/live-coding/online',
    element: <LiveCodingPage />,
    module: ["recruiter", "candidate"],
    permissions: ""
  },
]

export default function Root(props) {
  const {onAuthorizedRoutes, companyId} = useHasPermission()
  const { isLoadingSubscription, hasCompanySubscriptionElement } = companyId && useHasSubscription();
  return (
    <Provider store={makeStore()}>
      <BrowserRouter>
        <Routes>
          {companyId && companyRoutes.map(route => {
            return <Route path={route.path} key={route.path} element={ hasCompanySubscriptionElement( onAuthorizedRoutes(route.module, route.permissions))} />
          })}

          {!companyId && candidateRoutes.map(route => {
            return <Route path={route.path} key={route.path} element={onAuthorizedRoutes(route.module, route.permissions) ? route.element : <UnAuthorizePageComponent />} />
          })}

          {!companyId ? candidateCompanyRoutes.map(route => {
            return <Route path={route.path} key={route.path} element={onAuthorizedRoutes(route.module, route.permissions) ? route.element : <UnAuthorizePageComponent />} />
          }) :
            candidateCompanyRoutes.map(route => {
              return <Route path={route.path} key={route.path} element={hasCompanySubscriptionElement( onAuthorizedRoutes(route.module, route.permissions) ? route.element:<UnAuthorizePageComponent/>)} />
            }) 
          }

          <Route path={`/live-coding/*`} element={companyId && isLoadingSubscription ? <h1>Loading...</h1> :<NotFoundPageComponent />} />

        </Routes>
      </BrowserRouter>
      <EditorModals />
    </Provider>
  );
}