import {DataTypes} from "../../../coding-assessments/types/assessmentTask";
import {getArrayInputs} from "../../../coding-assessments/utils/testcase";
import {encode} from "base-64";
export const getReplacedCodeList = (testcaseList,testCaseInputs,source_code,activeLanguageId)=>{
      
  return testcaseList.map(testCase=>{
        const paramsList = JSON.parse(testCase.input);
  
        let replaced_source_code = source_code;
        for (const param of paramsList) {
          const paramIdentifier = Object.keys(param)[0];
          let paramValue = JSON.stringify(Object.values(param)[0]);
          const paramArrayLength = Array.isArray(Object.values(param)[0])
            ? JSON.parse(paramValue).length
            : null;
          const paramType = testCaseInputs.find(
            (el) => el.name === paramIdentifier
          )?.type;
  
          if (
            paramType === DataTypes.ARRAY_INTEGER ||
            paramType === DataTypes.ARRAY_STRING
          ) {
            paramValue = getArrayInputs(
              paramValue,
              activeLanguageId
            )?.array_string;
          }
          const regex = new RegExp(`{{${paramIdentifier}}}`, "g");
          const regexForLength = new RegExp(
            `{{${paramIdentifier + "_length"}}}`,
            "g"
          );
  
          replaced_source_code = replaced_source_code.replace(regex, paramValue);
       }
       return {
        language_id:activeLanguageId,
        source_code: encode(replaced_source_code)?.toString("base64"),
        testcaseId: testCase.id,
        testcaseOutput: testCase.output 
       }
})
}