import { videoMeeting as types } from "../../types";
import * as actions from "../../types/video-meeting/actions.types"

export const setVideoMeetingData = (
  payload: types.State["videoMeetingData"]
): actions.SetVideoMeetingData => ({
  type: types.actionTypes.SET_VIDEO_MEETING,
  payload,
});

export const setIsEndInterview = (
  payload: types.State["isEndInterView"]
): actions.SetIsEndInterview => ({
  type: types.actionTypes.SET_IS_END_INTERVIEW,
  payload,
});

export const setAllParticipants = (
  payload: types.State["allParticipants"]
): actions.SetAllParticipants => ({
  type: types.actionTypes.SET_ALL_PARTICIPANTS,
  payload,
});

export const SetStream = (
  payload: types.State["stream"]
): actions.SetStream => ({
  type: types.actionTypes.SET_STREAM,
  payload,
});

export const SetMeetingStream = (
  payload: types.State["meetingStream"]
): actions.SetMeetingStream => ({
  type: types.actionTypes.SET_MEETING_STREAM,
  payload,
});

export const setIsSnackBarOpen = (
  payload: {
    status: boolean;
    message: string;
  }
): actions.SetIsSnackBarOpen => ({
  type: types.actionTypes.SET_IS_SNACKBAR_OPEN,
  payload,
});