import React from "react"

function Header() {
    const Send = require("./../../../assets/icons/Send.svg");

    return(
        <div className='inviteCandidate__header'>
            <img className='inviteCandidate__sendIcon' src={Send} alt='Send' />
            <span className='inviteCandidate__header-label'>Send Email Invite</span>
        </div>
    )
}

export default Header;