import React, { useState } from "react";
import moment from "moment";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";

const InvitionModal = () => {
  const [inviteModalActive, setInviteModalActive] = useState(false);
  const [initialDay, setInitialDay] = useState(moment());
  const [time, setTime] = useState("00:00");
  const [timeType, setTimeType] = useState("AM");
  const [interviewers, setInterviewers] = useState(); 

  return (
    <div className="inviteCandidate">
      <Header />
      <Content
        key={Date.now()}
        inviteModalActive={inviteModalActive}
        setInviteModalActive={setInviteModalActive}
        initialDay={initialDay}
        setInitialDay={setInitialDay}
        time={time}
        setTime={setTime}
        timeType={timeType}
        setTimeType={setTimeType}
        setInterviewers = {setInterviewers}
        interviewers = {interviewers}
      />
      <Footer interviewers={interviewers} setInterviewers={setInterviewers}/>
    </div>
  );
};

export default InvitionModal;
