import * as MonacoCollabExt from '@convergencelabs/monaco-collab-ext';
import { useSelector, useDispatch } from 'react-redux'
//store
import { setCursorManager } from '../../store/editor/editor.actions'
import { editorSelectors } from '../../store/editor/editor.selectors';
import * as monaco from 'monaco-editor'

const useCursor = () => {
  const dispatch = useDispatch()
  const editor = useSelector(editorSelectors.getEditor)

  const initSharedCursors = () => {
    const remoteCursorManager = new MonacoCollabExt.RemoteCursorManager({
      editor: editor as any,
      tooltips: true,
      tooltipDuration: 2
    });
      
    dispatch(setCursorManager(remoteCursorManager))
  }

  return [initSharedCursors]
}

export default useCursor
