import { fromMonaco } from "@hackerrank/firepad";
import { getEnv } from "@urecruits/api";
import * as monaco from "monaco-editor";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { uuidv4 } from "../../../coding-assessments/config/utils";
import fetchData, { postData } from "../../hooks/api/postData";
import { assessmentSelectors } from "../../store/assessment/assessment.selectors";
import {
  setLocalParticipant,
  setMarker,
  setRoomId,
} from "../../store/editor/editor.actions";
import { editorSelectors } from "../../store/editor/editor.selectors";
import CodeEditor from "../_elements/CodeEditor";
import 'firebase/database';
import firebase from "../../config/firebaseFirestore";
import { videoMeetingSelectors } from "../../store/video-meeting/assessment.selectors";
import { useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_RECRUITMENT, API_ASSESSMENT } = getEnv();

export const EditorOnline = ({ editorContainerRef, isWidthChanged,user }) => {
  const {checkUserPermission} = useHasPermission()
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const firepadRef = useRef(null);

  const { editor,roomId } = useSelector(
    editorSelectors.getAllState
  );

  const { activeTask, assessmentTask } = useSelector(
    assessmentSelectors.getAllState
  );

  const allParticipants = useSelector(
    videoMeetingSelectors.getAllParticipants
  );

  const { room } = useParams();
  const [collectionName, setCollectionName] = useState(null);

  useEffect(() => {
   room && checkUserPermission(['recruiter','candidate']) && fetchData(`${API_ASSESSMENT}/api/calendar/room-event/${room}`).then(
      (res) => {
        setCollectionName(`live-coding-${res?.id}${res?.jobId}${res?.candidateId}`);
        assessmentTask && postData(`${API_RECRUITMENT}/api/playback`, {
          playbackId: `live-coding-${res?.id}${res?.jobId}${res?.candidateId}`,
          assessmentType: "live-coding",
          candidateId: res?.candidateId,
          jobId: res?.jobId,
          assessmentId: assessmentTask ? assessmentTask?.id : 1,
        });
      }
    );
  }, [room, assessmentTask]);

  const database =
    collectionName && firebase.database()?.ref(`${collectionName}`);

  const socketRoomId =room && room?.substring(0, 5);
// console.log('database ',database);

  useEffect(() => {
    if (!activeTask || !editor) {
      return;
    }
    editor.focus();
    editor.setValue(activeTask.data.starterCode);
  }, [activeTask, editor]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    const initializeFirebase = async () => {
      dispatch(setRoomId(roomId));
      const name = user?.name ? user?.name : allParticipants[0]
      const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      const userId = uuidv4()
      const userData = { id: user?.id, name:name, room: socketRoomId, color: color };
      
      firepadRef.current= fromMonaco(database,editor, {
        userColor: color,
        userId: userId,
        userName: name,
      });
      
      dispatch(setLocalParticipant(userData));
    };

    !!database && initializeFirebase();
  }, [collectionName, editor]);

  useEffect(() => {
    monaco?.editor?.onDidChangeMarkers(async ([uri]) => {
      const markers = monaco?.editor?.getModelMarkers({ resource: uri });
      dispatch(setMarker(markers));
    });
  }, [dispatch]);


  return (
    <div className="editor">
      <CodeEditor
        editorRef={editorRef}        
        editorContainerRef={editorContainerRef}
        isWidthChanged={isWidthChanged}
      />
    </div>
  );
};
