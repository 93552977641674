import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
//store
import { setActiveTask } from "../../store/assessment/assessment.actions";
import { assessmentSelectors } from "../../store/assessment/assessment.selectors";
//components
import CodingSelect from "../_elements/CodingSelect";
import { ArrowLeft, ArrowRight } from "../../assets/elements/arrows";
import { editorSelectors } from "../../store/editor/editor.selectors";

export const Problem = ({ problems }) => {
  const dispatch = useDispatch();
  const { activeTask, isOnline } = useSelector(assessmentSelectors.getAllState);
  const { modalClose } = useSelector(editorSelectors.getAllState);
  const [options, setOptions] = useState(null);

  useEffect(() => {
    if (problems) {
      setOptions(
        problems.map((problem, index) => {
          return {
            index,
            label: problem?.name,
            data: problem,
          };
        })
      );
    }
  }, [problems,modalClose]);

  useEffect(() => {
    if (options && !activeTask) {
      dispatch(setActiveTask(options[0]));
    }
  }, [options]);

  const setPrevTask = () => {
    if (activeTask.index === 0) {
      return;
    }
    dispatch(setActiveTask(options[activeTask.index - 1]));
  };

  const setNextTask = () => {
    if (activeTask.index + 1 === options.length) {
      return;
    }

    dispatch(setActiveTask(options[activeTask.index + 1]));
  };

  const activeProblem = useMemo(() => {
    if (!activeTask) {
      return;
    }
    return problems.find((problem) => problem.id === activeTask.data.id)
  }, [activeTask]);

  return (
    <>
      {options && activeTask && (
        <div className="taskDescription__wrapper">
          <div
            className={cn("taskDescription__header", { isOnline: isOnline })}
          >
            {isOnline && options.length <= 1 ? (
              <p className="taskDescription__title">{activeProblem?.name}</p>
            ) : (
              <>
                <CodingSelect
                  noBorder
                  options={options}
                  selected={activeTask}
                  setSelected={(task) => dispatch(setActiveTask(task))}
                  className="taskDescription__select"
                />
                <div className="taskDescription__slider">
                  <p>
                    Problem <span>{activeTask.index + 1}/</span>
                    <span>{options.length}</span>
                  </p>
                  <button
                    className={cn("taskDescription__control prev", {
                      disable: activeTask.index === 0,
                    })}
                    onClick={setPrevTask}
                  >
                    <ArrowLeft />
                  </button>
                  <button
                    className={cn("taskDescription__control next", {
                      disable: activeTask.index + 1 === options.length,
                    })}
                    onClick={setNextTask}
                  >
                    <ArrowRight />
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="taskDescription__content scroll">
            <p>{activeProblem?.description}</p>
          </div>
        </div>
      )}
    </>
  );
};
