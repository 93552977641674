export enum actionTypes {
  SET_CHAT_TOKEN = "conversation/SET_CHAT_TOKEN",
  SET_CHAT_OPEN = "conversation/SET_CHAT_OPEN",
  SET_ROOM = "conversation/SET_ROOM",
  SET_CONVERSATION = "conversation/SET_CONVERSATION",
  SET_PARTICIPANT_ID = "conversation/SET_PARTICIPANT_ID",
  SET_USER_IDENTITY = "conversation/SET_USER_IDENTITY",
  SET_CLIENT = "conversation/SET_CLIENT",
  SET_UNIQUE_NAME = "conversation/SET_UNIQUE_NAME",
  SET_MESSAGE = "conversation/SET_MESSAGE",
  SET_SID_FROM_ROUND = "conversation/SET_SID_FROM_ROUND",
  SET_JOIN_CHANNEL = "conversation/SET_JOIN_CHANNEL",
  SET_CREATE_CHANNEL = "conversation/SET_CREATE_CHANNEL",
}

export type Actions =
  | SetIsChatOpen
  | SetRoom
  | SetChannel
  | SetParticipantSid
  | SetUserIdentity
  | SetClient
  | SetUniqNameForConversation
  | SetMessage
  | SetSidForRoom
  | SetJoinChannel
  | SetCreateChannel
  | SetChatToken

  export interface SetIsChatOpen {
    type: actionTypes.SET_CHAT_OPEN;
    payload: boolean;
  }

  export interface SetRoom {
    type: actionTypes.SET_ROOM;
    payload: any;
  }

  export interface SetChannel {
    type: actionTypes.SET_CONVERSATION;
    payload: any;
  }
  

  export interface SetParticipantSid {
    type: actionTypes.SET_PARTICIPANT_ID;
    payload: any;
  }

  export interface SetUserIdentity {
    type: actionTypes.SET_USER_IDENTITY;
    payload: any;
  }
  

  export interface SetClient {
    type: actionTypes.SET_CLIENT;
    payload: any;
  }
  
  export interface SetUniqNameForConversation {
    type: actionTypes.SET_UNIQUE_NAME;
    payload: any;
  }
  
  export interface SetMessage {
    type: actionTypes.SET_MESSAGE;
    payload: any;
  }
  
  export interface SetSidForRoom {
    type: actionTypes.SET_SID_FROM_ROUND;
    payload: any;
  }
  
  export interface SetJoinChannel {
    type: actionTypes.SET_JOIN_CHANNEL;
    payload: any;
  }

  export interface SetCreateChannel {
    type: actionTypes.SET_CREATE_CHANNEL;
    payload: any;
  }

  export interface SetChatToken {
    type: actionTypes.SET_CHAT_TOKEN;
    payload: any;
  }

  