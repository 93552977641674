import React from "react";

function WelcomePage({ textData }) {
  return (
    <div className="listOfQuestions__display__welcomePage">
      <div className="listOfQuestions__display__startTitle">
        {textData.welcomeTitle}
      </div>

      <div className="listOfQuestions__display__startDescription">
        <p>{textData.welcomeDescription[0]}</p></div>
      <div className="listOfQuestions__display__startDescription">
        <p>{textData.welcomeDescription[1]}</p></div>

      <button className="c-btn c-btn--green c-btn-big">Create Question</button>
    </div>
  );
}

export default WelcomePage
