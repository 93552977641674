import React, { useEffect, useState } from 'react'
import QuestionItem from './question_item'
import QuestionLoader from "../../QuestionLoader"
import { assessmentSelectors } from '../../../../../store/assessment/assessment.selectors';
import { useSelector } from 'react-redux';
import fetchData from '../../../../../hooks/api/fetchData';
import { getEnv } from "@urecruits/api";
const {API_ASSESSMENT}=getEnv()
    
interface QuestionsProps {
    showDisplay: (id: string) => any
    filterValue;
    searchValue
}

function Questions({ showDisplay,filterValue,searchValue}: QuestionsProps) {

    const [selected, setSelected] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const { isOnline } = useSelector(assessmentSelectors.getAllState);
    const [currentItems, setCurrentItems] = useState([]);
    const [api, setApi] = useState([]);

    useEffect(()=>{
        setIsLoading(true)
        fetchData(`${API_ASSESSMENT}/api/${isOnline ? "live-coding" : "take-home-task"}`,setData)
    },[])
    
    useEffect(()=>{setApi(data)},[data])

    useEffect(()=>{
        if(data.length>0){
            setIsLoading(false)
        }
    },[data])
    
    const selectItem = (id) => {
        showDisplay(id)
        setSelected(id)
    }


    const filterData = (data, searchValue,filterValue) => {
        if(searchValue && filterValue){
          const search=data.filter(item => {
            return item.name.toLowerCase().includes(searchValue.toLowerCase());
          });
          const filter= search.filter(item => {
           return isOnline ? item?.languageId === filterValue?.id :item?.questions?.find(lang => lang?.languageId === filterValue?.id)
          });
          return filter
        }
        else if(filterValue){
            const filter = data.filter(item => {
              return isOnline ? item?.languageId === filterValue?.id :item?.questions?.find(lang => lang?.languageId === filterValue?.id)
            });
          return filter;
        }else if (searchValue) {
          return data.filter(item => {
            return item.name.toLowerCase().includes(searchValue.toLowerCase());
          });
        }else{
          return data;
        }
        };

        useEffect(() => {
            if(searchValue || filterValue){
                setCurrentItems(filterData(data, searchValue,filterValue));
            }else if(searchValue==="" || filterValue===""){
                setApi(data)  
            }
          }, [searchValue,filterValue]);
          useEffect(()=>{setApi(currentItems)},[currentItems])

    const questionItems = api?.map((td )=> {        
       return( <div onClick={() => selectItem(td.id)} key={td.id} className={selected === td.id ? 'listOfQuestions__selected_question_item' : 'listOfQuestions__question_item'}>
            <QuestionItem title={td.name} description={td.description} languages={['test']}/>
        </div>)})

    return(
        <>
            <div className='listOfQuestions__questions'>
            <div className='listOfQuestions__questions__questions_list scroll'>
            { isLoading ?
            <QuestionLoader/> :
                questionItems
            }
            </div>
        </div>
        </>
    )
}

export default Questions