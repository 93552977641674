import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyBwgLPJE8YojAvwfGMF5NE2ztLDjqWsgQg",
  authDomain: "testdb-f0e32.firebaseapp.com",
  databaseURL: "https://testdb-f0e32-default-rtdb.firebaseio.com",
  projectId: "testdb-f0e32",
  storageBucket: "testdb-f0e32.appspot.com",
  messagingSenderId: "388731634399",
  appId: "1:388731634399:web:0ccd8d2280ec84d3591f6a",
  measurementId: "G-C620ZG6KV7"
};

firebase.apps.length === 0
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();
  export default firebase;