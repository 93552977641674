import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import fetchData from "../../../hooks/api/postData";
import { getEnv } from "@urecruits/api";
const { API_RECRUITMENT, API_ASSESSMENT } = getEnv();
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { selectCustomStyle } from "../../../styles/selectCustomStyle";


function Content({
  inviteModalActive,
  setInviteModalActive,
  initialDay,
  setInitialDay,
  time,
  setTime,
  timeType,
  setTimeType,
  setInterviewers,
  interviewers
}) {
  const params = useParams();



  return (
    <div className="inviteCandidate__content">

      <AsyncSelect
        cacheOptions
        loadOptions={() => interviewersCallbackFunc(params.room)}
        isMulti
        defaultOptions
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={(currentValue: any) => {
          setInterviewers(currentValue);
        }}
        value={interviewers}
        placeholder={"Select interviewers"}
        components={{
          Option,
        }}
        styles={selectCustomStyle}
        id="invite-interviewers"
        instanceId="invite-interviewers"
      />
    </div>
  );
}

export default Content;


const interviewersCallbackFunc = async (roomId) => {
  const leadUsersData = await fetchData(
    `${API_RECRUITMENT}/api/company/get-lead-users`
  );
  const eventData =   await fetchData(`${API_ASSESSMENT}/api/calendar/room-event/${roomId}`);
  const reviewers = leadUsersData.filter(user=> {
    if(!eventData.interviewers.some(interviewer=> user.id == interviewer.id))
    return user });
  return reviewers.map((item) => {
    return {
      value: {
        id: item.id,
        name: `${item.firstname} ${item.lastname}`,
        email: item.email,
      },
      label: `${item.firstname} ${item.lastname}`,
      id: item.id,
    };
  });
};

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props} className="select-component">
        <input
          id={props.value}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={props.value}>
          <span />
        </label>
        <p>{props.label}</p>
      </components.Option>
    </div>
  );
};
