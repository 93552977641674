import React from 'react';
import ckecked from '../../assets/icons/checked.svg'

interface checkboxInterface {
  id: string;
  name?: string;
  isChecked?: boolean;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

const Checkbox:  React.FC<checkboxInterface> = ({ id, name, isChecked, onChange }) => {
  return (
    <div className="checkbox">
      <div>
        <label
          htmlFor={id}
          className={`${isChecked && "checkbox__labelActive"}`}
          style={{
            backgroundImage: isChecked ? `url(${ckecked})` : 'none',
            backgroundPosition: 'center',
            backgroundSize: '10px 8px',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <input
            type="checkbox"
            id={id}
            name={name}
            checked={isChecked}
            onChange={onChange}
          />
        </label>
      </div>
      <div></div>
    </div>
  );
};
 
export default Checkbox;
