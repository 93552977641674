import { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  BiMicrophone,
  BiMicrophoneOff,
  BiVideo,
  BiVideoOff,
} from "react-icons/bi";
import { BsChatLeftDots, BsFullscreen } from "react-icons/bs";
import { MdOutlineCallEnd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "../../styles/components/_interview-room-modal.scss";
import "../../styles/components/_meeting-preview.scss";
import Draggable from 'react-draggable';
import fetchData from "../../hooks/api/postData";
import { getEnv } from "@urecruits/api";
import RemoteParticipant from "./RemoteParticipant/RemoteParticipant";
import ParticipantList from "./RemoteParticipant/ParticipantsList";
import { useDispatch, useSelector } from "react-redux";
import { videoMeetingSelectors } from "../../store/video-meeting/assessment.selectors";
import { editorSelectors } from "../../store/editor/editor.selectors";
import { SetStream, setAllParticipants } from "../../store/video-meeting/assessment.actions";
import { setChatOpen } from "../../store/conversation/conversation.actions";
import { conversationSelectors } from "../../store/conversation/conversation.selectors";
import "../../styles/components/_interview-room-modal.scss";
import { useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const InterviewJoinRoom = ({roomId,roomObj,isAudioEnable,isVideoEnable}:RoomId) => {

  const {checkUserPermission} = useHasPermission()

  const id= roomId
  const [userName, setUserName] = useState<any>(null);
  const room= roomObj
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(true);
  const [minimizeScreen, setMinimizeScreen] = useState(false);
  const [participantLength, setParticipantLength] = useState(1);
  const [participants, setParticipants] = useState<any[]>([]);
  const [isLocalCameraEnable, setIsLocalCameraEnable] = useState(isVideoEnable);
  const [isLocalAudioEnable, setIsLocalAudioEnable] = useState(isAudioEnable);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [roomStatus,setRoomStatus]=useState("")
  const [loader,setLoader]=useState(false)
  const videoRef = useRef(null);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const {API_RECRUITMENT} = getEnv()
  const  isEndInterView  = useSelector(videoMeetingSelectors.getInterviewState);
  const  stream  = useSelector(videoMeetingSelectors.getStream);
  const  {userRole}  = useSelector(editorSelectors.getAllState);
  const dispatch= useDispatch()

  const getUser = async () => {
    try {
      const result = await fetchData(`${API_RECRUITMENT}/api/user`);
      if (result) {
        {
          setUserName(`${result?.firstname} ${result?.lastname}`);
        }
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    checkUserPermission(['recruiter','candidate']) && getUser();
  }, []);

  useEffect(() => {
    if (checkUserPermission(['recruiter','candidate']) && room) {
      const participantArray = Array.from(room.participants.values(), (p) => ({
        participant: p,
      }));
      setParticipantLength(participantArray.length + 1);
      setParticipants(participantArray);
      const handleParticipantConnected = (participant) => {
        const remoteParticiapantLength = room?.participants.size;
        setParticipantLength((prev) => prev + 1);
        setParticipants((prevParticipants) => {
          return [...prevParticipants, { participant: participant }];
        });
      };
      const handleParticipantDisconnected = (participant) => {
        const remoteParticiapantLength = room?.participants.size;
        setParticipantLength((prev) => prev - 1);
        setParticipants((prevParticipants) => {
          return prevParticipants.filter((p) => p.participant !== participant);
        });
      };
      room.on("participantConnected", handleParticipantConnected);
      room.on("participantDisconnected", handleParticipantDisconnected);
      return () => {
        room.off("participantConnected", handleParticipantConnected);
        room.off("participantDisconnected", handleParticipantDisconnected);
      };
    }
  }, [room]);



  useEffect(()=>{
    if(isVideoModalOpen){
      const videoTracks = videoRef.current
      ?.getInternalPlayer()
      ?.video?.srcObject?.getVideoTracks();
     const audioTracks = stream?.getAudioTracks();

    if (videoTracks) {
      videoTracks.forEach((track) => {
        track.enabled = !videoEnabled;
      });
    }
    if (audioTracks) {
      audioTracks.forEach((track) => {
        track.enabled = !audioEnabled;
      });
    }
  }
  })
  useEffect(()=>{
    if(userName===null){
      setLoader(true)
    }else{
      setLoader(false)
    }
  },[userName])

  const startVideoAndAudio = () => {
    const constraints = {
      video: videoEnabled,
      audio: audioEnabled,
    };

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
     navigator.mediaDevices
        .getUserMedia(constraints)
        .then((userStream) => {
          dispatch(SetStream(userStream));
        })
        .catch((error) => {
          console.error("Error accessing camera and microphone:", error);
        });
    } else {
      console.error("getUserMedia is not supported on this browser.");
    }
  };

  useEffect(() => {
    startVideoAndAudio();
  }, []);

  useEffect(()=>{
    if(isEndInterView && checkUserPermission('recruiter') ){
      room.disconnect();
      disableMediaAccess()
      fetchData(
        `${API_RECRUITMENT}/api/twilio/video-complete?roomName=${id}`
      ).then(()=>setRoomStatus("completed"))
      setMinimizeScreen(false)
      setIsVideoModalOpen(false);
    }
  },[isEndInterView])

  function disableMediaAccess() {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
      track.stop();
    })
    }
  }

  useEffect(()=>{
    if(room?.state==="disconnected"){
      disableMediaAccess()
      setMinimizeScreen(false)
      setIsVideoModalOpen(false);
    }
  },[room?.state])


  useEffect(()=>{
    const add=[room?.localParticipant?.identity]
    participants.map((participant, index) => {
    const identity = participant.participant.identity;
    add.push(identity);
  }
  )
  dispatch(setAllParticipants(add))
},[room,participants])
  return (
    <>
        {isVideoModalOpen && (
          <Modal
            setIsVideoModalOpen={setIsVideoModalOpen}
            room={room}
            participants={participants}
            participantLength={participantLength}
            setMinimizeScreen={setMinimizeScreen}
            isLocalAudioEnable={isLocalAudioEnable}
            isLocalCameraEnable={isLocalCameraEnable}
            setIsLocalAudioEnable={setIsLocalAudioEnable}
            setIsLocalCameraEnable={setIsLocalCameraEnable}
          />

        )}
        {minimizeScreen && (
          <MinimizeModal
            setMinimizeScreen={setMinimizeScreen}
            participants={participants}
            room={room}
            isLocalCameraEnable={isLocalCameraEnable}
            setIsVideoModalOpen={setIsVideoModalOpen}
            setIsLocalCameraEnable={setIsLocalCameraEnable}
            isLocalAudioEnable={isLocalAudioEnable}
            minimizeScreen={minimizeScreen}
            setIsLocalAudioEnable={setIsLocalAudioEnable}
          />
        )}
      </>
  );
};

export default InterviewJoinRoom;

export const Modal = ({
  setIsVideoModalOpen,
  room,
  participants,
  participantLength,
  isLocalAudioEnable,
  isLocalCameraEnable,
  setIsLocalAudioEnable,
  setMinimizeScreen,
  setIsLocalCameraEnable,
}) => {
  const navigate = useNavigate();
  const [participantScreenWidth, setParticipantScreenWidth] = useState(`49%`);
  const [participantScreenHeight, setParticipantScreenHeight] = useState(`49%`);
  const [loading, setLoading] = useState(false);
  const dispatch=useDispatch()


  useEffect(() => {
    if (participantLength >= 5) {
      setParticipantScreenWidth("32.5%");
    } else if (participantLength === 4 && participantScreenWidth !== "49%") {
      setParticipantScreenWidth("49%");
    }
  }, [participantLength]);

  useEffect(()=>{
    if(participantLength===1){
      setTimeout(() => {
        setLoading(false);
      }, 30000);
    }
  },[participantLength])

  const  {stream}  = useSelector(videoMeetingSelectors.getAllState);
  const  {isOpen}  = useSelector(conversationSelectors.getAllState);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 630;

  return (
    <div>
      {room && (
        <>
          <div className="interview-modal-content">
            { participantLength < 3 && (
              <div
                className={`interview-video-content ${!isLocalCameraEnable && "active"}`}>
                {participantLength === 1 ?(
                  <>
                  <RemoteParticipant
                  participant={room?.localParticipant}
                  participantIndex={0}
                  room={room}
                  isLocal={true}
                  isLocalCameraEnable={isLocalCameraEnable}
                  setIsLocalCameraEnable={setIsLocalCameraEnable}
                  isLocalAudioEnable={isLocalAudioEnable}
                  setIsLocalAudioEnable={setIsLocalAudioEnable}
                  />
                   </>
                  ):(
                  <RemoteParticipant
                  participant={participants[0]?.participant}
                  participantIndex={0}
                  room={room}
                  isLocalCameraEnable={isLocalCameraEnable}
                  setIsLocalCameraEnable={setIsLocalCameraEnable}
                  isLocalAudioEnable={isLocalAudioEnable}
                  setIsLocalAudioEnable={setIsLocalAudioEnable}
                />
                )
                }
                {(participantLength === 2) && (
                  <Draggable>
                  <div className="video-calling_participant-1">
                    <RemoteParticipant
                      participant={ room?.localParticipant}
                      participantIndex={0}
                      room={room}
                      isLocal={true}
                      isLocalCameraEnable={isLocalCameraEnable}
                      setIsLocalCameraEnable={setIsLocalCameraEnable}
                      isLocalAudioEnable={isLocalAudioEnable}
                      setIsLocalAudioEnable={setIsLocalAudioEnable}
                    />
                  </div>
                  </Draggable>
                )}
              </div>
            )}
            {participantLength >= 3  && (
              <>
                <div className="video-calling_participant-container">
                  <div
                    className="video-calling_participant-screen"
                    style={{
                      width: participantScreenWidth,
                      height: participantScreenHeight,
                    }}>
                    <RemoteParticipant
                      participant={room?.localParticipant}
                      room={room}
                      participantIndex={0}
                      isLocal={true}
                      isLocalCameraEnable={isLocalCameraEnable}
                      setIsLocalCameraEnable={setIsLocalCameraEnable}
                      isLocalAudioEnable={isLocalAudioEnable}
                      setIsLocalAudioEnable={setIsLocalAudioEnable}
                    />
                  </div>
                  {participants.map((participant, i) => (
                    <div
                      className="video-calling_participant-screen"
                      key={i}
                      style={{
                        width: participantScreenWidth,
                        height: participantScreenHeight,
                      }}>
                      <RemoteParticipant
                        participant={participants[i]?.participant}
                        room={room}
                        participantIndex={i}
                        isLocalCameraEnable={isLocalCameraEnable}
                        setIsLocalCameraEnable={setIsLocalCameraEnable}
                        isLocalAudioEnable={isLocalAudioEnable}
                        setIsLocalAudioEnable={setIsLocalAudioEnable}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            <div
              className={`${
                participantLength >= 4 ? "video-action active" : "video-action"
              }`}>
              <div className="video-main-action">
                <div
                  onClick={() => {
                    if (isLocalAudioEnable)
                      room.localParticipant.audioTracks.forEach(
                        (publication) => {
                          publication.track.disable();
                        }
                      );
                    if (!isLocalAudioEnable)
                      room.localParticipant.audioTracks.forEach(
                        (publication) => {
                          publication.track.enable();
                        }
                      );
                    setIsLocalAudioEnable((prev) => !prev);
                  }}
                  className="icon-action">
                  {isLocalAudioEnable ? (
                    <BiMicrophone className="icon" />
                  ) : (
                    <BiMicrophoneOff className="icon" />
                  )}
                </div>
                <div
                  onClick={() => {
                    room.disconnect();
                    setIsVideoModalOpen(false);
                    if (stream !==null) {
                      const tracks = stream.getTracks();
                      tracks.forEach((track) => {
                      track.stop();
                    })
                    dispatch(SetStream(null))
                    }
                    navigate("/")
                  }}
                  className="icon-action disconnect">
                  <MdOutlineCallEnd className="icon" />
                </div>
                <div
                  className="icon-action"
                  onClick={() => {
                    if (isLocalCameraEnable) {
                      room.localParticipant.videoTracks.forEach(
                        (publication) => {
                          publication.track.disable();
                        }
                      );
                    }
                    if (!isLocalCameraEnable) {
                      room.localParticipant.videoTracks.forEach(
                        (publication) => {
                          publication.track.enable();
                        }
                      );
                    }
                    setIsLocalCameraEnable((prev) => !prev);
                  }}>
                  {isLocalCameraEnable ? (
                    <BiVideo className="icon" />
                  ) : (
                    <BiVideoOff className="icon" />
                  )}
                </div>
              </div>
              <div className={isMobile ? "video-main-action":"video-feature-action"}>
                <div className="icon-action" >
                <ParticipantList  participants={participants} localParticipant={room?.localParticipant.identity} participantLength={participantLength}/>
                </div>
                <div className="icon-action">
                  <BsChatLeftDots className="icon" onClick={()=>{
                    isOpen ? dispatch(setChatOpen(false)): dispatch(setChatOpen(true))
                  }} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const MinimizeModal = ({
  setMinimizeScreen,
  participants,
  room,
  isLocalCameraEnable,
  setIsLocalCameraEnable,
  isLocalAudioEnable,
  setIsLocalAudioEnable,
  minimizeScreen,
  setIsVideoModalOpen,
}) => {
  return (
   <Draggable>
     <div className="minimize-screen">
        <div className="header">
        <BsFullscreen
          onClick={() => {
            setIsVideoModalOpen(true);
            setMinimizeScreen(false);
          }}
          className="icon"
        />
        <AiOutlineClose
          className="icon"
          onClick={() => setMinimizeScreen(false)}
        />
      </div>
      {room && (
        <div className="video-container">
          <div className="video">
            <RemoteParticipant
              participant={room?.localParticipant}
              room={room}
              participantIndex={0}
              minimizeScreen={minimizeScreen}
              setMinimizeScreen={setMinimizeScreen}
              isLocal={true}
              isLocalCameraEnable={isLocalCameraEnable}
              setIsLocalCameraEnable={setIsLocalCameraEnable}
              isLocalAudioEnable={isLocalAudioEnable}
              setIsLocalAudioEnable={setIsLocalAudioEnable}
            />
          </div>
          {participants.map((participant, i) => (
            <div className="video" key={i}>
              <RemoteParticipant
                participant={participants[i]?.participant}
                room={room}
                participantIndex={i}
                minimizeScreen={minimizeScreen}
                setMinimizeScreen={setMinimizeScreen}
                isLocalCameraEnable={isLocalCameraEnable}
                setIsLocalCameraEnable={setIsLocalCameraEnable}
                isLocalAudioEnable={isLocalAudioEnable}
                setIsLocalAudioEnable={setIsLocalAudioEnable}
              />
            </div>
          ))}
        </div>
      )}
    </div>
   </Draggable>
  );
};

export interface Data {
  jobId?: any;
  CandidateId?: any;
}
export interface RoomId {
  roomId?: any;
  roomObj?:any;
  isVideoEnable?:any;
  isAudioEnable?:any
}
