import React, { useRef, useState } from "react";
import useOnClickOutside from "../../hooks/common/use-outside-click";

export interface Option {
  id: number;
  name?: string;
  data?: any;
  label?: string;
}

interface CustomSelectProps {
  options: Option[];
  placeholder?: string;
  selected: any;
  setSelected: React.Dispatch<(prevState: Option) => Option>;
  label?: string;
  className?: string;
  noBorder?: boolean;
}

function CodingSelect({
  options,
  placeholder,
  selected,
  setSelected,
  label,
  className,
}: CustomSelectProps) {
  const dropdownIcon = require("./../../assets/icons/dropDownIconCommon.svg");
  const dropdownIconSelected = require("./../../assets/icons/dropDownSelected.svg");

  const ref = useRef(null);
  const [active, setActive] = useState(false);

  const selectItem = (i) => {
    setSelected(i);
    setActive(false);
  };

  useOnClickOutside(ref, () => setActive(false));

  const finalClassName = [`Select`];
  if (className) finalClassName.push(className);

  const options_array = options.map((item, key) => (
    <div
      key={key}
      className="customSelect__select_box__options_container__option"
      onClick={() => selectItem(item)}
    >
      <input
        type="radio"
        className="customSelect__select_box__options_container__option__radio"
        id={item?.id?.toString() || key.toString()}
        name="category"
      />
      <label htmlFor={item.name}>{item.name ? item.name : item.label}</label>
    </div>
  ));

  function openSelect() {
    setActive(!active);
  }

  return (
    <div ref={ref} className={finalClassName.join(" ")}>
      <label className="customSelect__label">{label}</label>
      <div className="customSelect">
        <div className="customSelect__select_box">
          {active && (
            <div className="customSelect__optionsWrapper">
              <div className="customSelect__select_box__options_container scroll active">
                {options_array}
              </div>
            </div>
          )}
          <div
            className={
              active
                ? "customSelect__selected active"
                : "customSelect__selected"
            }
            style={{
              backgroundImage: active
                ? `url(${dropdownIconSelected})`
                : `url(${dropdownIcon})`,
            }}
            onClick={openSelect}
          >
            {selected
              ? selected?.title || selected?.name || selected?.label
              : placeholder}
            {typeof selected === "string" ? selected : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CodingSelect;
