import { Channel } from 'twilio-chat';
import { conversation as types } from "../../types";
import produce from "immer";

export const initialValue: types.State = {
  token:"",
  isOpen:false,
  room: null,
  channel: null,
  participantSid: "",
  userIdentity: "",
  client: null,
  uniqueName: "",
  createChannel: null,
  joinChannel: null,
  messagesList:[],
  sidForRoom:""
};

const reducer = (state = initialValue, action) => {
  switch (action.type) {
    case types.actionTypes.SET_CHAT_OPEN:
      return produce(state, (draft) => {
        draft.isOpen = action.payload;
      });
    case types.actionTypes.SET_CHAT_TOKEN:
      return produce(state, (draft) => {
        draft.token = action.payload;
      });
    case types.actionTypes.SET_CONVERSATION:
      return produce(state, (draft) => {
        draft.channel = action.payload;
      });
    case types.actionTypes.SET_PARTICIPANT_ID:
      return produce(state, (draft) => {
        draft.participantSid = action.payload;
      });
    case types.actionTypes.SET_USER_IDENTITY:
      return produce(state, (draft) => {
        draft.userIdentity = action.payload;
      });
    case types.actionTypes.SET_CLIENT:
      return produce(state, (draft) => {
        draft.client = action.payload;
      });
    case types.actionTypes.SET_UNIQUE_NAME:
      return produce(state, (draft) => {
        draft.uniqueName = action.payload;
      });
    case types.actionTypes.SET_MESSAGE:
      return produce(state, (draft) => { 
        draft.messagesList?.push(action?.payload)
      });
    case types.actionTypes.SET_SID_FROM_ROUND:
      return produce(state, (draft) => {
        draft.sidForRoom = action.payload;
      });
    case types.actionTypes.SET_JOIN_CHANNEL:
      return produce(state, (draft) => {
        draft.joinChannel = action.payload;
      });
    case types.actionTypes.SET_CREATE_CHANNEL:
      return produce(state, (draft) => {
        draft.createChannel = action.payload;
      });
    default:
      return state;
  }
};

export default reducer;
