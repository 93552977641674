import { assessment as types } from "../../types";
import produce from "immer";

export const initialValue: types.State = {
  assessmentTask: null,
  activeTask: null,
  isOnline: true,
  isStartedSolving: false,
  assessmentList: null,
  duration: null,
  testCaseStatuses: [],
};

const reducer = (state = initialValue, action) => {
  switch (action.type) {
    case types.actionTypes.SET_ASSESSMENT_TASK:
      return produce(state, (draft) => {
        draft.assessmentTask = action.payload;
      });

    case types.actionTypes.SET_ACTIVE_TASK:
      return produce(state, (draft) => {
        draft.activeTask = action.payload;
      });

    case types.actionTypes.SET_IS_ONLINE:
      return produce(state, (draft) => {
        draft.isOnline = action.payload;
      });

    case types.actionTypes.SET_IS_START_SOLVING:
      return produce(state, (draft) => {
        draft.isStartedSolving = action.payload;
      });

    case types.actionTypes.SET_ASSESSMENT_LIST:
      return produce(state, (draft) => {
        draft.assessmentList = action.payload;
      });

    case types.actionTypes.SET_DURATION:
      return produce(state, (draft) => {
        draft.duration = action.payload;
      });

    case types.actionTypes.SET_TEST_CASE_STATUSES:
      return produce(state, (draft) => {
        draft.testCaseStatuses = action.payload;
      });

    case types.actionTypes.RESET_STATE:
      return { ...initialValue };

    default:
      return state;
  }
};

export default reducer;
