export interface SubmissionResult {
  stdout?: string;
  compile_output?: string;
  message?: string;
  stderr?: string;
  [key: string]: any;
}
export interface State {
  activePackage: any | null;
  activeLanguage: any | null;
  consoleWarningOpen: any | null;
  consoleWarning: any | null;
  runCode: { stdout: any, compile_output:any };
  error: {message:any, stderr:any};
  isTerminalLoading: boolean;
  submissionResults:SubmissionResult[]
}

export enum CodingConsoleEnun {
  consoleWarningOpen = "consoleWarningOpen",
}
