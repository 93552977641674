import React from "react";
import WelcomePage from "../welcomePage/welcomePage";
import QuestionPage from "../questionPage/questionPage";

function Display({ textData, data }) {
  return (
    <div className="listOfQuestions__display">
      {!data && <WelcomePage textData={textData} />}
      {data && <QuestionPage data={data} />}
    </div>
  );
}

export default Display;
