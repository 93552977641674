import { createStore, compose, applyMiddleware } from "redux";
import rootReducer from "../store/root-reducer";

const actionSanitizer = (action: any) =>
  action.type.includes("LONG_BLOB") && action.payload
    ? { ...action, payload: "<<LONG_BLOB>>" }
    : action;

const bindMiddleware = () => {
  if (process.env.NODE_ENV !== "production") {
    const composeEnhancers =
      //@ts-ignore
      // eslint-disable-next-line no-restricted-globals
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // add sanitizers here as devtools options
        // see https://github.com/zalmoxisus/redux-devtools-extension/tree/94f7e53800f4665bddc9b7438c5cc75cfb4547cc#12-advanced-store-setup
        // section 1.2

        actionSanitizer,
        stateSanitizer: (state: any) =>
          state.editor
            ? {
                ...state,
                editor: {
                  ...state.editor,
                  editor: "editorState",
                  remoteCursorManager: "remoteCursorManager",
                  remoteSelectionManager: "remoteSelectionManager",
                  contentManager: "contentManager",
                  participants: "participants",
                },
              }
            : state,
      }) || compose;
    return composeEnhancers(applyMiddleware());
  }
  return applyMiddleware();
};

export const makeStore = () => {
  const store = createStore(rootReducer, bindMiddleware());
  return store;
};
