import { footer as types } from "../../types";
import produce from "immer";

export const initialValue: types.State = {
  activePackage: null,
  activeLanguage: null,
  consoleWarningOpen: null,
  consoleWarning: null,
  runCode: { stdout: "", compile_output: "" },
  error: { message:"", stderr:null },
  isTerminalLoading:false,
  submissionResults:[]
};

const reducer = (state = initialValue, action) => {
  switch (action.type) {
    case types.actionTypes.SET_PACKAGE:
      return produce(state, (draft) => {
        draft.activePackage = action.payload;
      });
    case types.actionTypes.SET_LANGUAGE:
      return produce(state, (draft) => {
        draft.activeLanguage = action.payload;
      });
    case types.actionTypes.SET_CONSOLE_WARNING_OPEN:
      return produce(state, (draft) => {
        draft.consoleWarningOpen = action.payload;
      });
    case types.actionTypes.SET_CONSOLE_WARNING:
      return produce(state, (draft) => {
        draft.consoleWarning = action.payload;
      });
    case types.actionTypes.RUN_CODE_STATE:
      return produce(state, (draft) => {
        draft.runCode = action.payload;
      });
    case types.actionTypes.IS_TERMINAL_LOADING:
      return produce(state, (draft) => {
        draft.isTerminalLoading = action.payload;
      });
    case types.actionTypes.CODE_ERROR:
      return produce(state, (draft) => {
        draft.error = action.payload;
      });
    case types.actionTypes.SET_SUBMISSION_RESULTS:
      return produce(state, (draft)=>{
        draft.submissionResults = action.payload;
      })  
    case types.actionTypes.RESET_FOOTER_STATE:
      return { ...initialValue };
    default:
      return state;
  }
};

export default reducer;
