import React from "react";
import {postData} from "../../../hooks/api/postData";
import { getEnv } from "@urecruits/api";
import {useParams} from "react-router-dom";
const { API_ASSESSMENT } = getEnv();
function Footer({interviewers,setInterviewers}) {
  const getLink = require("./../../../assets/icons/getLink.svg");
  const meetingLink = location.href; 
  const params = useParams();
  function copyLink() {
    const copyText = document.getElementById("link") as HTMLInputElement;
    copyText.select();
    navigator.clipboard.writeText(copyText.value);
  }

  const handleSendInvitation=async ()=>{
    if(!Array.isArray(interviewers) || interviewers.length===0){
      return null;
    }
    const body={
      interviewers: interviewers?.map((item) => item.value),
      redirectLink: meetingLink,
      roomId:params.room
    }
     
    const resData = await postData(`${API_ASSESSMENT}/api/live-coding/invite-interviewers`,body)
     
    if(resData){
      setInterviewers([]);
    }
  }

  return (
    <div className="inviteCandidate__footer">
      <div className="inviteCandidate__buttons">
        <button className="c-btn c-btn--small c-btn--greenBorder">
          Cancel
        </button>
        <button className="c-btn c-btn--green c-btn--small" onClick={handleSendInvitation}>Send</button>
      </div>

      <div className="inviteCandidate__footer__label">
        <hr />
        <span>or share link</span>
        <hr />
      </div>
      <div className="inviteCandidate__footer__getLink">
        <img
          className="inviteCandidate__footer__getLink__icon"
          src={getLink}
          alt="getLink"
        />
        <span className="inviteCandidate__footer__getLink__label">
          Get Link
        </span>
      </div>

      <div className="inviteCandidate__footer__inputSection">
        <input
          id="link"
          className="inviteCandidate__footer__inputSection__input"
          type="text"
          value={meetingLink}
        />
        <div
          onClick={copyLink}
          className="inputSection__input__icon inputSection__input__icon__pointer"
        >
          copy
        </div>
      </div>
    </div>
  );
}

export default Footer;
