import { videoMeeting } from "../../types";
import { RootState } from "../root-reducer";

const getAllState = (state: RootState): videoMeeting.State => {
  return state.videoMeeting;
};

const getInterviewState = (state: RootState): videoMeeting.State["isEndInterView"] => {
  return state.videoMeeting?.isEndInterView
};

const getIsSnackBarOpen = (state: RootState): videoMeeting.State["isSnackBarOpen"] => {
  return state.videoMeeting?.isSnackBarOpen
};

const getAllParticipants = (state: RootState): videoMeeting.State["allParticipants"] => {
  return state.videoMeeting?.allParticipants
};

const getStream = (state: RootState): videoMeeting.State["stream"] => {
  return state.videoMeeting?.stream
};

const getMeetingStream = (state: RootState): videoMeeting.State["meetingStream"] => {
  return state.videoMeeting?.meetingStream
};

export const videoMeetingSelectors = {
  getAllState,
  getInterviewState,
  getIsSnackBarOpen,
  getAllParticipants,
  getStream,
  getMeetingStream
};
