export enum actionTypes {
  SET_VIDEO_MEETING = "video-meet/SET_VIDEO_MEETING",
  SET_IS_END_INTERVIEW = "video-meet/SET_IS_END_INTERVIEW",
  SET_IS_SNACKBAR_OPEN = "video-meet/SET_IS_SNACKBAR_OPEN",
  SET_ALL_PARTICIPANTS = "video-meet/SET_ALL_PARTICIPANTS",
  SET_STREAM = "video-meet/SET_STREAM",
  SET_MEETING_STREAM = "video-meet/SET_MEETING_STREAM",
}

export type Actions =
  | SetVideoMeetingData
  | SetIsEndInterview
  | SetIsSnackBarOpen
  | SetAllParticipants
  | SetStream
  | SetMeetingStream

export interface SetVideoMeetingData {
  type: actionTypes.SET_VIDEO_MEETING;
  payload: any;
}

export interface SetIsEndInterview {
  type: actionTypes.SET_IS_END_INTERVIEW;
  payload: any;
}

export interface SetIsSnackBarOpen {
  type: actionTypes.SET_IS_SNACKBAR_OPEN;
  payload: any;
}


export interface SetAllParticipants {
  type: actionTypes.SET_ALL_PARTICIPANTS;
  payload: any;
}

export interface SetStream {
  type: actionTypes.SET_STREAM;
  payload: any;
}

export interface SetMeetingStream {
  type: actionTypes.SET_MEETING_STREAM;
  payload: boolean;
}

