import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import Split from "react-split-grid";
import cn from "classnames";
import { useLocation } from "react-router-dom";
//components
import Header from "../components/header";
import { Editor } from "../components/code-editor";
import { TaskDescription } from "../components/task-description";
//store
import { setReadOnly } from "../store/editor/editor.actions";
import {
  resetAssessmentState,
  setAssessmentTask,
  setIsOnline,
} from "../store/assessment/assessment.actions";
import { editorSelectors } from "../store/editor/editor.selectors";
//hooks
import { useWindowSize } from "../hooks/common/use-window-size";
import useSettings from "../hooks/editor/use-settings";
//mockdata
import fetchData from "../hooks/api/postData";
import { getEnv } from "@urecruits/api";
import { useHasPermission, AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const {API_ASSESSMENT,API_RECRUITMENT}=getEnv()

export const PlaybackPage = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector(editorSelectors.getAllState);
  const { width } = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState("400px 1px 1fr");

  useSettings();

  const {checkUserPermission} = useHasPermission()

  useEffect(() => {
    dispatch(setReadOnly(true)); 
    dispatch(setIsOnline(false));
    // dispatch(setAssessmentTask(assesmentOnLineTask));
    setIsLoading(false);
  }, []);

  const { state: { userId, jobId, assessmentType, assessmentId } }:any = useLocation();

  useEffect(() => {
    checkUserPermission('assessment','view') &&fetchData(`${API_ASSESSMENT}/api/${assessmentType}/${assessmentId}`).then((data)=>{
          dispatch(setAssessmentTask(data));
        })
    setIsLoading(false);
    return () => {
      dispatch(resetAssessmentState());
    };
  }, []);

  const handleDrag = (direction, track, style) => {
    setColumns(style);
  };

  const columnMinSizes = useMemo(() => {
    const editorWidth = width - width * 0.4;
    const taskDescriptionWidth = 400;

    if (settings.view === "left") {
      setColumns("400px 1px 1fr");
      return { [0]: taskDescriptionWidth, [2]: editorWidth };
    } else {
      setColumns("1fr 1px 400px");
      return { [0]: editorWidth, [2]: taskDescriptionWidth };
    }
  }, [settings.view, width]);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <AuthGuard module='assessment' permission='view'>
    <div className="codding">
      <div className="codding__wrapper">
        <Header />
        <div className={cn("codding__content-wrapper", settings.view)}>
          <Split
            gridTemplateColumns={columns}
            onDrag={handleDrag}
            cursor="col-resize"
            columnMinSizes={columnMinSizes}
            // @ts-ignore
            render={({ getGridProps, getGutterProps }) => (
              <div
                className={`split-grid ${settings.view}`}
                {...getGridProps()}
              >
                <TaskDescription />
                <div className="gutterGrid" {...getGutterProps("column", 1)} />
                <Editor isWidthChanged={columns} />
              </div>
            )}
          />
        </div>
      </div>
    </div>
    </AuthGuard>
  );
};
