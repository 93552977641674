import { footer as types } from "../../types";
import * as actions from "../../types/footer/actions.types";

export const setPackage = (
  payload: types.State["activePackage"]
): actions.SetPackage => ({
  type: types.actionTypes.SET_PACKAGE,
  payload,
});

export const setLanguage = (
  payload: types.State["activeLanguage"]
): actions.SetLanguage => ({
  type: types.actionTypes.SET_LANGUAGE,
  payload,
});

export const resetFooterState = (): actions.ResetFooterState => ({
  type: types.actionTypes.RESET_FOOTER_STATE,
});

export const setConsoleWarningOpen = (
  payload: types.State["consoleWarningOpen"]
): actions.SetConsoleWarningOpen => ({
  type: types.actionTypes.SET_CONSOLE_WARNING_OPEN,
  payload,
});

export const setConsoleWarning = (
  payload: types.State["consoleWarning"]
): actions.SetConsoleWarning => ({
  type: types.actionTypes.SET_CONSOLE_WARNING,
  payload,
});

export const runCodeState = (
  payload: types.State["runCode"]
): actions.RunCodeState => ({
  type: types.actionTypes.RUN_CODE_STATE,
  payload,
});

export const isTerminalLoading = (
  payload: types.State["isTerminalLoading"]
): actions.IsTerminalLoading => ({
  type: types.actionTypes.IS_TERMINAL_LOADING,
  payload,
});

export const setError = (payload: types.State["error"]): actions.Error => ({
  type: types.actionTypes.CODE_ERROR,
  payload,
});

export const setSubmissionResults = (
  payload: types.State["submissionResults"]
): actions.SetSubmissionResults => ({
  type: types.actionTypes.SET_SUBMISSION_RESULTS,
  payload,
});
