import { useDispatch, useSelector } from "react-redux";
import { assessmentSelectors } from "../../store/assessment/assessment.selectors";
import { setStartSolving } from "../../store/assessment/assessment.actions";
import fetchData from "../../hooks/api/fetchData";
import { useEffect, useState } from "react";
import { getEnv } from "@urecruits/api";
import { LanguagesSet } from "../../../coding-assessments/config/utils";
import { patchData } from "../../hooks/api/postData";
import { useParams } from "react-router-dom";

const {API_ASSESSMENT}=getEnv()

export const EditorStartOffline = () => {
  const dispatch = useDispatch();
  const { activeTask } = useSelector(assessmentSelectors.getAllState);
  const [languageData,setLanguagesData]=useState([])
  const [language,setLanguage]=useState(null)
  const [packageData,setPackagesData]=useState([])
  const [packageValue,setPackageValue]=useState(null)
  const {jobId}=useParams()
  useEffect(() => {
   
    const data=LanguagesSet.find((lang) => lang?.id === activeTask?.data?.languageId)
    setLanguage(data?.name)
  
  }, [activeTask]);

  useEffect(() => {
    fetchData( `${API_ASSESSMENT}/api/take-home-task/packages`, setPackagesData)
    .then((res)=>{
    const data=res?.data?.find((lang) => lang?.id === activeTask?.data?.packageId)
    setPackageValue(data?.title)
    })
  }, [activeTask]);

  if (!activeTask) {
    return <></>;
  }

  return (
    <div className="editorStartOffline__container">
      <p className="editorStartOffline__title">Take Home Task</p>
      <p className="editorStartOffline__taskName">{activeTask.data.name}</p>
      <div className="editorStartOffline__info">
        <p className="editorStartOffline__description">
          {activeTask.data.description}
        </p>
        <p className="editorStartOffline__bold">
          Default language -{" "}
          <span>{activeTask.data.language}</span>
        </p>
        <p className="editorStartOffline__bold">
          Package - <span>{packageValue && packageValue}</span>
        </p>
      </div>
      <div className="editorStartOffline__bottom">
        <p className="editorStartOffline__taskName">
          With this language you start solving
        </p>
        <div className="editorStartOffline__language">
          <p>{language && language}</p>
        </div>
        <button
          className="c-btn c-btn-big c-btn--green"
          onClick={async() => {
            dispatch(setStartSolving(true))
            const startTime=new Date().toUTCString()
            await patchData(`${API_ASSESSMENT}/api/test-manager?jobId=${jobId}&assessmentType=Coding Assessment&testStatus=In Progress&startTime=${startTime}`,{})
          }}
        >
          Start solving
        </button>
      </div>
    </div>
  );
};
