import { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce'
import * as MonacoCollabExt from '@convergencelabs/monaco-collab-ext';
import { useSelector, useDispatch } from 'react-redux'
//store
import { editorSelectors } from '../../store/editor/editor.selectors'
import { setSelectionManager, setLocalAction } from '../../store/editor/editor.actions'
import { EditorActionsEnum } from '../../types/editor';

const useSelection = () => {
  const dispatch = useDispatch()
  const [selectionData, setSelectionData] = useState<any>(null)

  const { isReadOnly, editor } = useSelector(editorSelectors.getAllState)

  const debounceSetSelection = useCallback(debounce(setSelectionData, 150), [])

  const initSharedSelection = () => {
    const remoteSelectionManager = new MonacoCollabExt.RemoteSelectionManager({ editor: editor as any });

    dispatch(setSelectionManager(remoteSelectionManager))
    //handle all mouse events from editor
    if (!isReadOnly) {
      editor?.onDidChangeCursorSelection(() => {
        setLocalSelection()
      })

      editor?.onDidBlurEditorText(() => {
        setSelectionData({actionType: EditorActionsEnum.dispose})
      })
    }
  }

  const setLocalSelection = () => {
    const selection = editor.getSelection();
    const position = editor.getPosition();
    const offset = editor.getModel().getOffsetAt(position);
    const startOffset = editor.getModel().getOffsetAt(selection.getStartPosition());
    const endOffset = editor.getModel().getOffsetAt(selection.getEndPosition());
    if (!selection.isEmpty()) {
      debounceSetSelection({
        actionType: EditorActionsEnum.selection,
        data: {
          cursorPosition: offset,
          selection: {
            start: startOffset,
            end: endOffset
          }
        }
      })
    } else {
      debounceSetSelection({
        actionType: EditorActionsEnum.selection,
        data: {
          cursorPosition: offset,
          selection: null
        }
      })
    }
  }
  //dispatch actions to state
  useEffect(() => {
    dispatch(setLocalAction(selectionData))
  }, [selectionData])

  return [initSharedSelection]
}

export default useSelection
