import { editor as types } from "../../types";
import * as actions from "../../types/editor/actions.types";

export const setEditor = (
  payload: types.State["editor"]
): actions.SetEditor => ({
  type: types.actionTypes.SET_EDITOR,
  payload,
});

export const setSelectionManager = (
  payload: types.State["remoteSelectionManager"]
): actions.SetSelectionManager => ({
  type: types.actionTypes.SET_SELECTION_MANAGER,
  payload,
});

export const setCursorManager = (
  payload: types.State["remoteCursorManager"]
): actions.SetCursorManager => ({
  type: types.actionTypes.SET_CURSOR_MANAGER,
  payload,
});

export const setContentManager = (
  payload: types.State["contentManager"]
): actions.SetContentManager => ({
  type: types.actionTypes.SET_CONTENT_MANAGER,
  payload,
});

export const setReadOnly = (
  payload: types.State["isReadOnly"]
): actions.SetReadOnly => ({
  type: types.actionTypes.SET_READ_ONLY,
  payload,
});

export const setLocalAction = (
  payload: types.State["localAction"]
): actions.SetLocalAction => ({
  type: types.actionTypes.SET_LOCAL_ACTION,
  payload,
});

export const setRemoteParticipants = (
  payload: types.State["participants"]
): actions.SetRemoteParticipants => ({
  type: types.actionTypes.SET_REMOTE_PARTICIPANTS,
  payload,
});

export const setLocalParticipant = (
  payload: types.State["localParticipant"]
): actions.SetLocalParticipant => ({
  type: types.actionTypes.SET_LOCAL_PARTICIPAN,
  payload,
});

export const resetEditorState = (): actions.ResetEditorState => ({
  type: types.actionTypes.RESET_EDITOR_STATE,
});

export const setRoomId = (
  payload: types.State["roomId"]
): actions.SetRoomId => ({
  type: types.actionTypes.SET_ROOM_ID,
  payload,
});

export const setOutputData = (
  payload: types.State["outPut"]
): actions.SetOutput => ({
  type: types.actionTypes.SET_OUTPUT,
  payload,
});

export const setModal = (payload: types.State["modal"]): actions.SetModal => ({
  type: types.actionTypes.SET_MODAL,
  payload,
});

export const setModalClose = (payload: types.State["modalClose"]): actions.SetModalClose => ({
  type: types.actionTypes.SET_MODAL_CLOSE,
  payload,
});

export const setSetting = (payload: any): actions.SetSetting => ({
  type: types.actionTypes.SET_SETTING,
  payload,
});

export const setUserRole = (payload: any): actions.SetUserRole => ({
  type: types.actionTypes.SET_USER_ROLE,
  payload,
});

export const SetUserName = (payload: any): actions.SetUserName => ({
  type: types.actionTypes.SET_USER_NAME,
  payload,
});

export const SetCurrentUser = (payload: any): actions.SetCurrentUser => ({
  type: types.actionTypes.SET_CURRENT_USER,
  payload,
});

export const setMarker = (
  payload: types.State["markers"]
): actions.SetMarkers => ({
  type: types.actionTypes.SET_MARKERS,
  payload,
});
