import React, { useState, useRef } from "react";
import { Link } from "@reach/router";
import InvitionModal from "./invitionModal";
import { setModal } from "../../store/editor/editor.actions";
import { CodingModalsEnum } from "../../types/editor/data.types";
import { useDispatch, useSelector } from "react-redux";
import { assessmentSelectors } from "../../store/assessment/assessment.selectors";
import useOnClickOutside from "../../hooks/common/use-outside-click";
import { videoMeetingSelectors } from "../../store/video-meeting/assessment.selectors";
import { editorSelectors } from "../../store/editor/editor.selectors";
import CountdownTimer from './CountdownTimer';
import * as types from "../../types/editor";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

 const Header = () => {


  const  {userRole}  = useSelector(editorSelectors.getAllState);
  const  allParticipants  = useSelector(videoMeetingSelectors.getAllParticipants);
  const [moreInfo, setMoreInfo] = useState(false);
  const [inviteModalActive, setInviteModalActive] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setInviteModalActive(false));

  const dispatch = useDispatch();

  const { isOnline, isStartedSolving } = useSelector(assessmentSelectors.getAllState);
  const { isReadOnly } = useSelector(editorSelectors.getAllState);

  return (
    <div className="header-cod">
      <div className="header-cod__leftBar">
        <Link to={isReadOnly?"/recruitment/review-score":"/"} className="header-cod__linkHome">
          <svg
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.61538 1.2002L1 6.0002M1 6.0002L5.61538 10.8002M1 6.0002H13"
              stroke="#099C73"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
         {isReadOnly? "Back": "Go to Home"}
        </Link>
        <h6 className="header-cod__assignmentTitle">{allParticipants?.title}</h6>
        <div className="header-cod__buttons">
          {isOnline && (
            <button className="c-btn c-btn--greenBorder">White Board</button>
          )}
         {!isReadOnly && (
          <AuthGuard module='recruiter'>
            <button
           onClick={() => {
              dispatch(setModal(types.CodingModalsEnum.endInterview));
           }}
           className="c-btn c-btn--red"
         >
           End Interview
         </button>
          </AuthGuard>
           
         )} 
        </div>
      </div>
      <div className="header-cod__rightBar">
      {isStartedSolving && <div style={{border: '1px solid #ccc', padding: '5px', borderRadius: '4px'}}>
       <CountdownTimer/>
      </div> }
        {isOnline && (
          <AuthGuard module='recruiter'>
            <AuthGuard module='team-members' permission='view'>
            <div className="header-cod__members">
              {allParticipants.map((member,index) => (
                <div className="header-cod__icon" key={index}>
                  {member?.charAt(0)}
                </div>
              ))}
            </div>
            <button
              className="c-btn c-btn--greenBorder"
              onClick={() => setInviteModalActive(!inviteModalActive)}
              style={{ pointerEvents: inviteModalActive ? "none" : "auto" }}
            >
              Invite
            </button>
          </AuthGuard>
          </AuthGuard>
        )}

        {inviteModalActive && (
          <div ref={ref} className="inviteModal-wrapper">
            <InvitionModal />
          </div>
        )}

        <div className="header-cod__moreInfo">
          <div onClick={() => setMoreInfo(!moreInfo)}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0003 10.8337C10.4606 10.8337 10.8337 10.4606 10.8337 10.0003C10.8337 9.54009 10.4606 9.16699 10.0003 9.16699C9.54009 9.16699 9.16699 9.54009 9.16699 10.0003C9.16699 10.4606 9.54009 10.8337 10.0003 10.8337Z"
                fill="#999EA5"
                stroke="#999EA5"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.0003 4.99967C10.4606 4.99967 10.8337 4.62658 10.8337 4.16634C10.8337 3.7061 10.4606 3.33301 10.0003 3.33301C9.54009 3.33301 9.16699 3.7061 9.16699 4.16634C9.16699 4.62658 9.54009 4.99967 10.0003 4.99967Z"
                fill="#999EA5"
                stroke="#999EA5"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.0003 16.6667C10.4606 16.6667 10.8337 16.2936 10.8337 15.8333C10.8337 15.3731 10.4606 15 10.0003 15C9.54009 15 9.16699 15.3731 9.16699 15.8333C9.16699 16.2936 9.54009 16.6667 10.0003 16.6667Z"
                fill="#999EA5"
                stroke="#999EA5"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          {moreInfo && (
            <ul className="header-cod__moreInfo__description">
              <li className="header-cod__moreInfo__item">
                <a
                  className="header-cod__moreInfo__link"
                  onClick={() => {
                    dispatch(setModal(CodingModalsEnum.keyboardShortcuts)),
                      setMoreInfo(false);
                  }}
                >
                  <p className="header-cod__moreInfo__name">
                    Keyboard Shortcuts
                  </p>
                </a>
              </li>

              <li className="header-cod__moreInfo__item">
                <a
                  className="header-cod__moreInfo__link"
                  onClick={() => setMoreInfo(false)}
                >
                  <p className="header-cod__moreInfo__name">Info</p>
                </a>
              </li>
              <li className="header-cod__moreInfo__item">
                <a
                  className="header-cod__moreInfo__link"
                  onClick={() => setMoreInfo(false)}
                >
                  <p className="header-cod__moreInfo__name">Help</p>
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};


export default React.memo(Header)