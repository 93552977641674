import React, { useEffect, useState } from "react";
import Split from "react-split";
import { useSelector } from "react-redux";
import { footerSelectors } from "../../store/footer/footer.selectors";
import { EditorFeedback } from "../code-editor/editor-feedback";
import cn from "classnames";
import QuestionLoader from "../code-editor/modals/QuestionLoader";
import { getEnv } from "@urecruits/api";

export const Terminal = () => {
  const {isTerminalLoading,submissionResults } = useSelector(footerSelectors.getAllState);
  return (
    <div className="test-cases">
      {/* TODO:// Split component is commented because it is causing error. The error needs to be fixed */}
      {/* <Split
        sizes={[99, 1]}
        direction="vertical"
        className={cn("split-vertical")}
        style={{ width: "325px" }}
        gutterSize={1}
       
        > */}
       {submissionResults.length ? 
          submissionResults.map((result, index) => (
            <div className="test-cases__container scroll" key={result.token || index}>
              {submissionResults.length > 1 && <p className="test-cases__result-sequence">{`Result-${index + 1}`}</p>}
              {result?.stdout !== '' || result?.compile_output !== '' ? (
                <>
                  <span style={{ fontSize: "16px", fontWeight: "500" }}>Code result:</span>
                  <p>{result?.stdout}</p>
                  <p style={{ color: "#FE4672" }}>{result?.compile_output}</p>
                </>
              ) : result?.message !== "" ? (
                <>
                  <span style={{ fontSize: "16px", fontWeight: "500", color: "#FE4672" }}>Error:</span>
                  <p>{result?.message}</p>
                  <p style={{ color: "#FE4672" }}>{result?.stderr}</p>
                </>
              ) : isTerminalLoading ? (
                <QuestionLoader />
              ) : null}
            </div>
          )) : (
            <p>JavaScript environment ready. Hit run to try out some code!</p>
          )
        }
        {/* <div className="test-cases__footer">
          <EditorFeedback />
        </div> */}

      {/* </Split> */}
    </div>
  );
};
