import { conversation } from "../../types";
import { RootState } from "../root-reducer";

const getAllState = (state: RootState): conversation.State => {
  return state.conversation;
};



export const conversationSelectors = {
  getAllState,
};
