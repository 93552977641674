import { combineReducers } from "redux";
import editorReducer from "./editor/editor.reducer";
import assessmentReducer from "./assessment/assessment.reducer";
import footerReducer from "./footer/footer.reducer";
import videoMeetingReducer from "./video-meeting/assessment.reducer";
import conversationReducer from "./conversation/conversation.reducer";
import * as types from "../types";

export interface RootState {
  editor: types.editor.State;
  assessment: types.assessment.State;
  footer: types.footer.State;
  videoMeeting: types.videoMeeting.State;
  conversation: types.conversation.State;
}

export interface RootAction {
  type: "RESET_APP";
}

const appReducer = combineReducers<RootState>({
  editor: editorReducer,
  assessment: assessmentReducer,
  footer: footerReducer,
  videoMeeting: videoMeetingReducer,
  conversation: conversationReducer,
});

const rootReducer = (
  state: RootState | undefined,
  action: RootAction
): RootState => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
