import * as monaco from "monaco-editor";

export const lightTheme:monaco.editor.IStandaloneThemeData = {
  "base": "vs",
  "inherit": true,
  "rules": [
    {
      "background": "f1f1f1",
      "token": ""
    },
    {
      "foreground": "999EA5",
      "token": "comment"
    },
    {
      "foreground": "0206ff",
      "fontStyle": "italic",
      "token": "variable"
    },
    {
      "foreground": "0100b6",
      "token": "keyword"
    },
    {
      "foreground": "cd0000",
      "fontStyle": "italic",
      "token": "constant.numeric"
    },
    {
      "foreground": "c5060b",
      "fontStyle": "italic",
      "token": "constant"
    },
    {
      "foreground": "585cf6",
      "fontStyle": "italic",
      "token": "constant.language"
    },
    {
      "foreground": "d80800",
      "token": "string"
    },
    {
      "foreground": "26b31a",
      "token": "constant.character.escape"
    },
    {
      "foreground": "26b31a",
      "token": "string source"
    },
    {
      "foreground": "1a921c",
      "token": "meta.preprocessor"
    },
    {
      "foreground": "0c450d",
      "fontStyle": "bold",
      "token": "keyword.control.import"
    },
    {
      "foreground": "0000a2",
      "fontStyle": "bold",
      "token": "entity.name.function"
    },
    {
      "foreground": "0000a2",
      "fontStyle": "bold",
      "token": "keyword.other.name-of-parameter.objc"
    },
    {
      "fontStyle": "italic",
      "token": "entity.name.type"
    },
    {
      "fontStyle": "italic",
      "token": "entity.other.inherited-class"
    },
    {
      "fontStyle": "italic",
      "token": "variable.parameter"
    },
    {
      "foreground": "70727e",
      "token": "storage.type.method"
    },
    {
      "fontStyle": "italic",
      "token": "meta.section entity.name.section"
    },
    {
      "fontStyle": "italic",
      "token": "declaration.section entity.name.section"
    },
    {
      "foreground": "3c4c72",
      "fontStyle": "bold",
      "token": "support.function"
    },
    {
      "foreground": "6d79de",
      "fontStyle": "bold",
      "token": "support.class"
    },
    {
      "foreground": "6d79de",
      "fontStyle": "bold",
      "token": "support.type"
    },
    {
      "foreground": "06960e",
      "fontStyle": "bold",
      "token": "support.constant"
    },
    {
      "foreground": "21439c",
      "fontStyle": "bold",
      "token": "support.variable"
    },
    {
      "foreground": "687687",
      "token": "keyword.operator.js"
    },
    {
      "foreground": "f44747",
      "background": "990000",
      "token": "invalid"
    },
    {
      "background": "ffd0d0",
      "token": "invalid.deprecated.trailing-whitespace"
    },
    {
      "background": "427ff530",
      "token": "text source"
    },
    {
      "background": "427ff530",
      "token": "string.unquoted"
    },
    {
      "foreground": "68685b",
      "token": "meta.xml-processing"
    },
    {
      "foreground": "68685b",
      "token": "declaration.xml-processing"
    },
    {
      "foreground": "888888",
      "token": "meta.doctype"
    },
    {
      "foreground": "888888",
      "token": "declaration.doctype"
    },
    {
      "fontStyle": "italic",
      "token": "meta.doctype.DTD"
    },
    {
      "fontStyle": "italic",
      "token": "declaration.doctype.DTD"
    },
    {
      "foreground": "1c02ff",
      "token": "meta.tag"
    },
    {
      "foreground": "1c02ff",
      "token": "declaration.tag"
    },
    {
      "fontStyle": "bold",
      "token": "entity.name.tag"
    },
    {
      "fontStyle": "italic",
      "token": "entity.other.attribute-name"
    },
    {
      "foreground": "0c07ff",
      "fontStyle": "bold",
      "token": "markup.heading"
    },
    {
      "foreground": "000000",
      "fontStyle": "italic",
      "token": "markup.quote"
    },
    {
      "foreground": "b90690",
      "token": "markup.list"
    }
  ],
  "colors": {
    "editor.foreground": "#000000",
    "editor.background": "#FFFFFF",
    "editor.selectionBackground": "#C3DCFF",
    "editor.lineHighlightBackground": "#00000012",
    "editorCursor.foreground": "#000000",
    "editorWhitespace.foreground": "#BFBFBF"
  }
}

export const darkTheme:monaco.editor.IStandaloneThemeData = {
  "base": "vs-dark",
  "inherit": true,
  "rules": [
    {
      "background": "28272c",
      "token": ""
    },
    {
      "foreground": "C1C5CB",
      "token": "comment"
    },
    {
      "foreground": "3387cc",
      "token": "constant"
    },
    {
      "foreground": "89bdff",
      "token": "entity"
    },
    {
      "foreground": "e28964",
      "token": "keyword"
    },
    {
      "foreground": "99cf50",
      "token": "storage"
    },
    {
      "foreground": "65b042",
      "token": "string"
    },
    {
      "foreground": "9b859d",
      "token": "support"
    },
    {
      "foreground": "3e87e3",
      "token": "variable"
    },
    {
      "foreground": "fd5ff1",
      "fontStyle": "italic underline",
      "token": "invalid.deprecated"
    },
    {
      "foreground": "fd5ff1",
      "background": "562d56bf",
      "token": "invalid.illegal"
    },
    {
      "background": "b1b3ba08",
      "token": "text source"
    },
    {
      "foreground": "9b5c2e",
      "fontStyle": "italic",
      "token": "entity.other.inherited-class"
    },
    {
      "foreground": "daefa3",
      "token": "string.quoted source"
    },
    {
      "foreground": "ddf2a4",
      "token": "string constant"
    },
    {
      "foreground": "e9c062",
      "token": "string.regexp"
    },
    {
      "foreground": "cf7d34",
      "token": "string.regexp constant.character.escape"
    },
    {
      "foreground": "cf7d34",
      "token": "string.regexp source.ruby.embedded"
    },
    {
      "foreground": "cf7d34",
      "token": "string.regexp string.regexp.arbitrary-repitition"
    },
    {
      "foreground": "8a9a95",
      "token": "string variable"
    },
    {
      "foreground": "dad085",
      "token": "support.function"
    },
    {
      "foreground": "cf6a4c",
      "token": "support.constant"
    },
    {
      "foreground": "8996a8",
      "token": "meta.preprocessor.c"
    },
    {
      "foreground": "afc4db",
      "token": "meta.preprocessor.c keyword"
    },
    {
      "fontStyle": "underline",
      "token": "entity.name.type"
    },
    {
      "foreground": "676767",
      "fontStyle": "italic",
      "token": "meta.cast"
    },
    {
      "foreground": "494949",
      "token": "meta.sgml.html meta.doctype"
    },
    {
      "foreground": "494949",
      "token": "meta.sgml.html meta.doctype entity"
    },
    {
      "foreground": "494949",
      "token": "meta.sgml.html meta.doctype string"
    },
    {
      "foreground": "494949",
      "token": "meta.xml-processing"
    },
    {
      "foreground": "494949",
      "token": "meta.xml-processing entity"
    },
    {
      "foreground": "494949",
      "token": "meta.xml-processing string"
    },
    {
      "foreground": "89bdff",
      "token": "meta.tag"
    },
    {
      "foreground": "89bdff",
      "token": "meta.tag entity"
    },
    {
      "foreground": "e0c589",
      "token": "source entity.name.tag"
    },
    {
      "foreground": "e0c589",
      "token": "source entity.other.attribute-name"
    },
    {
      "foreground": "e0c589",
      "token": "meta.tag.inline"
    },
    {
      "foreground": "e0c589",
      "token": "meta.tag.inline entity"
    },
    {
      "foreground": "e18964",
      "token": "entity.name.tag.namespace"
    },
    {
      "foreground": "e18964",
      "token": "entity.other.attribute-name.namespace"
    },
    {
      "foreground": "cda869",
      "token": "meta.selector.css entity.name.tag"
    },
    {
      "foreground": "8f9d6a",
      "token": "meta.selector.css entity.other.attribute-name.tag.pseudo-class"
    },
    {
      "foreground": "8b98ab",
      "token": "meta.selector.css entity.other.attribute-name.id"
    },
    {
      "foreground": "9b703f",
      "token": "meta.selector.css entity.other.attribute-name.class"
    },
    {
      "foreground": "c5af75",
      "token": "support.type.property-name.css"
    },
    {
      "foreground": "f9ee98",
      "token": "meta.property-group support.constant.property-value.css"
    },
    {
      "foreground": "f9ee98",
      "token": "meta.property-value support.constant.property-value.css"
    },
    {
      "foreground": "8693a5",
      "token": "meta.preprocessor.at-rule keyword.control.at-rule"
    },
    {
      "foreground": "dd7b3b",
      "token": "meta.property-value support.constant.named-color.css"
    },
    {
      "foreground": "dd7b3b",
      "token": "meta.property-value constant"
    },
    {
      "foreground": "8f9d6a",
      "token": "meta.constructor.argument.css"
    },
    {
      "foreground": "f8f8f8",
      "background": "0e2231",
      "fontStyle": "italic",
      "token": "meta.diff"
    },
    {
      "foreground": "f8f8f8",
      "background": "0e2231",
      "fontStyle": "italic",
      "token": "meta.diff.header"
    },
    {
      "foreground": "f8f8f8",
      "background": "420e09",
      "token": "markup.deleted"
    },
    {
      "foreground": "f8f8f8",
      "background": "4a410d",
      "token": "markup.changed"
    },
    {
      "foreground": "f8f8f8",
      "background": "253b22",
      "token": "markup.inserted"
    },
    {
      "foreground": "e9c062",
      "fontStyle": "italic",
      "token": "markup.italic"
    },
    {
      "foreground": "e9c062",
      "fontStyle": "bold",
      "token": "markup.bold"
    },
    {
      "foreground": "e18964",
      "fontStyle": "underline",
      "token": "markup.underline"
    },
    {
      "foreground": "e1d4b9",
      "background": "fee09c12",
      "fontStyle": "italic",
      "token": "markup.quote"
    },
    {
      "foreground": "fedcc5",
      "background": "632d04",
      "token": "markup.heading"
    },
    {
      "foreground": "fedcc5",
      "background": "632d04",
      "token": "markup.heading entity"
    },
    {
      "foreground": "e1d4b9",
      "token": "markup.list"
    },
    {
      "foreground": "578bb3",
      "background": "b1b3ba08",
      "token": "markup.raw"
    },
    {
      "foreground": "f67b37",
      "fontStyle": "italic",
      "token": "markup comment"
    },
    {
      "foreground": "60a633",
      "background": "242424",
      "token": "meta.separator"
    },
    {
      "background": "eeeeee29",
      "token": "meta.line.entry.logfile"
    },
    {
      "background": "eeeeee29",
      "token": "meta.line.exit.logfile"
    },
    {
      "background": "751012",
      "token": "meta.line.error.logfile"
    }
  ],
  "colors": {
    "editor.foreground": "#F8F8F8",
    "editor.background": "#2F2E34",
    "editor.selectionBackground": "#DDF0FF33",
    "editor.lineHighlightBackground": "#FFFFFF0D",
    "editorCursor.foreground": "#A7A7A7",
    "editorWhitespace.foreground": "#CAE2FB3D"
  }
}