import { useState } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { setSetting } from "../../../store/editor/editor.actions";
import { editorSelectors } from "../../../store/editor/editor.selectors";
import CodingSelect from "../../_elements/CodingSelect";
import { colorSchemeEnun, viewEnum } from "../../../types/editor";

export const EditorSettingsModal = ({ data }) => {
  const dispatch = useDispatch();
  const { settings } = useSelector(editorSelectors.getAllState);

  const onSwitchChange = (checked: boolean, _, id: string) => {
    if (id === "colorScheme") {
      dispatch(
        setSetting({
          key: id,
          data: checked ? colorSchemeEnun.dark : colorSchemeEnun.light,
        })
      );
    } else {
      dispatch(setSetting({ key: id, data: checked }));
    }
  };

  const renderSwitcherOrSelector = (setting) => {
    switch (setting.type) {
      case "select":
        return (
          <CodingSelect
            className="Settings__select"
            options={setting.options}
            selected={settings[setting.key]}
            setSelected={(data) => {
              dispatch(setSetting({ key: setting.key, data }));
            }}
          />
        );
      case "colorSwitcher":
        return (
          <Switch
            onChange={onSwitchChange}
            checked={settings[setting.key] !== colorSchemeEnun.light}
            id={setting.key}
            onHandleColor="#099C73"
            offHandleColor="#099C73"
            checkedIcon={<div className="checked">{colorSchemeEnun.light}</div>}
            uncheckedIcon={
              <div className="unchecked">{colorSchemeEnun.dark}</div>
            }
            uncheckedHandleIcon={
              <div className="checkedIcon">{colorSchemeEnun.light}</div>
            }
            checkedHandleIcon={
              <div className="uncheckedIcon">{colorSchemeEnun.dark}</div>
            }
            handleDiameter={38}
            height={46}
            width={172}
            borderRadius={35}
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            className={cn("Settings__switch Settings__switch--lang", {
              active: settings[setting.key] !== colorSchemeEnun.light,
            })}
          />
        );
      case "viewSwitcher":
        return (
          <div className="Settings__view">
            <button
              className={cn("Settings__view--btn", viewEnum.left, {
                active: settings[setting.key] === viewEnum.left,
              })}
              onClick={() =>
                dispatch(setSetting({ key: setting.key, data: viewEnum.left }))
              }
            >
              <div className="filled" />
              <div />
            </button>
            <button
              className={cn("Settings__view--btn", viewEnum.right, {
                active: settings[setting.key] === viewEnum.right,
              })}
              onClick={() =>
                dispatch(setSetting({ key: setting.key, data: viewEnum.right }))
              }
            >
              <div />
              <div className="filled" />
            </button>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="Settings__wrapper">
      <div className="Settings__left">
        {data.left.map((setting) => (
          <div className="Settings__item" key={setting.key}>
            <div className="Settings__content">
              <p className="Settings__title">{setting.title}</p>
              <p className="Settings__description">{setting.description}</p>
            </div>
            <div className="Settings__controler">
              {renderSwitcherOrSelector(setting)}
            </div>
          </div>
        ))}
      </div>
      <div className="Settings__right">
        {data.right.map((setting) => (
          <div className="Settings__item" key={setting.key}>
            <div className="Settings__content">
              <p className="Settings__title">{setting.title}</p>
              <p className="Settings__description">{setting.description}</p>
            </div>
            <div className="Settings__controler">
              <Switch
                onChange={onSwitchChange}
                checked={settings[setting.key]}
                id={setting.key}
                onHandleColor="#099C73"
                offHandleColor="#999EA5"
                checkedIcon={false}
                uncheckedIcon={false}
                handleDiameter={28}
                height={24}
                width={61}
                borderRadius={50}
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                className="Settings__switch"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
