import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useModal from "../../hooks/modal/use-modal";
import { setModal, setModalClose } from "../../store/editor/editor.actions";
import { setIsEndInterview } from "../../store/video-meeting/assessment.actions";

import * as types from "../../types/editor";
import { editorSelectors } from "../../store/editor/editor.selectors";

interface Props {
  children?: React.ReactNode;
  isOpen?: types.CodingModalsEnum | null;
  className?: string;
  closeBtn?: boolean;
  onOpen?: boolean;
  title?: string;
}

export const Modal: React.FC<Props> = (props) => {
  const { children, className, closeBtn = true, isOpen = null, title } = props;
  const {modalClose} = useSelector(
    editorSelectors.getAllState
  );
  useModal(isOpen);
  const dispatch = useDispatch();
  const ref = useRef(null);

  const finalClassName = [`Modal`];
  if (className) finalClassName.push(className);

  const handleClose = () => {
    if(isOpen===types.CodingModalsEnum.endInterview){
      dispatch(setIsEndInterview(false))
    }
    
    dispatch(setModal(null));
  };
  
  useEffect(()=>{
    if(modalClose===true){
      handleClose();
      dispatch(setModalClose(false))
    }
  },[modalClose])

  useEffect(() => {
    const close = (e) => {
      if (e.target == ref.current) {
        handleClose();
      }
    };
    // window.addEventListener("click", close);

    return () => {
      window.removeEventListener("click", close);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      ref.current.style.display = "flex";
    } else {
      ref.current.style.display = "none";
    }
  }, [isOpen]);
  return (
    <div className={finalClassName.join(" ")} ref={ref}>
      <div className="Modal__content">
        <div className="Modal__header">
          {title && <div className="Modal__title">{title}</div>}
          {closeBtn && isOpen !== "deadline" && isOpen !== "inProgress" && isOpen!== "congratulations" && isOpen!== "completed" && isOpen!== "interviewEnded" &&(
            <button className="Modal__close" onClick={handleClose}>
              &times;
            </button>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
