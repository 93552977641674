import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as monaco from "monaco-editor";
import * as VimMode from "monaco-vim";
import { EmacsExtension } from "monaco-emacs";
//store
import { editorSelectors } from "../../store/editor/editor.selectors";
//types
import * as types from "../../types/editor";

const useSettings = () => {
  const { settings, editor } = useSelector(editorSelectors.getAllState);
  const {
    minimap,
    tabSpacing,
    fontSize,
    autoBrackers,
    mode,
    colorScheme,
    codeCompletion,
  } = settings;
  const [vimModeState, setVimMode] = useState(null);
  const [emacsModeState, setEmacsMode] = useState(null);

  const remoteCursorElement = document.querySelector<HTMLElement>(
    ".monaco-remote-cursor"
  )!;

  useEffect(() => {
    document.documentElement.classList.add(`theme-${colorScheme}`);
  }, []);

  //change remote cursor height settings
  useEffect(() => {
    if (remoteCursorElement) {
      remoteCursorElement.style.height = `calc(${fontSize.data} * 1.5)`;
    }
  }, [remoteCursorElement, fontSize.data]);

  useEffect(() => {
    //change editor settings
    if (editor) {
      editor.updateOptions({
        autoClosingBrackets: autoBrackers ? "always" : "never",
        minimap: {
          enabled: minimap,
        },
        quickSuggestions: codeCompletion,
        tabSize: tabSpacing.data,
        fontSize: fontSize.data,
        wordBasedSuggestions: false,
      });
    }

    //set theme
    monaco.editor.setTheme(colorScheme);
    document.documentElement.className = "";
    document.documentElement.classList.add(`theme-${colorScheme}`);
  }, [
    minimap,
    tabSpacing,
    fontSize,
    autoBrackers,
    colorScheme,
    editor,
    codeCompletion,
  ]);

  //change keybindings mode
  useEffect(() => {
    if (!editor) {
      return;
    }

    let statusNode = document.getElementById("status");

    switch (mode.data) {
      case types.editorModeEnun.emacs:
        if (vimModeState) {
          vimModeState.dispose();
          setVimMode(null);
        }

        const emacsMode = new EmacsExtension(editor);
        setEmacsMode(emacsMode);

        emacsMode.onDidMarkChange((ev: boolean) => {
          console.log("set");
          statusNode.textContent = ev ? "Mark Set!" : "Mark Unset";
        });

        emacsMode.onDidChangeKey((str) => {
          console.log("change");
          statusNode.textContent = str;
        });

        emacsMode.start();
        break;
      case types.editorModeEnun.vim:
        if (emacsModeState) {
          emacsModeState.dispose();
          setEmacsMode(null);
        }

        const vimMode = VimMode.initVimMode(editor, statusNode);
        setVimMode(vimMode);
        break;
      default:
        if (vimModeState) {
          vimModeState.dispose();
          setVimMode(null);
        }

        if (emacsModeState) {
          emacsModeState.dispose();
          setEmacsMode(null);
        }
        break;
    }
  }, [mode, editor]);

  useEffect(() => {
    monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: !settings.errorHighlightning,
      noSyntaxValidation: !settings.errorHighlightning,
    });
  }, [settings.errorHighlightning]);
};

export default useSettings;
