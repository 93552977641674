import React from "react";
import Header from "./questionPage-header";
import Content from "./questionPage-content";
import StarterCode from "./StarterCodePage"
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { assessmentSelectors } from "../../../../../store/assessment/assessment.selectors";
import { TestCases } from "../../../../task-description/testCases";
import { useDispatch } from "react-redux";
import { setModalClose } from "../../../../../store/editor/editor.actions";
import { setActiveTask, setAssessmentTask } from "../../../../../store/assessment/assessment.actions";

function QuestionPage({ data }) {
  const { isOnline, activeTask } = useSelector(assessmentSelectors.getAllState);
  const dispatch=useDispatch()

  return (
    <>
      <Header data={data} />
      <Tabs className="questionPage_tabs">
        <TabList className="questionPage_tabs__tab-list">
          <Tab>Problem</Tab>
          <Tab>Starter code</Tab>
          {!isOnline && (
            <Tab>Test Cases</Tab>
          )}
        </TabList>

        <div className="questionPage_tabs__content scroll">
          <TabPanel className="questionPage_tabs__tab-panel">
            {/* <Problem problems={assessmentTask.problems} /> */}
            <Content data={data} />
          </TabPanel>
          <TabPanel className="questionPage_tabs__tab-panel">
            <StarterCode data={data}/>
          </TabPanel>
          {!isOnline && (
            <TabPanel className="questionPage_tabs__tab-panel">
              <TestCases testCases={data} />
            </TabPanel>
          )}
        </div>
      </Tabs>
      <div className="questionPage_buttons">
        <button className="c-btn c-btn--greenBorder">Edit</button>
        <button className="c-btn c-btn--greenBorder">
          Replace for this one
        </button>
        <button className="c-btn c-btn--green"
          onClick={()=>{
            dispatch(setAssessmentTask(data))
            dispatch(setActiveTask(null))           
            dispatch(setModalClose(true))
          }}
        >Add to Assign</button>
      </div>
    </>
  );
}

export default QuestionPage;
