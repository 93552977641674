export enum actionTypes {
  SET_PACKAGE = "footer/SET_PACKAGE",
  SET_LANGUAGE = "footer/SET_LANGUAGE",
  SET_CONSOLE_WARNING = "footer/SET_CONSOLE_WARNING",
  SET_CONSOLE_WARNING_OPEN = "footer/SET_CONSOLE_WARNING_OPEN",
  RESET_FOOTER_STATE = "footer/RESET_FOOTER_STATE",
  RUN_CODE_STATE = "footer/RUN_CODE_STATE",
  IS_TERMINAL_LOADING = "footer/IS_TERMINAL_LOADING",
  CODE_ERROR = "footer/CODE_ERROR",
  SET_SUBMISSION_RESULTS = "footer/SET_SUBMISSION_RESULTS"
}

export type Actions =
  | SetPackage
  | SetLanguage
  | SetConsoleWarningOpen
  | SetConsoleWarning
  | ResetFooterState
  | RunCodeState
  | Error
  | IsTerminalLoading
  | SetSubmissionResults;

export interface SetPackage {
  type: actionTypes.SET_PACKAGE;
  payload: any;
}

export interface SetLanguage {
  type: actionTypes.SET_LANGUAGE;
  payload: any;
}

export interface SetConsoleWarningOpen {
  type: actionTypes.SET_CONSOLE_WARNING_OPEN;
  payload: any;
}

export interface SetConsoleWarning {
  type: actionTypes.SET_CONSOLE_WARNING;
  payload: any;
}

export interface ResetFooterState {
  type: actionTypes.RESET_FOOTER_STATE;
}

export interface RunCodeState {
  type: actionTypes.RUN_CODE_STATE;
  payload: any;
}

export interface IsTerminalLoading {
  type: actionTypes.IS_TERMINAL_LOADING;
  payload: any;
}

export interface Error {
  type: actionTypes.CODE_ERROR;
  payload: any;
}

export interface SetSubmissionResults{
  type : actionTypes.SET_SUBMISSION_RESULTS;
  payload:any;
}
