import React, { useState } from 'react';
import { FiUsers } from 'react-icons/fi';
import "../../../styles/components/_participantList.scss"
import Badge from "@mui/material/Badge";


function ParticipantList({ participants ,localParticipant,participantLength}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
       {isMenuOpen && (
        <div className="participant_modal">
            <ul>
              {participants.map((participant, index) => {
                const identity = participant.participant.identity;
                return <li key={index}>{identity}</li>;
              })}
              <li>{localParticipant}(You)</li>
            </ul>
          </div>
        )}
    <div className="badge-container">
    <Badge badgeContent={participantLength} color="primary" />
    <FiUsers className="icon" onClick={handleMenuToggle} />
</div>
</div>

  );
}

export default ParticipantList;
