import React, { useState } from 'react'
import Filters from './filters/filters'
import Questions from './questions/questions'
import Display from './display/display'
import fetchData from '../../../../hooks/api/fetchData'
import { useSelector } from 'react-redux'
import { assessmentSelectors } from '../../../../store/assessment/assessment.selectors'
import { getEnv } from "@urecruits/api";
const {API_ASSESSMENT}=getEnv()

function ListOfQuestions({ textData }) {
  const [dataById, setDataById] = useState(null)
  const [searchValue, setSearchValue] = useState(null)
  const [filterValue, setFilterValue] = useState(null)
  const { isOnline } = useSelector(assessmentSelectors.getAllState);

  const showDisplay = (id: string) => {
    fetchData(`${API_ASSESSMENT}/api/${isOnline ? "live-coding" : "take-home-task"}/${id}`,setDataById)
  }

  const search=(value)=>{
    setSearchValue(value)
  }

  const filter=(value)=>{
    setFilterValue(value)
  }
    return (
        <div className='listOfQuestions__sides'>
            <div>
                <Filters searchData={search} filterData={filter}/>
                <Questions showDisplay={showDisplay} filterValue={filterValue} searchValue={searchValue}/>
            </div>
            <Display textData={textData} data={dataById} />
        </div>
    )
}

export default ListOfQuestions