export const languageMapping = [
    {name:'Assembly (NASM 2.14.02)',value:'assembly',suggestion: [
      {
        label: "mov",
        insertText: "mov ${1:destination}, ${2:source}",
        
        documentation: "Move data between registers/memory",

      },
      {
        label: "add",
        insertText: "add ${1:destination}, ${2:source}",
        
        documentation: "Add two operands",

      },
      {
        label: "sub",
        insertText: "sub ${1:destination}, ${2:source}",
        
        documentation: "Subtract two operands",

      },
      {
        label: "cmp",
        insertText: "cmp ${1:operand1}, ${2:operand2}",
        
        documentation: "Compare two operands",

      },
      {
        label: "jmp",
        insertText: "jmp ${1:label}",
        
        documentation: "Unconditional jump to label",

      },
      {
        label: "je",
        insertText: "je ${1:label}",
        
        documentation: "Jump if equal",

      },
      {
        label: "jne",
        insertText: "jne ${1:label}",
        
        documentation: "Jump if not equal",

      },
      {
        label: "jg",
        insertText: "jg ${1:label}",
        
        documentation: "Jump if greater",

      },
      {
        label: "jl",
        insertText: "jl ${1:label}",
        
        documentation: "Jump if less",

      },
      {
        label: "call",
        insertText: "call ${1:subroutine}",
        
        documentation: "Call a subroutine",

      },
      {
        label: "ret",
        insertText: "ret",
        
        documentation: "Return from subroutine",

      },
      {
        label: "db",
        insertText: "db ${1:data}",
        
        documentation: "Define byte",

      },
      {
        label: "dw",
        insertText: "dw ${1:data}",
        
        documentation: "Define word",

      },
      {
        label: "dd",
        insertText: "dd ${1:data}",
        
        documentation: "Define double word",

      },
      {
        label: "section",
        insertText: "section .${1:section_name}",
        
        documentation: "Define section",

      },
      {
        label: "global",
        insertText: "global ${1:symbol}",
        
        documentation: "Declare global symbol",

      },
      {
        label: "extern",
        insertText: "extern ${1:symbol}",
        
        documentation: "Declare external symbol",

      },
      {
        label: "segment",
        insertText: "segment ${1:registers}",
        
        documentation: "Define segment",

      },
      {
        label: "inc",
        insertText: "inc ${1:destination}",
        
        documentation: "Increment operand",

      },
      {
        label: "dec",
        insertText: "dec ${1:destination}",
        
        documentation: "Decrement operand",

      },
    ]
    },
    {name:'Bash (5.0.0)',value: 'shell',suggestion: [
      {
        label: "echo",
        insertText: "echo ${1:message}",
        
        documentation: "Print a message",

      },
      {
        label: "if",
        insertText: [
          "if ${1:condition}; then",
          "\t$0",
          "fi",
        ].join("\n"),
        
        documentation: "Conditional statement",

      },
      {
        label: "for",
        insertText: [
          "for ${1:item} in ${2:items}; do",
          "\t$0",
          "done",
        ].join("\n"),
        
        documentation: "For loop",

      },
      {
        label: "while",
        insertText: [
          "while ${1:condition}; do",
          "\t$0",
          "done",
        ].join("\n"),
        
        documentation: "While loop",

      },
      {
        label: "case",
        insertText: [
          "case ${1:variable} in",
          "\t${2:pattern})",
          "\t\t$0",
          "\t;;",
          "esac",
        ].join("\n"),
        
        documentation: "Case statement",

      },
      {
        label: "function",
        insertText: [
          "function ${1:function_name}() {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Define a function",

      },
      {
        label: "read",
        insertText: "read ${1:variable}",
        
        documentation: "Read user input",

      },
      {
        label: "while",
        insertText: [
          "while ${1:condition}; do",
          "\t$0",
          "done",
        ].join("\n"),
        
        documentation: "While loop",

      },
      {
        label: "shift",
        insertText: "shift",
        
        documentation: "Shift command-line arguments",

      },
      {
        label: "export",
        insertText: "export ${1:variable}",
        
        documentation: "Export variable to subshells",

      },
      {
        label: "unset",
        insertText: "unset ${1:variable}",
        
        documentation: "Unset a variable",

      },
      {
        label: "source",
        insertText: "source ${1:file}",
        
        documentation: "Run commands from a file",

      },
      {
        label: "alias",
        insertText: "alias ${1:alias_name}='${2:command}'",
        
        documentation: "Create an alias",

      },
      {
        label: "export",
        insertText: "export ${1:variable}",
        
        documentation: "Export variable to subshells",

      },
      {
        label: "break",
        insertText: "break",
        
        documentation: "Exit a loop or switch",

      },
      {
        label: "continue",
        insertText: "continue",
        
        documentation: "Skip to the next iteration of a loop",

      },
      {
        label: "return",
        insertText: "return ${1:status}",
        
        documentation: "Return from a function",

      },
      {
        label: "exit",
        insertText: "exit ${1:status}",
        
        documentation: "Exit the shell or script",

      },
      {
        label: "grep",
        insertText: "grep ${1:pattern} ${2:file}",
        
        documentation: "Search for a pattern in a file",

      },
      {
        label: "sed",
        insertText: "sed 's/${1:pattern}/${2:replacement}/' ${3:file}",
        
        documentation: "Stream editor for text manipulation",

      },
      {
        label: "awk",
        insertText: "awk '{ ${1:print $0} }' ${2:file}",
        
        documentation: "Pattern scanning and text processing language",

      },
      {
        label: "chmod",
        insertText: "chmod ${1:permissions} ${2:file}",
        
        documentation: "Change file permissions",

      },
      {
        label: "chown",
        insertText: "chown ${1:user}:${2:group} ${3:file}",
        
        documentation: "Change file owner and group",

      },
      {
        label: "mkdir",
        insertText: "mkdir ${1:directory}",
        
        documentation: "Create a directory",

      },
      {
        label: "rm",
        insertText: "rm ${1:file}",
        
        documentation: "Remove a file or directory",

      },
    ]
    },
    {name:'Basic (FBC 1.07.1)',value: 'basic',suggestion: [
      {
        label: "PRINT",
        insertText: "PRINT ${1:message}",
        
        documentation: "Print a message",

      },
      {
        label: "INPUT",
        insertText: "INPUT ${1:variable}",
        
        documentation: "Read user input",

      },
      {
        label: "IF",
        insertText: [
          "IF ${1:condition} THEN",
          "\t$0",
          "END IF",
        ].join("\n"),
        
        documentation: "Conditional statement",

      },
      {
        label: "FOR",
        insertText: [
          "FOR ${1:variable} = ${2:start} TO ${3:end} STEP ${4:step}",
          "\t$0",
          "NEXT ${1:variable}",
        ].join("\n"),
        
        documentation: "For loop",

      },
      {
        label: "DO",
        insertText: [
          "DO",
          "\t$0",
          "LOOP UNTIL ${1:condition}",
        ].join("\n"),
        
        documentation: "Do loop",

      },
      {
        label: "WHILE",
        insertText: [
          "WHILE ${1:condition}",
          "\t$0",
          "WEND",
        ].join("\n"),
        
        documentation: "While loop",

      },
      {
        label: "SELECT CASE",
        insertText: [
          "SELECT CASE ${1:expression}",
          "\tCASE ${2:value1}",
          "\t\t$0",
          "\tCASE ${3:value2}",
          "\t\t",
          "\tCASE ELSE",
          "\t\t",
          "END SELECT",
        ].join("\n"),
        
        documentation: "Select Case statement",

      },
      {
        label: "SUB",
        insertText: [
          "SUB ${1:subroutine_name} (${2:parameters})",
          "\t$0",
          "END SUB",
        ].join("\n"),
        
        documentation: "Define a subroutine",

      },
      {
        label: "FUNCTION",
        insertText: [
          "FUNCTION ${1:function_name} (${2:parameters})",
          "\t$0",
          "END FUNCTION",
        ].join("\n"),
        
        documentation: "Define a function",

      },
      {
        label: "EXIT",
        insertText: "EXIT",
        
        documentation: "Exit a loop or subroutine",

      },
      {
        label: "GOTO",
        insertText: "GOTO ${1:line_number}",
        
        documentation: "Go to a specific line number",

      },
      {
        label: "DIM",
        insertText: "DIM ${1:array}(${2:dimensions})",
        
        documentation: "Declare an array",

      },
      {
        label: "REM",
        insertText: "REM ${1:comment}",
        
        documentation: "Add a comment",

      },
      {
        label: "EXIT DO",
        insertText: "EXIT DO",
        
        documentation: "Exit a DO loop",

      },
      {
        label: "EXIT FOR",
        insertText: "EXIT FOR",
        
        documentation: "Exit a FOR loop",

      },
      {
        label: "EXIT WHILE",
        insertText: "EXIT WHILE",
        
        documentation: "Exit a WHILE loop",

      },
      {
        label: "CONST",
        insertText: "CONST ${1:constant} = ${2:value}",
        
        documentation: "Define a constant",

      },
      {
        label: "DATA",
        insertText: "DATA ${1:data_values}",
        
        documentation: "Provide data for READ statements",

      },
      {
        label: "READ",
        insertText: "READ ${1:variables}",
        
        documentation: "Read data into variables",

      },
      {
        label: "RESTORE",
        insertText: "RESTORE",
        
        documentation: "Reset DATA pointer",

      },
      {
        label: "GOSUB",
        insertText: "GOSUB ${1:line_number}",
        
        documentation: "Go to a subroutine",

      },
      {
        label: "RETURN",
        insertText: "RETURN",
        
        documentation: "Return from a subroutine",

      },
      {
        label: "LINE INPUT",
        insertText: "LINE INPUT ${1:variable}",
        
        documentation: "Read a line of text",

      },
      {
        label: "OPEN",
        insertText: "OPEN ${1:#unit} ${2:file_name} ${3:FOR ${4:mode} AS ${5:#file_handle}}",
        
        documentation: "Open a file",

      },
      {
        label: "CLOSE",
        insertText: "CLOSE ${1:#unit}",
        
        documentation: "Close a file",

      },
    ]
    },
    {name:'C (Clang 7.0.1)',value: 'c',suggestion: [
      {
        label: "printf",
        insertText: 'printf("${1:format_string}", ${2:args});',
        
        documentation: "Print formatted output",

      },
      {
        label: "scanf",
        insertText: 'scanf("${1:format_string}", ${2:args});',
        
        documentation: "Read formatted input",

      },
      {
        label: "if",
        insertText: [
          "if (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "If statement",

      },
      {
        label: "else",
        insertText: [
          "else {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Else statement",

      },
      {
        label: "while",
        insertText: [
          "while (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "While loop",

      },
      {
        label: "for",
        insertText: [
          "for (${1:init}; ${2:condition}; ${3:increment}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "For loop",

      },
      {
        label: "switch",
        insertText: [
          "switch (${1:expression}) {",
          "\tcase ${2:value}:",
          "\t\t$0",
          "\t\tbreak;",
          "\tdefault:",
          "}",
        ].join("\n"),
        
        documentation: "Switch statement",

      },
      {
        label: "case",
        insertText: "case ${1:value}: $0 break;",
        
        documentation: "Case in a switch statement",

      },
      {
        label: "default",
        insertText: "default: $0",
        
        documentation: "Default case in a switch statement",

      },
      {
        label: "break",
        insertText: "break;",
        
        documentation: "Break statement",

      },
      {
        label: "continue",
        insertText: "continue;",
        
        documentation: "Continue statement",

      },
      {
        label: "return",
        insertText: "return ${1:value};",
        
        documentation: "Return statement",

      },
      {
        label: "int",
        insertText: "int ${1:variable_name} = ${2:value};",
        
        documentation: "Declare an integer variable",

      },
      {
        label: "float",
        insertText: "float ${1:variable_name} = ${2:value};",
        
        documentation: "Declare a floating-point variable",

      },
      {
        label: "char",
        insertText: "char ${1:variable_name} = '${2:char}';",
        
        documentation: "Declare a character variable",

      },
      {
        label: "void",
        insertText: "void ${1:function_name}(${2:parameters}) { $0 }",
        
        documentation: "Declare a void function",

      },
      {
        label: "const",
        insertText: "const ${1:type} ${2:variable_name} = ${3:value};",
        
        documentation: "Declare a constant",

      },
      {
        label: "sizeof",
        insertText: "sizeof(${1:type});",
        
        documentation: "Get the size of a type",

      },
      {
        label: "malloc",
        insertText: "malloc(${1:size});",
        
        documentation: "Allocate memory dynamically",

      },
      {
        label: "free",
        insertText: "free(${1:pointer});",
        
        documentation: "Free dynamically allocated memory",

      },
    ]},
    {name:'C++ (Clang 7.0.1)',value: 'cpp',suggestion:[
      {
        label: "cout",
        insertText: "std::cout << ${1:value} << std::endl;",
        
        documentation: "Print to standard output",

      },
      {
        label: "cin",
        insertText: "std::cin >> ${1:variable};",
        
        documentation: "Read from standard input",

      },
      {
        label: "if",
        insertText: [
          "if (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "If statement",

      },
      {
        label: "else",
        insertText: [
          "else {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Else statement",

      },
      {
        label: "while",
        insertText: [
          "while (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "While loop",

      },
      {
        label: "for",
        insertText: [
          "for (${1:init}; ${2:condition}; ${3:increment}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "For loop",

      },
      {
        label: "switch",
        insertText: [
          "switch (${1:expression}) {",
          "\tcase ${2:value}:",
          "\t\t$0",
          "\t\tbreak;",
          "\tdefault:",
          "}",
        ].join("\n"),
        
        documentation: "Switch statement",

      },
      {
        label: "case",
        insertText: "case ${1:value}: $0 break;",
        
        documentation: "Case in a switch statement",

      },
      {
        label: "default",
        insertText: "default: $0",
        
        documentation: "Default case in a switch statement",

      },
      {
        label: "break",
        insertText: "break;",
        
        documentation: "Break statement",

      },
      {
        label: "continue",
        insertText: "continue;",
        
        documentation: "Continue statement",

      },
      {
        label: "return",
        insertText: "return ${1:value};",
        
        documentation: "Return statement",

      },
      {
        label: "int",
        insertText: "int ${1:variable_name} = ${2:value};",
        
        documentation: "Declare an integer variable",

      },
      {
        label: "float",
        insertText: "float ${1:variable_name} = ${2:value};",
        
        documentation: "Declare a floating-point variable",

      },
      {
        label: "double",
        insertText: "double ${1:variable_name} = ${2:value};",
        
        documentation: "Declare a double variable",

      },
      {
        label: "char",
        insertText: "char ${1:variable_name} = '${2:char}';",
        
        documentation: "Declare a character variable",

      },
      {
        label: "string",
        insertText: "std::string ${1:variable_name} = \"${2:value}\";",
        
        documentation: "Declare a string variable",

      },
      {
        label: "bool",
        insertText: "bool ${1:variable_name} = ${2:true};",
        
        documentation: "Declare a boolean variable",

      },
      {
        label: "void",
        insertText: "void ${1:function_name}(${2:parameters}) { $0 }",
        
        documentation: "Declare a void function",

      },
      {
        label: "const",
        insertText: "const ${1:type} ${2:variable_name} = ${3:value};",
        
        documentation: "Declare a constant",

      },
      {
        label: "class",
        insertText: [
          "class ${1:class_name} {",
          "public:",
          "\t${1}(${2:parameters}) { $0 }",
          "};",
        ].join("\n"),
        
        documentation: "Declare a class",

      },
      {
        label: "struct",
        insertText: [
          "struct ${1:struct_name} {",
          "\t${1}(${2:parameters}) { $0 }",
          "};",
        ].join("\n"),
        
        documentation: "Declare a struct",

      },
    ]
    },
    {name:'C (GCC 7.4.0)',value: 'c',suggestion:[
      {
        label: "printf",
        insertText: 'printf("${1:format_string}", ${2:args});',
        
        documentation: "Print formatted output",

      },
      {
        label: "scanf",
        insertText: 'scanf("${1:format_string}", ${2:args});',
        
        documentation: "Read formatted input",

      },
      {
        label: "if",
        insertText: [
          "if (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "If statement",

      },
      {
        label: "else",
        insertText: [
          "else {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Else statement",

      },
      {
        label: "while",
        insertText: [
          "while (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "While loop",

      },
      {
        label: "for",
        insertText: [
          "for (${1:init}; ${2:condition}; ${3:increment}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "For loop",

      },
      {
        label: "switch",
        insertText: [
          "switch (${1:expression}) {",
          "\tcase ${2:value}:",
          "\t\t$0",
          "\t\tbreak;",
          "\tdefault:",
          "}",
        ].join("\n"),
        
        documentation: "Switch statement",

      },
      {
        label: "case",
        insertText: "case ${1:value}: $0 break;",
        
        documentation: "Case in a switch statement",

      },
      {
        label: "default",
        insertText: "default: $0",
        
        documentation: "Default case in a switch statement",

      },
      {
        label: "break",
        insertText: "break;",
        
        documentation: "Break statement",

      },
      {
        label: "continue",
        insertText: "continue;",
        
        documentation: "Continue statement",

      },
      {
        label: "return",
        insertText: "return ${1:value};",
        
        documentation: "Return statement",

      },
      {
        label: "int",
        insertText: "int ${1:variable_name} = ${2:value};",
        
        documentation: "Declare an integer variable",

      },
      {
        label: "float",
        insertText: "float ${1:variable_name} = ${2:value};",
        
        documentation: "Declare a floating-point variable",

      },
      {
        label: "char",
        insertText: "char ${1:variable_name} = '${2:char}';",
        
        documentation: "Declare a character variable",

      },
      {
        label: "void",
        insertText: "void ${1:function_name}(${2:parameters}) { $0 }",
        
        documentation: "Declare a void function",

      },
      {
        label: "const",
        insertText: "const ${1:type} ${2:variable_name} = ${3:value};",
        
        documentation: "Declare a constant",

      },
      {
        label: "sizeof",
        insertText: "sizeof(${1:type});",
        
        documentation: "Get the size of a type",

      },
      {
        label: "malloc",
        insertText: "malloc(${1:size});",
        
        documentation: "Allocate memory dynamically",

      },
      {
        label: "free",
        insertText: "free(${1:pointer});",
        
        documentation: "Free dynamically allocated memory",

      },
    ]
    },
    {name:'C++ (GCC 7.4.0)',value: 'cpp',suggestion: [
      {
        label: "cout",
        insertText: "std::cout << ${1:value} << std::endl;",
        
        documentation: "Print to standard output",

      },
      {
        label: "cin",
        insertText: "std::cin >> ${1:variable};",
        
        documentation: "Read from standard input",

      },
      {
        label: "if",
        insertText: [
          "if (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "If statement",

      },
      {
        label: "else",
        insertText: [
          "else {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Else statement",

      },
      {
        label: "while",
        insertText: [
          "while (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "While loop",

      },
      {
        label: "for",
        insertText: [
          "for (${1:init}; ${2:condition}; ${3:increment}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "For loop",

      },
      {
        label: "switch",
        insertText: [
          "switch (${1:expression}) {",
          "\tcase ${2:value}:",
          "\t\t$0",
          "\t\tbreak;",
          "\tdefault:",
          "}",
        ].join("\n"),
        
        documentation: "Switch statement",

      },
      {
        label: "case",
        insertText: "case ${1:value}: $0 break;",
        
        documentation: "Case in a switch statement",

      },
      {
        label: "default",
        insertText: "default: $0",
        
        documentation: "Default case in a switch statement",

      },
      {
        label: "break",
        insertText: "break;",
        
        documentation: "Break statement",

      },
      {
        label: "continue",
        insertText: "continue;",
        
        documentation: "Continue statement",

      },
      {
        label: "return",
        insertText: "return ${1:value};",
        
        documentation: "Return statement",

      },
      {
        label: "int",
        insertText: "int ${1:variable_name} = ${2:value};",
        
        documentation: "Declare an integer variable",

      },
      {
        label: "float",
        insertText: "float ${1:variable_name} = ${2:value};",
        
        documentation: "Declare a floating-point variable",

      },
      {
        label: "double",
        insertText: "double ${1:variable_name} = ${2:value};",
        
        documentation: "Declare a double variable",

      },
      {
        label: "char",
        insertText: "char ${1:variable_name} = '${2:char}';",
        
        documentation: "Declare a character variable",

      },
      {
        label: "string",
        insertText: "std::string ${1:variable_name} = \"${2:value}\";",
        
        documentation: "Declare a string variable",

      },
      {
        label: "bool",
        insertText: "bool ${1:variable_name} = ${2:true};",
        
        documentation: "Declare a boolean variable",

      },
      {
        label: "void",
        insertText: "void ${1:function_name}(${2:parameters}) { $0 }",
        
        documentation: "Declare a void function",

      },
      {
        label: "const",
        insertText: "const ${1:type} ${2:variable_name} = ${3:value};",
        
        documentation: "Declare a constant",

      },
      {
        label: "class",
        insertText: [
          "class ${1:class_name} {",
          "public:",
          "\t${1}(${2:parameters}) { $0 }",
          "};",
        ].join("\n"),
        
        documentation: "Declare a class",

      },
      {
        label: "struct",
        insertText: [
          "struct ${1:struct_name} {",
          "\t${1}(${2:parameters}) { $0 }",
          "};",
        ].join("\n"),
        
        documentation: "Declare a struct",

      },
    ]
    },
    {name:'C (GCC 8.3.0)',value: 'c',suggestion: [
      {
        label: "printf",
        insertText: 'printf("${1:format_string}", ${2:args});',
        
        documentation: "Print formatted output",

      },
      {
        label: "scanf",
        insertText: 'scanf("${1:format_string}", ${2:args});',
        
        documentation: "Read formatted input",

      },
      {
        label: "if",
        insertText: [
          "if (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "If statement",

      },
      {
        label: "else",
        insertText: [
          "else {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Else statement",

      },
      {
        label: "while",
        insertText: [
          "while (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "While loop",

      },
      {
        label: "for",
        insertText: [
          "for (${1:init}; ${2:condition}; ${3:increment}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "For loop",

      },
      {
        label: "switch",
        insertText: [
          "switch (${1:expression}) {",
          "\tcase ${2:value}:",
          "\t\t$0",
          "\t\tbreak;",
          "\tdefault:",
          "}",
        ].join("\n"),
        
        documentation: "Switch statement",

      },
      {
        label: "case",
        insertText: "case ${1:value}: $0 break;",
        
        documentation: "Case in a switch statement",

      },
      {
        label: "default",
        insertText: "default: $0",
        
        documentation: "Default case in a switch statement",

      },
      {
        label: "break",
        insertText: "break;",
        
        documentation: "Break statement",

      },
      {
        label: "continue",
        insertText: "continue;",
        
        documentation: "Continue statement",

      },
      {
        label: "return",
        insertText: "return ${1:value};",
        
        documentation: "Return statement",

      },
      {
        label: "int",
        insertText: "int ${1:variable_name} = ${2:value};",
        
        documentation: "Declare an integer variable",

      },
      {
        label: "float",
        insertText: "float ${1:variable_name} = ${2:value};",
        
        documentation: "Declare a floating-point variable",

      },
      {
        label: "char",
        insertText: "char ${1:variable_name} = '${2:char}';",
        
        documentation: "Declare a character variable",

      },
      {
        label: "void",
        insertText: "void ${1:function_name}(${2:parameters}) { $0 }",
        
        documentation: "Declare a void function",

      },
      {
        label: "const",
        insertText: "const ${1:type} ${2:variable_name} = ${3:value};",
        
        documentation: "Declare a constant",

      },
      {
        label: "sizeof",
        insertText: "sizeof(${1:type});",
        
        documentation: "Get the size of a type",

      },
      {
        label: "malloc",
        insertText: "malloc(${1:size});",
        
        documentation: "Allocate memory dynamically",

      },
      {
        label: "free",
        insertText: "free(${1:pointer});",
        
        documentation: "Free dynamically allocated memory",

      },
    ]
    },
    {name:'C++ (GCC 8.3.0)',value: 'cpp',suggestion: [
      {
        label: "cout",
        insertText: "std::cout << ${1:value} << std::endl;",
        
        documentation: "Print to standard output",

      },
      {
        label: "cin",
        insertText: "std::cin >> ${1:variable};",
        
        documentation: "Read from standard input",

      },
      {
        label: "if",
        insertText: [
          "if (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "If statement",

      },
      {
        label: "else",
        insertText: [
          "else {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Else statement",

      },
      {
        label: "while",
        insertText: [
          "while (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "While loop",

      },
      {
        label: "for",
        insertText: [
          "for (${1:init}; ${2:condition}; ${3:increment}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "For loop",

      },
      {
        label: "switch",
        insertText: [
          "switch (${1:expression}) {",
          "\tcase ${2:value}:",
          "\t\t$0",
          "\t\tbreak;",
          "\tdefault:",
          "}",
        ].join("\n"),
        
        documentation: "Switch statement",

      },
      {
        label: "case",
        insertText: "case ${1:value}: $0 break;",
        
        documentation: "Case in a switch statement",

      },
      {
        label: "default",
        insertText: "default: $0",
        
        documentation: "Default case in a switch statement",

      },
      {
        label: "break",
        insertText: "break;",
        
        documentation: "Break statement",

      },
      {
        label: "continue",
        insertText: "continue;",
        
        documentation: "Continue statement",

      },
      {
        label: "return",
        insertText: "return ${1:value};",
        
        documentation: "Return statement",

      },
      {
        label: "int",
        insertText: "int ${1:variable_name} = ${2:value};",
        
        documentation: "Declare an integer variable",

      },
      {
        label: "float",
        insertText: "float ${1:variable_name} = ${2:value};",
        
        documentation: "Declare a floating-point variable",

      },
      {
        label: "double",
        insertText: "double ${1:variable_name} = ${2:value};",
        
        documentation: "Declare a double variable",

      },
      {
        label: "char",
        insertText: "char ${1:variable_name} = '${2:char}';",
        
        documentation: "Declare a character variable",

      },
      {
        label: "string",
        insertText: "std::string ${1:variable_name} = \"${2:value}\";",
        
        documentation: "Declare a string variable",

      },
      {
        label: "bool",
        insertText: "bool ${1:variable_name} = ${2:true};",
        
        documentation: "Declare a boolean variable",

      },
      {
        label: "void",
        insertText: "void ${1:function_name}(${2:parameters}) { $0 }",
        
        documentation: "Declare a void function",

      },
      {
        label: "const",
        insertText: "const ${1:type} ${2:variable_name} = ${3:value};",
        
        documentation: "Declare a constant",

      },
      {
        label: "class",
        insertText: [
          "class ${1:class_name} {",
          "public:",
          "\t${1}(${2:parameters}) { $0 }",
          "};",
        ].join("\n"),
        
        documentation: "Declare a class",

      },
      {
        label: "struct",
        insertText: [
          "struct ${1:struct_name} {",
          "\t${1}(${2:parameters}) { $0 }",
          "};",
        ].join("\n"),
        
        documentation: "Declare a struct",

      },
    ]
    },
    {name:'C (GCC 9.2.0)',value: 'c',suggestion: [
      {
        label: "printf",
        insertText: 'printf("${1:format_string}", ${2:args});',
        
        documentation: "Print formatted output",

      },
      {
        label: "scanf",
        insertText: 'scanf("${1:format_string}", ${2:args});',
        
        documentation: "Read formatted input",

      },
      {
        label: "if",
        insertText: [
          "if (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "If statement",

      },
      {
        label: "else",
        insertText: [
          "else {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Else statement",

      },
      {
        label: "while",
        insertText: [
          "while (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "While loop",

      },
      {
        label: "for",
        insertText: [
          "for (${1:init}; ${2:condition}; ${3:increment}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "For loop",

      },
      {
        label: "switch",
        insertText: [
          "switch (${1:expression}) {",
          "\tcase ${2:value}:",
          "\t\t$0",
          "\t\tbreak;",
          "\tdefault:",
          "}",
        ].join("\n"),
        
        documentation: "Switch statement",

      },
      {
        label: "case",
        insertText: "case ${1:value}: $0 break;",
        
        documentation: "Case in a switch statement",

      },
      {
        label: "default",
        insertText: "default: $0",
        
        documentation: "Default case in a switch statement",

      },
      {
        label: "break",
        insertText: "break;",
        
        documentation: "Break statement",

      },
      {
        label: "continue",
        insertText: "continue;",
        
        documentation: "Continue statement",

      },
      {
        label: "return",
        insertText: "return ${1:value};",
        
        documentation: "Return statement",

      },
      {
        label: "int",
        insertText: "int ${1:variable_name} = ${2:value};",
        
        documentation: "Declare an integer variable",

      },
      {
        label: "float",
        insertText: "float ${1:variable_name} = ${2:value};",
        
        documentation: "Declare a floating-point variable",

      },
      {
        label: "char",
        insertText: "char ${1:variable_name} = '${2:char}';",
        
        documentation: "Declare a character variable",

      },
      {
        label: "void",
        insertText: "void ${1:function_name}(${2:parameters}) { $0 }",
        
        documentation: "Declare a void function",

      },
      {
        label: "const",
        insertText: "const ${1:type} ${2:variable_name} = ${3:value};",
        
        documentation: "Declare a constant",

      },
      {
        label: "sizeof",
        insertText: "sizeof(${1:type});",
        
        documentation: "Get the size of a type",

      },
      {
        label: "malloc",
        insertText: "malloc(${1:size});",
        
        documentation: "Allocate memory dynamically",

      },
      {
        label: "free",
        insertText: "free(${1:pointer});",
        
        documentation: "Free dynamically allocated memory",

      },
    ]
    },
    {name:'C++ (GCC 9.2.0)',value: 'cpp',suggestion:  [
      {
        label: "cout",
        insertText: "std::cout << ${1:value} << std::endl;",
        
        documentation: "Print to standard output",

      },
      {
        label: "cin",
        insertText: "std::cin >> ${1:variable};",
        
        documentation: "Read from standard input",

      },
      {
        label: "if",
        insertText: [
          "if (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "If statement",

      },
      {
        label: "else",
        insertText: [
          "else {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Else statement",

      },
      {
        label: "while",
        insertText: [
          "while (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "While loop",

      },
      {
        label: "for",
        insertText: [
          "for (${1:init}; ${2:condition}; ${3:increment}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "For loop",

      },
      {
        label: "switch",
        insertText: [
          "switch (${1:expression}) {",
          "\tcase ${2:value}:",
          "\t\t$0",
          "\t\tbreak;",
          "\tdefault:",
          "}",
        ].join("\n"),
        
        documentation: "Switch statement",

      },
      {
        label: "case",
        insertText: "case ${1:value}: $0 break;",
        
        documentation: "Case in a switch statement",

      },
      {
        label: "default",
        insertText: "default: $0",
        
        documentation: "Default case in a switch statement",

      },
      {
        label: "break",
        insertText: "break;",
        
        documentation: "Break statement",

      },
      {
        label: "continue",
        insertText: "continue;",
        
        documentation: "Continue statement",

      },
      {
        label: "return",
        insertText: "return ${1:value};",
        
        documentation: "Return statement",

      },
      {
        label: "int",
        insertText: "int ${1:variable_name} = ${2:value};",
        
        documentation: "Declare an integer variable",

      },
      {
        label: "float",
        insertText: "float ${1:variable_name} = ${2:value};",
        
        documentation: "Declare a floating-point variable",

      },
      {
        label: "double",
        insertText: "double ${1:variable_name} = ${2:value};",
        
        documentation: "Declare a double variable",

      },
      {
        label: "char",
        insertText: "char ${1:variable_name} = '${2:char}';",
        
        documentation: "Declare a character variable",

      },
      {
        label: "string",
        insertText: "std::string ${1:variable_name} = \"${2:value}\";",
        
        documentation: "Declare a string variable",

      },
      {
        label: "bool",
        insertText: "bool ${1:variable_name} = ${2:true};",
        
        documentation: "Declare a boolean variable",

      },
      {
        label: "void",
        insertText: "void ${1:function_name}(${2:parameters}) { $0 }",
        
        documentation: "Declare a void function",

      },
      {
        label: "const",
        insertText: "const ${1:type} ${2:variable_name} = ${3:value};",
        
        documentation: "Declare a constant",

      },
      {
        label: "class",
        insertText: [
          "class ${1:class_name} {",
          "public:",
          "\t${1}(${2:parameters}) { $0 }",
          "};",
        ].join("\n"),
        
        documentation: "Declare a class",

      },
      {
        label: "struct",
        insertText: [
          "struct ${1:struct_name} {",
          "\t${1}(${2:parameters}) { $0 }",
          "};",
        ].join("\n"),
        
        documentation: "Declare a struct",

      },
    ]
    },
    {name:'Clojure (1.10.1)',value: 'clojure',suggestion:[
      {
        label: "(defn",
        insertText: [
          "(defn ${1:function_name} [${2:parameters}]",
          "\t$0",
          ")",
        ].join("\n"),
        
        documentation: "Define a function",

      },
      {
        label: "(let",
        insertText: [
          "(let [${1:binding} ${2:value}]",
          "\t$0",
          ")",
        ].join("\n"),
        
        documentation: "Create a local binding",

      },
      {
        label: "(if",
        insertText: [
          "(if ${1:condition}",
          "\t${2:then-expression}",
          "\t${3:else-expression}",
          ")",
        ].join("\n"),
        
        documentation: "Conditional branching",

      },
      {
        label: "(do",
        insertText: [
          "(do",
          "\t${1:expression-1}",
          "\t${2:expression-2}",
          "\t$0",
          ")",
        ].join("\n"),
        
        documentation: "Evaluate multiple expressions",

      },
      {
        label: "(def",
        insertText: [
          "(def ${1:symbol} ${2:value})",
        ].join("\n"),
        
        documentation: "Define a global variable",

      },
      {
        label: "(fn",
        insertText: [
          "(fn [${1:parameters}]",
          "\t$0",
          ")",
        ].join("\n"),
        
        documentation: "Anonymous function",

      },
      {
        label: "(map",
        insertText: [
          "(map ${1:function} ${2:collection})",
        ].join("\n"),
        
        documentation: "Apply function to each item in a collection",

      },
      {
        label: "(filter",
        insertText: [
          "(filter ${1:predicate} ${2:collection})",
        ].join("\n"),
        
        documentation: "Filter items from a collection",

      },
      {
        label: "(reduce",
        insertText: [
          "(reduce ${1:function} ${2:initial-value} ${3:collection})",
        ].join("\n"),
        
        documentation: "Apply function to reduce a collection to a single value",

      },
      {
        label: "(letfn",
        insertText: [
          "(letfn [(${1:function-name} [${2:parameters}]",
          "\t$0",
          ")]",
        ].join("\n"),
        
        documentation: "Define multiple named functions",

      },
      {
        label: "(defmacro",
        insertText: [
          "(defmacro ${1:macro-name} [${2:parameters}]",
          "\t$0",
          ")",
        ].join("\n"),
        
        documentation: "Define a macro",

      },
      {
        label: "(quote",
        insertText: "(quote ${1:expression})",
        
        documentation: "Quote an expression to prevent evaluation",

      },
      {
        label: "(doall",
        insertText: [
          "(doall ${1:expression-seq})",
        ].join("\n"),
        
        documentation: "Evaluate all items in a sequence",

      },
      {
        label: "(defrecord",
        insertText: [
          "(defrecord ${1:RecordName} [${2:fields}])",
        ].join("\n"),
        
        documentation: "Define a record type",

      },
      {
        label: "(defprotocol",
        insertText: [
          "(defprotocol ${1:ProtocolName}",
          "\t(${2:function-name} [${3:parameters}])",
          "\t${4:...}",
          ")",
        ].join("\n"),
        
        documentation: "Define a protocol",

      },
      {
        label: "(extend-protocol",
        insertText: [
          "(extend-protocol ${1:ProtocolName}",
          "\t${2:ProtocolInterface}",
          "\t(${3:function-name} [${4:parameters}]",
          "\t\t$0",
          "\t\t)",
          "\t${5:...}",
          ")",
        ].join("\n"),
        
        documentation: "Extend a protocol with implementation",

      },
      {
        label: "(defn-",
        insertText: [
          "(defn- ${1:function_name} [${2:parameters}]",
          "\t$0",
          ")",
        ].join("\n"),
        
        documentation: "Define a private function",

      },
      {
        label: "(defmethod",
        insertText: [
          "(defmethod ${1:protocol-name} ${2:function-name}",
          "\t[${3:parameters}]",
          "\t$0",
          ")",
        ].join("\n"),
        
        documentation: "Define a method for a protocol",

      },
      {
        label: "(defmulti",
        insertText: [
          "(defmulti ${1:dispatch-fn} ${2:default-value})",
        ].join("\n"),
        
        documentation: "Define a multimethod",

      },
      {
        label: "(defmethod",
        insertText: [
          "(defmethod ${1:multi-fn} ${2:dispatch-value}",
          "\t[${3:parameters}]",
          "\t$0",
          ")",
        ].join("\n"),
        
        documentation: "Define a method for a multimethod",

      },
      {
        label: "(loop",
        insertText: [
          "(loop [${1:bindings}]",
          "\t(if ${2:condition}",
          "\t\t(recur ${3:updated-bindings})",
          "\t\t$0",
          "\t\t))",
        ].join("\n"),
        
        documentation: "Create a loop with optional recursion",

      },
      {
        label: "(recur",
        insertText: "recur ${1:bindings}",
        
        documentation: "Recursively call a loop",

      },
      {
        label: "(when",
        insertText: [
          "(when ${1:condition}",
          "\t$0",
          ")",
        ].join("\n"),
        
        documentation: "Conditional execution",

      },
      {
        label: "(comment",
        insertText: [
          "(comment",
          "\t$0",
          ")",
        ].join("\n"),
        
        documentation: "Add a comment (ignored by the compiler)",

      },
    ]
    },
    {name:'C# (Mono 6.6.0.161)',value: 'csharp',suggestion: [
      {
        label: "namespace",
        insertText: "namespace ${1:NamespaceName} {\n\t$0\n}",
        
        documentation: "Declare a namespace",

      },
      {
        label: "class",
        insertText: [
          "class ${1:ClassName}",
          "{",
          "\tpublic ${1:ClassName}()",
          "\t{",
          "\t\t$0",
          "\t}",
          "}",
        ].join("\n"),
        
        documentation: "Declare a class",

      },
      {
        label: "public",
        insertText: "public ${1:type} ${2:Name} { get; set; }",
        
        documentation: "Declare a public property",

      },
      {
        label: "private",
        insertText: "private ${1:type} ${2:Name};",
        
        documentation: "Declare a private field",

      },
      {
        label: "protected",
        insertText: "protected ${1:type} ${2:Name};",
        
        documentation: "Declare a protected field",

      },
      {
        label: "internal",
        insertText: "internal ${1:type} ${2:Name};",
        
        documentation: "Declare an internal field",

      },
      {
        label: "abstract",
        insertText: "abstract ${1:class} ${2:ClassName} { $0 }",
        
        documentation: "Declare an abstract class",

      },
      {
        label: "interface",
        insertText: "interface ${1:InterfaceName} { $0 }",
        
        documentation: "Declare an interface",

      },
      {
        label: "enum",
        insertText: [
          "enum ${1:EnumName}",
          "{",
          "\t${2:Value1},",
          "\t${3:Value2},",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Declare an enumeration",

      },
      {
        label: "struct",
        insertText: [
          "struct ${1:StructName}",
          "{",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Declare a struct",

      },
      {
        label: "using",
        insertText: "using ${1:Namespace};",
        
        documentation: "Import a namespace",

      },
      {
        label: "public",
        insertText: "public ${1:type} ${2:MethodName}(${3:parameters})\n{\n\t$0\n}",
        
        documentation: "Declare a public method",

      },
      {
        label: "private",
        insertText: "private ${1:type} ${2:MethodName}(${3:parameters})\n{\n\t$0\n}",
        
        documentation: "Declare a private method",

      },
      {
        label: "protected",
        insertText: "protected ${1:type} ${2:MethodName}(${3:parameters})\n{\n\t$0\n}",
        
        documentation: "Declare a protected method",

      },
      {
        label: "internal",
        insertText: "internal ${1:type} ${2:MethodName}(${3:parameters})\n{\n\t$0\n}",
        
        documentation: "Declare an internal method",

      },
      {
        label: "override",
        insertText: "override ${1:type} ${2:MethodName}(${3:parameters})\n{\n\t$0\n}",
        
        documentation: "Override a base class method",

      },
      {
        label: "virtual",
        insertText: "virtual ${1:type} ${2:MethodName}(${3:parameters})\n{\n\t$0\n}",
        
        documentation: "Declare a virtual method",

      },
      {
        label: "new",
        insertText: "new ${1:type} ${2:MethodName}(${3:parameters})\n{\n\t$0\n}",
        
        documentation: "Declare a new method",

      },
      {
        label: "get",
        insertText: "get { $0 }",
        
        documentation: "Define the getter of a property",

      },
      {
        label: "set",
        insertText: "set { $0 }",
        
        documentation: "Define the setter of a property",

      },
      {
        label: "namespace",
        insertText: "namespace ${1:NamespaceName} { $0 }",
        
        documentation: "Declare a namespace",

      },
      {
        label: "using",
        insertText: "using ${1:Namespace};",
        
        documentation: "Import a namespace",

      },
      {
        label: "public",
        insertText: "public ${1:type} ${2:PropertyName} { get; set; }",
        
        documentation: "Declare a public property",

      },
      {
        label: "private",
        insertText: "private ${1:type} ${2:fieldName};",
        
        documentation: "Declare a private field",

      },
      {
        label: "protected",
        insertText: "protected ${1:type} ${2:fieldName};",
        
        documentation: "Declare a protected field",

      },
      {
        label: "internal",
        insertText: "internal ${1:type} ${2:fieldName};",
        
        documentation: "Declare an internal field",

      },
      {
        label: "static",
        insertText: "static ${1:type} ${2:MethodName}(${3:parameters})\n{\n\t$0\n}",
        
        documentation: "Declare a static method",

      },
    ]
    },
    {name:'COBOL (GnuCOBOL 2.2)',value: 'cobol',suggestion: [
      {
        label: "IDENTIFICATION DIVISION.",
        insertText: [
          "IDENTIFICATION DIVISION.",
          "\tPROGRAM-ID. ${1:ProgramName}.",
          "\tAUTHOR. ${2:AuthorName}.",
          "\t$0",
          "\t...",
        ].join("\n"),
        
        documentation: "Declare program information",

      },
      {
        label: "DATA DIVISION.",
        insertText: [
          "DATA DIVISION.",
          "\tWORKING-STORAGE SECTION.",
          "\t\t01 ${1:VariableName} PIC ${2:9(10)} VALUE ${3:InitialValue}.",
          "\t...",
        ].join("\n"),
        
        documentation: "Declare working-storage section",

      },
      {
        label: "PROCEDURE DIVISION.",
        insertText: [
          "PROCEDURE DIVISION.",
          "\t${1:ParagraphName}.",
          "\t\tDISPLAY 'Hello, World!'.",
          "\t$0",
        ].join("\n"),
        
        documentation: "Declare procedure division",

      },
      {
        label: "DISPLAY",
        insertText: "DISPLAY '${1:Text}'${0}",
        
        documentation: "Display text on the screen",

      },
      {
        label: "ACCEPT",
        insertText: "ACCEPT ${1:VariableName}${0}",
        
        documentation: "Accept input from user",

      },
      {
        label: "MOVE",
        insertText: "MOVE ${1:Value} TO ${2:Variable}${0}",
        
        documentation: "Move a value to a variable",

      },
      {
        label: "ADD",
        insertText: "ADD ${1:Value1} TO ${2:Value2} GIVING ${3:Result}${0}",
        
        documentation: "Add values and store the result",

      },
      {
        label: "SUBTRACT",
        insertText: "SUBTRACT ${1:Value1} FROM ${2:Value2} GIVING ${3:Result}${0}",
        
        documentation: "Subtract values and store the result",

      },
      {
        label: "MULTIPLY",
        insertText: "MULTIPLY ${1:Value1} BY ${2:Value2} GIVING ${3:Result}${0}",
        
        documentation: "Multiply values and store the result",

      },
      {
        label: "DIVIDE",
        insertText: "DIVIDE ${1:Value1} BY ${2:Value2} GIVING ${3:Result}${0}",
        
        documentation: "Divide values and store the result",

      },
      {
        label: "PERFORM",
        insertText: "PERFORM ${1:ParagraphName} ${2:UNTIL ${3:Condition}}${0}",
        
        documentation: "Perform a paragraph until a condition is met",

      },
      {
        label: "IF",
        insertText: [
          "IF ${1:Condition}",
          "\t${2:PerformStatement}",
          "ELSE",
          "\t${3:ElseStatement}",
          "END-IF${0}",
        ].join("\n"),
        
        documentation: "Conditional statement",

      },
      {
        label: "EVALUATE",
        insertText: [
          "EVALUATE ${1:VariableName}",
          "\tWHEN ${2:Value1}",
          "\t\t${3:Statement1}",
          "\tWHEN ${4:Value2}",
          "\t\t${5:Statement2}",
          "\t...",
          "\t${6:OTHER}",
          "\t\t${7:DefaultStatement}",
          "END-EVALUATE${0}",
        ].join("\n"),
        
        documentation: "Evaluate multiple conditions",

      },
      {
        label: "PERFORM VARYING",
        insertText: [
          "PERFORM ${1:ParagraphName}",
          "\tVARYING ${2:CounterName} FROM ${3:InitialValue} BY ${4:Increment}",
          "\tUNTIL ${5:Condition}${0}",
        ].join("\n"),
        
        documentation: "Perform a paragraph with a varying loop",

      },
      {
        label: "MOVE CORRESPONDING",
        insertText: "MOVE CORRESPONDING ${1:DataGroup1} TO ${2:DataGroup2}${0}",
        
        documentation: "Move corresponding fields between data groups",

      },
      {
        label: "GOBACK",
        insertText: "GOBACK${0}",
        
        documentation: "Terminate program execution",

      },
      {
        label: "EXIT PROGRAM",
        insertText: "EXIT PROGRAM${0}",
        
        documentation: "Exit program execution",

      },
      {
        label: "STOP RUN",
        insertText: "STOP RUN${0}",
        
        documentation: "Stop program execution",

      },
    ]
    },
    {name:'Common Lisp (SBCL 2.0.0)',value: 'lisp',suggestion:[
      {
        "label": "print",
        "insertText": "print(${1:message});",
        "documentation": "Print a message to the console"
      },
      {
        "label": "if",
        "insertText": "if (${1:condition}) {\n  ${2:// code to execute}\n}",
        "documentation": "Conditional statement"
      },
      {
        "label": "else",
        "insertText": "else {\n  ${1:// code to execute}\n}",
        "documentation": "Else statement for conditional blocks"
      },
      {
        "label": "for",
        "insertText": "for (${1:var i = 0; i < ${2:length}; i++}) {\n  ${3:// code to repeat}\n}",
        "documentation": "For loop"
      },
      {
        "label": "while",
        "insertText": "while (${1:condition}) {\n  ${2:// code to execute}\n}",
        "documentation": "While loop"
      },
      {
        "label": "function",
        "insertText": "void ${1:functionName}(${2:parameters}) {\n  ${3:// function body}\n}",
        "documentation": "Declare a function"
      },
      {
        "label": "class",
        "insertText": "class ${1:ClassName} {\n  ${2:// class members}\n}",
        "documentation": "Declare a class"
      },
      {
        "label": "import",
        "insertText": "import '${1:library.dart}';",
        "documentation": "Import a Dart library"
      },
      {
        "label": "try",
        "insertText": "try {\n  ${1:// code that might throw an exception}\n} catch (${2:exception}) {\n  ${3:// code to handle the exception}\n}",
        "documentation": "Try-catch block for exception handling"
      },
      {
        "label": "throw",
        "insertText": "throw ${1:Exception('Message')};",
        "documentation": "Throw an exception"
      },
      {
        "label": "final",
        "insertText": "final ${1:variableName} = ${2:value};",
        "documentation": "Declare a final variable"
      },
      {
        "label": "var",
        "insertText": "var ${1:variableName} = ${2:value};",
        "documentation": "Declare a variable with type inference"
      },
      {
        "label": "List",
        "insertText": "List<${1:Type}> ${2:listName} = [${3:initialValues}];",
        "documentation": "Declare a List"
      },
      {
        "label": "Map",
        "insertText": "Map<${1:KeyType}, ${2:ValueType}> ${3:mapName} = {${4:key1}: ${5:value1}, ${6:key2}: ${7:value2}};",
        "documentation": "Declare a Map"
      },
      {
        "label": "await",
        "insertText": "await ${1:asyncFunctionCall};",
        "documentation": "Await an asynchronous operation"
      },
      {
        "label": "async",
        "insertText": "Future<${1:ReturnType}> ${2:asyncFunction}(${3:parameters}) async {\n  ${4:// asynchronous code}\n}",
        "documentation": "Declare an asynchronous function"
      },
      {
        "label": "return",
        "insertText": "return ${1:expression};",
        "documentation": "Return a value from a function"
      },
      {
        "label": "extends",
        "insertText": "extends ${1:ParentClass} {",
        "documentation": "Extend a class"
      },
      {
        "label": "implements",
        "insertText": "implements ${1:Interface} {",
        "documentation": "Implement an interface"
      }
    ]
    },
    {name:'Dart (2.19.2)',value: 'dart', suggestion:[
      {
        label: "print",
        insertText: "print(${1:message});",
        documentation: "Print a message to the console"
      },
      {
        label: "if",
        insertText: "if (${1:condition}) {\n  ${2:// code to execute}\n}",
        documentation: "Conditional statement"
      },
      {
        label: "else",
        insertText: "else {\n  ${1:// code to execute}\n}",
        documentation: "Else statement for conditional blocks"
      },
      {
        label: "for",
        insertText: "for (${1:var i = 0; i < ${2:length}; i++}) {\n  ${3:// code to repeat}\n}",
        documentation: "For loop"
      },
      {
        label: "while",
        insertText: "while (${1:condition}) {\n  ${2:// code to execute}\n}",
        documentation: "While loop"
      },
      {
        label: "function",
        insertText: "void ${1:functionName}(${2:parameters}) {\n  ${3:// function body}\n}",
        documentation: "Declare a function"
      },
      {
        label: "class",
        insertText: "class ${1:ClassName} {\n  ${2:// class members}\n}",
        documentation: "Declare a class"
      },
      {
        label: "import",
        insertText: "import '${1:library.dart}';",
        documentation: "Import a Dart library"
      },
      {
        label: "try",
        insertText: "try {\n  ${1:// code that might throw an exception}\n} catch (${2:exception}) {\n  ${3:// code to handle the exception}\n}",
        documentation: "Try-catch block for exception handling"
      },
      {
        label: "throw",
        insertText: "throw ${1:Exception('Message')};",
        documentation: "Throw an exception"
      },
      {
        label: "final",
        insertText: "final ${1:variableName} = ${2:value};",
        documentation: "Declare a final variable"
      },
      {
        label: "var",
        insertText: "var ${1:variableName} = ${2:value};",
        documentation: "Declare a variable with type inference"
      },
      {
        label: "List",
        insertText: "List<${1:Type}> ${2:listName} = [${3:initialValues}];",
        documentation: "Declare a List"
      },
      {
        label: "Map",
        insertText: "Map<${1:KeyType}, ${2:ValueType}> ${3:mapName} = {${4:key1}: ${5:value1}, ${6:key2}: ${7:value2}};",
        documentation: "Declare a Map"
      },
      {
        label: "await",
        insertText: "await ${1:asyncFunctionCall};",
        documentation: "Await an asynchronous operation"
      },
      {
        label: "async",
        insertText: "Future<${1:ReturnType}> ${2:asyncFunction}(${3:parameters}) async {\n  ${4:// asynchronous code}\n}",
        documentation: "Declare an asynchronous function"
      },
      {
        label: "return",
        insertText: "return ${1:expression};",
        documentation: "Return a value from a function"
      },
      {
        label: "extends",
        insertText: "extends ${1:ParentClass} {",
        documentation: "Extend a class"
      },
      {
        label: "implements",
        insertText: "implements ${1:Interface} {",
        documentation: "Implement an interface"
      }
    ]},
    {name:'D (DMD 2.089.1)',value: 'd',suggestion:[
      {
        "label": "void",
        "insertText": "void",
        "documentation": "Declares a void return type"
      },
      {
        "label": "int",
        "insertText": "int",
        "documentation": "Declares an integer type"
      },
      {
        "label": "string",
        "insertText": "string",
        "documentation": "Declares a string type"
      },
      {
        "label": "float",
        "insertText": "float",
        "documentation": "Declares a floating-point type"
      },
      {
        "label": "double",
        "insertText": "double",
        "documentation": "Declares a double-precision floating-point type"
      },
      {
        "label": "char",
        "insertText": "char",
        "documentation": "Declares a character type"
      },
      {
        "label": "bool",
        "insertText": "bool",
        "documentation": "Declares a boolean type"
      },
      {
        "label": "auto",
        "insertText": "auto",
        "documentation": "Infers the type from the assigned value"
      },
      {
        "label": "if",
        "insertText": "if (${1:condition}) {\n  ${2:// code to execute}\n}",
        "documentation": "Conditional statement"
      },
      {
        "label": "else",
        "insertText": "else {\n  ${1:// code to execute}\n}",
        "documentation": "Else statement for conditional blocks"
      },
      {
        "label": "while",
        "insertText": "while (${1:condition}) {\n  ${2:// code to execute}\n}",
        "documentation": "While loop"
      },
      {
        "label": "for",
        "insertText": "for (${1:int i = 0; i < ${2:length}; i++}) {\n  ${3:// code to repeat}\n}",
        "documentation": "For loop"
      },
      {
        "label": "foreach",
        "insertText": "foreach (${1:element}; ${2:array}) {\n  ${3:// code to execute}\n}",
        "documentation": "Foreach loop for iterating collections"
      },
      {
        "label": "switch",
        "insertText": "switch (${1:expression}) {\n  case ${2:constant}:\n    ${3:// code to execute}\n  default:\n    ${4:// default code}\n}",
        "documentation": "Switch statement"
      },
      {
        "label": "function",
        "insertText": "${1:returnType} ${2:functionName}(${3:parameters}) {\n  ${4:// function body}\n}",
        "documentation": "Declare a function"
      },
      {
        "label": "class",
        "insertText": "class ${1:ClassName} {\n  ${2:// class members}\n}",
        "documentation": "Declare a class"
      },
      {
        "label": "struct",
        "insertText": "struct ${1:StructName} {\n  ${2:// struct members}\n}",
        "documentation": "Declare a struct"
      },
      {
        "label": "import",
        "insertText": "import ${1:module};",
        "documentation": "Import a D module"
      },
      {
        "label": "alias",
        "insertText": "alias ${1:NewName} = ${2:OldName};",
        "documentation": "Create an alias for a type or symbol"
      },
      {
        "label": "return",
        "insertText": "return ${1:expression};",
        "documentation": "Return a value from a function"
      }
    ]
    },
    {name:'Elixir (1.9.4)',value: 'elixir',suggestion:[
      {
        "label": "defmodule",
        "insertText": "defmodule ${1:ModuleName} do\n  ${2:// module code}\nend",
        "documentation": "Define a new module"
      },
      {
        "label": "defp",
        "insertText": "defp ${1:function_name}(${2:params}) do\n  ${3:// function body}\nend",
        "documentation": "Define a private function"
      },
      {
        "label": "def",
        "insertText": "def ${1:function_name}(${2:params}) do\n  ${3:// function body}\nend",
        "documentation": "Define a public function"
      },
      {
        "label": "defmacro",
        "insertText": "defmacro ${1:macro_name}(${2:params}) do\n  ${3:// macro body}\nend",
        "documentation": "Define a macro"
      },
      {
        "label": "if",
        "insertText": "if ${1:condition} do\n  ${2:// code to execute}\nelse\n  ${3:// code to execute}\nend",
        "documentation": "Conditional statement"
      },
      {
        "label": "case",
        "insertText": "case ${1:expression} do\n  ${2:pattern} ->\n    ${3:// code to execute}\n  _ ->\n    ${4:// default code}\nend",
        "documentation": "Pattern matching case statement"
      },
      {
        "label": "receive",
        "insertText": "receive do\n  ${1:pattern} ->\n    ${2:// code to execute}\n  after ${3:timeout} ->\n    ${4:// code to execute}\nend",
        "documentation": "Process message handling"
      },
      {
        "label": "spawn",
        "insertText": "spawn(fn ->\n  ${1:// code to execute}\nend)",
        "documentation": "Spawn a new process"
      },
      {
        "label": "module",
        "insertText": "defmodule ${1:ModuleName} do\n  ${2:// module code}\nend",
        "documentation": "Define a new module (alternative)"
      },
      {
        "label": "use",
        "insertText": "use ${1:Module}",
        "documentation": "Apply a module as a macro"
      },
      {
        "label": "require",
        "insertText": "require ${1:Module}",
        "documentation": "Import a module for use"
      },
      {
        "label": "import",
        "insertText": "import ${1:Module}",
        "documentation": "Import a module"
      },
      {
        "label": "defstruct",
        "insertText": "defstruct ${1:StructName}, ${2:fields: [:${3:field1}, :${4:field2}]",
        "documentation": "Define a struct with optional fields"
      },
      {
        "label": "IO.puts",
        "insertText": "IO.puts(${1:message})",
        "documentation": "Print a message to the console"
      },
      {
        "label": "IO.inspect",
        "insertText": "IO.inspect(${1:expression})",
        "documentation": "Print and return a value for debugging"
      },
      {
        "label": "Enum.map",
        "insertText": "Enum.map(${1:collection}, fn ${2:element} ->\n  ${3:expression}\nend)",
        "documentation": "Map function over an enumerable"
      },
      {
        "label": "Enum.reduce",
        "insertText": "Enum.reduce(${1:collection}, ${2:accumulator}, fn ${3:element}, ${4:accumulator} ->\n  ${5:expression}\nend)",
        "documentation": "Reduce function over an enumerable"
      },
      {
        "label": "defprotocol",
        "insertText": "defprotocol ${1:Protocol} do\n  @spec ${2:function_name}(${3:params}) :: ${4:return_type}\nend",
        "documentation": "Define a protocol for behavior"
      },
      {
        "label": "defimpl",
        "insertText": "defimpl ${1:Protocol}, for: ${2:Type} do\n  @spec ${3:function_name}(${4:params}) :: ${5:return_type}\n  def ${3:function_name}(${4:params}) do\n    ${6:// implementation}\n  end\nend",
        "documentation": "Implement a protocol for a type"
      }
    ]
    },
    {name:'Erlang (OTP 22.2)',value: 'erlang',suggestion:[]},
    {name:'F# (.NET Core SDK 3.1.202)',value: 'fsharp',suggestion:[
      {
        "label": "let",
        "insertText": "let ${1:variable} = ${2:value}",
        "documentation": "Declare a new variable"
      },
      {
        "label": "let mutable",
        "insertText": "let mutable ${1:variable} = ${2:value}",
        "documentation": "Declare a mutable variable"
      },
      {
        "label": "fun",
        "insertText": "let ${1:functionName} ${2:params} =\n  ${3:body}",
        "documentation": "Define a function"
      },
      {
        "label": "if",
        "insertText": "if ${1:condition} then\n  ${2:expression}\nelse\n  ${3:expression}",
        "documentation": "Conditional expression"
      },
      {
        "label": "match",
        "insertText": "match ${1:expression} with\n  | ${2:pattern} -> ${3:expression}\n  | ${4:pattern} -> ${5:expression}",
        "documentation": "Pattern matching expression"
      },
      {
        "label": "for",
        "insertText": "for ${1:element} in ${2:sequence} do\n  ${3:body}",
        "documentation": "For loop"
      },
      {
        "label": "while",
        "insertText": "while ${1:condition} do\n  ${2:body}",
        "documentation": "While loop"
      },
      {
        "label": "try",
        "insertText": "try\n  ${1:expression}\nwith\n  | ${2:exception} -> ${3:handler}",
        "documentation": "Exception handling"
      },
      {
        "label": "type",
        "insertText": "type ${1:TypeName} = ${2:Type}",
        "documentation": "Define a new type"
      },
      {
        "label": "module",
        "insertText": "module ${1:ModuleName} =\n  ${2:moduleBody}",
        "documentation": "Define a module"
      },
      {
        "label": "namespace",
        "insertText": "namespace ${1:NamespaceName}\nopen System",
        "documentation": "Define a namespace and open a module"
      },
      {
        "label": "interface",
        "insertText": "type ${1:InterfaceName} =\n  ${2:interfaceMembers}",
        "documentation": "Define an interface"
      },
      {
        "label": "class",
        "insertText": "type ${1:ClassName} =\n  ${2:classMembers}",
        "documentation": "Define a class"
      },
      {
        "label": "inherit",
        "insertText": "inherit ${1:BaseType}",
        "documentation": "Inherit from a base class or interface"
      },
      {
        "label": "do!",
        "insertText": "do!\n  ${1:expression}",
        "documentation": "Execute an expression and return a result type"
      },
      {
        "label": "yield",
        "insertText": "yield ${1:value}",
        "documentation": "Yield a value in a sequence or computation expression"
      },
      {
        "label": "printfn",
        "insertText": "printfn \"${1:format}\" ${2:args}",
        "documentation": "Print formatted text to the console"
      },
      {
        "label": "List.map",
        "insertText": "List.map (fun ${1:arg} -> ${2:mapFunction}) ${3:sourceList}",
        "documentation": "Map function over a list"
      },
      {
        "label": "Seq.filter",
        "insertText": "Seq.filter (fun ${1:arg} -> ${2:filterFunction}) ${3:sourceSeq}",
        "documentation": "Filter elements in a sequence"
      }
    ]
    },
    {name:'Fortran (GFortran 9.2.0)',value: 'fortran',suggestion:[
      {
        "label": "program",
        "insertText": "program ${1:ProgramName}\n  ${2:! Your program code here}\nend program ${1:ProgramName}",
        "documentation": "Define a new Fortran program"
      },
      {
        "label": "subroutine",
        "insertText": "subroutine ${1:SubroutineName}(${2:arguments})\n  ${3:! Your subroutine code here}\nend subroutine ${1:SubroutineName}",
        "documentation": "Define a new subroutine"
      },
      {
        "label": "function",
        "insertText": "function ${1:FunctionName}(${2:arguments}) result(${3:ResultType})\n  ${4:! Your function code here}\nend function ${1:FunctionName}",
        "documentation": "Define a new function"
      },
      {
        "label": "module",
        "insertText": "module ${1:ModuleName}\n  ${2:! Your module code here}\nend module ${1:ModuleName}",
        "documentation": "Define a new module"
      },
      {
        "label": "use",
        "insertText": "use ${1:ModuleName}",
        "documentation": "Include a module in your program"
      },
      {
        "label": "implicit none",
        "insertText": "implicit none",
        "documentation": "Disable implicit typing"
      },
      {
        "label": "integer",
        "insertText": "integer :: ${1:VariableName}",
        "documentation": "Declare an integer variable"
      },
      {
        "label": "real",
        "insertText": "real :: ${1:VariableName}",
        "documentation": "Declare a real (floating-point) variable"
      },
      {
        "label": "double precision",
        "insertText": "double precision :: ${1:VariableName}",
        "documentation": "Declare a double precision variable"
      },
      {
        "label": "character",
        "insertText": "character(len=${1:StringLength}) :: ${2:VariableName}",
        "documentation": "Declare a character variable with a specified length"
      },
      {
        "label": "logical",
        "insertText": "logical :: ${1:VariableName}",
        "documentation": "Declare a logical variable"
      },
      {
        "label": "do",
        "insertText": "do ${1:LoopVariable} = ${2:Start}, ${3:End}, ${4:Step}\n  ${5:! Loop body}\nend do",
        "documentation": "Create a do loop"
      },
      {
        "label": "if",
        "insertText": "if (${1:Condition}) then\n  ${2:! Code to execute if true}\nelse\n  ${3:! Code to execute if false}\nend if",
        "documentation": "Conditional statement"
      },
      {
        "label": "call",
        "insertText": "call ${1:SubroutineName}(${2:arguments})",
        "documentation": "Call a subroutine"
      },
      {
        "label": "return",
        "insertText": "return",
        "documentation": "Return from a subroutine or function"
      },
      {
        "label": "write",
        "insertText": "write(${1:UnitNumber}, ${2:Format}) ${3:OutputItems}",
        "documentation": "Write formatted output to a unit"
      },
      {
        "label": "read",
        "insertText": "read(${1:UnitNumber}, ${2:Format}) ${3:InputItems}",
        "documentation": "Read formatted input from a unit"
      },
      {
        "label": "stop",
        "insertText": "stop",
        "documentation": "Stop program execution"
      },
      {
        "label": "open",
        "insertText": "open(unit=${1:UnitNumber}, file='${2:FileName}', status='${3:Status}')",
        "documentation": "Open a file"
      },
      {
        "label": "close",
        "insertText": "close(unit=${1:UnitNumber})",
        "documentation": "Close a file"
      }
    ]
    },
    {name:'Go (1.13.5)',value: 'go',suggestion:[
      {
        "label": "package",
        "insertText": "package ${1:main}",
        "documentation": "Declare the package name"
      },
      {
        "label": "import",
        "insertText": "import (\n  \"${1:package1}\"\n  \"${2:package2}\"\n)",
        "documentation": "Import packages"
      },
      {
        "label": "func",
        "insertText": "func ${1:functionName}(${2:params}) ${3:returnType} {\n  ${4:// function body}\n}",
        "documentation": "Define a new function"
      },
      {
        "label": "var",
        "insertText": "var ${1:variableName} ${2:type}",
        "documentation": "Declare a variable"
      },
      {
        "label": "const",
        "insertText": "const ${1:constantName} ${2:type} = ${3:value}",
        "documentation": "Declare a constant"
      },
      {
        "label": "type",
        "insertText": "type ${1:typeName} ${2:type}",
        "documentation": "Declare a custom type"
      },
      {
        "label": "if",
        "insertText": "if ${1:condition} {\n  ${2:// code to execute}\n}",
        "documentation": "Conditional statement"
      },
      {
        "label": "else",
        "insertText": "else {\n  ${1:// code to execute}\n}",
        "documentation": "Else statement for conditional blocks"
      },
      {
        "label": "switch",
        "insertText": "switch ${1:expression} {\n  case ${2:value}:\n    ${3:// code to execute}\n  default:\n    ${4:// default code}\n}",
        "documentation": "Switch statement"
      },
      {
        "label": "for",
        "insertText": "for ${1:i} := ${2:0}; ${1:i} < ${3:n}; ${1:i}++ {\n  ${4:// code to repeat}\n}",
        "documentation": "For loop"
      },
      {
        "label": "range",
        "insertText": "for ${1:index}, ${2:value} := range ${3:sliceOrMap} {\n  ${4:// code to execute}\n}",
        "documentation": "Iterate over slices, arrays, maps, or channels"
      },
      {
        "label": "func main",
        "insertText": "func main() {\n  ${1:// entry point of the program}\n}",
        "documentation": "Main function"
      },
      {
        "label": "defer",
        "insertText": "defer ${1:functionCall}",
        "documentation": "Schedule a function call to run when the surrounding function returns"
      },
      {
        "label": "make",
        "insertText": "make(${1:Type}, ${2:args})",
        "documentation": "Create slices, maps, or channels"
      },
      {
        "label": "new",
        "insertText": "new(${1:Type})",
        "documentation": "Allocate memory for a new value of the given type"
      },
      {
        "label": "return",
        "insertText": "return ${1:value}",
        "documentation": "Return a value from a function"
      },
      {
        "label": "panic",
        "insertText": "panic(${1:message})",
        "documentation": "Interrupt normal execution and trigger a panic"
      },
      {
        "label": "recover",
        "insertText": "recover()",
        "documentation": "Recover from a panic"
      },
      {
        "label": "go",
        "insertText": "go ${1:functionCall}",
        "documentation": "Start a new Goroutine"
      }
    ]
    },
    {name:'Go (1.18.5)',value: 'go',suggestion:[
      {
        "label": "package",
        "insertText": "package ${1:main}",
        "documentation": "Declare the package name"
      },
      {
        "label": "import",
        "insertText": "import (\n  \"${1:package1}\"\n  \"${2:package2}\"\n)",
        "documentation": "Import packages"
      },
      {
        "label": "func",
        "insertText": "func ${1:functionName}(${2:params}) ${3:returnType} {\n  ${4:// function body}\n}",
        "documentation": "Define a new function"
      },
      {
        "label": "var",
        "insertText": "var ${1:variableName} ${2:type}",
        "documentation": "Declare a variable"
      },
      {
        "label": "const",
        "insertText": "const ${1:constantName} ${2:type} = ${3:value}",
        "documentation": "Declare a constant"
      },
      {
        "label": "type",
        "insertText": "type ${1:typeName} ${2:type}",
        "documentation": "Declare a custom type"
      },
      {
        "label": "if",
        "insertText": "if ${1:condition} {\n  ${2:// code to execute}\n}",
        "documentation": "Conditional statement"
      },
      {
        "label": "else",
        "insertText": "else {\n  ${1:// code to execute}\n}",
        "documentation": "Else statement for conditional blocks"
      },
      {
        "label": "switch",
        "insertText": "switch ${1:expression} {\n  case ${2:value}:\n    ${3:// code to execute}\n  default:\n    ${4:// default code}\n}",
        "documentation": "Switch statement"
      },
      {
        "label": "for",
        "insertText": "for ${1:i} := ${2:0}; ${1:i} < ${3:n}; ${1:i}++ {\n  ${4:// code to repeat}\n}",
        "documentation": "For loop"
      },
      {
        "label": "range",
        "insertText": "for ${1:index}, ${2:value} := range ${3:sliceOrMap} {\n  ${4:// code to execute}\n}",
        "documentation": "Iterate over slices, arrays, maps, or channels"
      },
      {
        "label": "func main",
        "insertText": "func main() {\n  ${1:// entry point of the program}\n}",
        "documentation": "Main function"
      },
      {
        "label": "defer",
        "insertText": "defer ${1:functionCall}",
        "documentation": "Schedule a function call to run when the surrounding function returns"
      },
      {
        "label": "make",
        "insertText": "make(${1:Type}, ${2:args})",
        "documentation": "Create slices, maps, or channels"
      },
      {
        "label": "new",
        "insertText": "new(${1:Type})",
        "documentation": "Allocate memory for a new value of the given type"
      },
      {
        "label": "return",
        "insertText": "return ${1:value}",
        "documentation": "Return a value from a function"
      },
      {
        "label": "panic",
        "insertText": "panic(${1:message})",
        "documentation": "Interrupt normal execution and trigger a panic"
      },
      {
        "label": "recover",
        "insertText": "recover()",
        "documentation": "Recover from a panic"
      },
      {
        "label": "go",
        "insertText": "go ${1:functionCall}",
        "documentation": "Start a new Goroutine"
      }
    ]
    },
    {name:'Groovy (3.0.3)',value: 'groovy',suggestion:[
      {
        "label": "class",
        "insertText": "class ${1:ClassName} {\n  ${2:// class body}\n}",
        "documentation": "Define a new class"
      },
      {
        "label": "interface",
        "insertText": "interface ${1:InterfaceName} {\n  ${2:// interface body}\n}",
        "documentation": "Define a new interface"
      },
      {
        "label": "enum",
        "insertText": "enum ${1:EnumName} {\n  ${2:// enum values}\n}",
        "documentation": "Define a new enum"
      },
      {
        "label": "def",
        "insertText": "def ${1:variableName} = ${2:value}",
        "documentation": "Declare a dynamically typed variable"
      },
      {
        "label": "String",
        "insertText": "String ${1:variableName} = \"${2:value}\"",
        "documentation": "Declare a string variable"
      },
      {
        "label": "int",
        "insertText": "int ${1:variableName} = ${2:value}",
        "documentation": "Declare an integer variable"
      },
      {
        "label": "float",
        "insertText": "float ${1:variableName} = ${2:value}",
        "documentation": "Declare a floating-point variable"
      },
      {
        "label": "boolean",
        "insertText": "boolean ${1:variableName} = ${2:true}",
        "documentation": "Declare a boolean variable"
      },
      {
        "label": "if",
        "insertText": "if (${1:condition}) {\n  ${2:// code to execute}\n}",
        "documentation": "Conditional statement"
      },
      {
        "label": "else",
        "insertText": "else {\n  ${1:// code to execute}\n}",
        "documentation": "Else statement for conditional blocks"
      },
      {
        "label": "switch",
        "insertText": "switch (${1:expression}) {\n  case ${2:value}:\n    ${3:// code to execute}\n  default:\n    ${4:// default code}\n}",
        "documentation": "Switch statement"
      },
      {
        "label": "for",
        "insertText": "for (${1:item} in ${2:collection}) {\n  ${3:// code to repeat}\n}",
        "documentation": "For loop"
      },
      {
        "label": "while",
        "insertText": "while (${1:condition}) {\n  ${2:// code to execute}\n}",
        "documentation": "While loop"
      },
      {
        "label": "try",
        "insertText": "try {\n  ${1:// code to try}\n} catch (${2:ExceptionType} ${3:exception}) {\n  ${4:// code to handle exception}\n}",
        "documentation": "Exception handling with try-catch"
      },
      {
        "label": "throw",
        "insertText": "throw new ${1:ExceptionType}(\"${2:Message}\")",
        "documentation": "Throw an exception"
      },
      {
        "label": "return",
        "insertText": "return ${1:value}",
        "documentation": "Return a value from a function"
      },
      {
        "label": "void",
        "insertText": "void ${1:functionName}(${2:params}) {\n  ${3:// function body}\n}",
        "documentation": "Define a void function"
      },
      {
        "label": "List",
        "insertText": "List<${1:Type}> ${2:listName} = [${3:values}]",
        "documentation": "Declare a List"
      },
      {
        "label": "Map",
        "insertText": "Map<${1:KeyType}, ${2:ValueType}> ${3:mapName} = [:]",
        "documentation": "Declare a Map"
      }
    ]
    },
    {name:'Haskell (GHC 8.8.1)',value: 'haskell',suggestion:[
      {
        "label": "module",
        "insertText": "module ${1:ModuleName} where\n\n${2:-- module contents}",
        "documentation": "Define a new Haskell module"
      },
      {
        "label": "import",
        "insertText": "import ${1:ModuleName}",
        "documentation": "Import a module"
      },
      {
        "label": "data",
        "insertText": "data ${1:DataType} = ${2:Constructor1} ${3:Type1} ${4:Type2} | ${5:Constructor2} ${6:Type3}",
        "documentation": "Define a new algebraic data type"
      },
      {
        "label": "type",
        "insertText": "type ${1:TypeAlias} = ${2:Type}",
        "documentation": "Define a type alias"
      },
      {
        "label": "newtype",
        "insertText": "newtype ${1:NewType} = ${2:Constructor} ${3:Type}",
        "documentation": "Define a newtype"
      },
      {
        "label": "class",
        "insertText": "class ${1:TypeClass} ${2:where}\n  ${3:-- class methods and constraints}",
        "documentation": "Define a type class"
      },
      {
        "label": "instance",
        "insertText": "instance ${1:TypeClass} ${2:Type} where\n  ${3:-- instance methods and implementations}",
        "documentation": "Define an instance of a type class"
      },
      {
        "label": "module export",
        "insertText": "module ${1:ModuleName} (${2:exports}) where\n\n${3:-- module contents}",
        "documentation": "Define a module with specific exports"
      },
      {
        "label": "let",
        "insertText": "let\n  ${1:variableName} = ${2:value}\nin\n  ${3:expression}",
        "documentation": "Define a local variable with let expression"
      },
      {
        "label": "case",
        "insertText": "case ${1:expression} of\n  ${2:pattern1} -> ${3:result1}\n  ${4:pattern2} -> ${5:result2}",
        "documentation": "Pattern matching with case expression"
      },
      {
        "label": "if",
        "insertText": "if ${1:condition} then\n  ${2:trueBranch}\nelse\n  ${3:falseBranch}",
        "documentation": "Conditional expression with if-then-else"
      },
      {
        "label": "where",
        "insertText": "${1:functionName} ${2:params} = ${3:expression}\n  where\n    ${4:-- local definitions}",
        "documentation": "Local definitions with where clause"
      },
      {
        "label": "do",
        "insertText": "do\n  ${1:action1}\n  ${2:action2}\n  ${3:action3}",
        "documentation": "Do notation for monads"
      },
      {
        "label": "lambda",
        "insertText": "\\${1:params} -> ${2:expression}",
        "documentation": "Define a lambda function"
      },
      {
        "label": "let in",
        "insertText": "let ${1:variable} = ${2:value} in\n  ${3:expression}",
        "documentation": "Let-in expression for local variable binding"
      },
      {
        "label": "typeclass constraint",
        "insertText": "(${1:TypeClass} ${2:constraint})",
        "documentation": "Type class constraint in function signature"
      },
      {
        "label": "guard",
        "insertText": "guard ${1:condition} -> ${2:expression}",
        "documentation": "Guard clause in list comprehension"
      },
      {
        "label": "map",
        "insertText": "map ${1:function} ${2:list}",
        "documentation": "Map function over a list"
      },
      {
        "label": "filter",
        "insertText": "filter ${1:condition} ${2:list}",
        "documentation": "Filter elements in a list"
      },
      {
        "label": "foldl",
        "insertText": "foldl ${1:function} ${2:initial} ${3:list}",
        "documentation": "Left fold over a list"
      }
    ]
    },
    {name:'Java (JDK 17.0.6)',value: 'java',suggestion:[
      {
        label: "public class",
        insertText: [
          "public class ${1:ClassName} {",
          "\tpublic static void main(String[] args) {",
          "\t\t$0",
          "\t}",
          "}",
        ].join("\n"),
        
        documentation: "Declare a public class with a main method",

      },
      {
        label: "public static void main",
        insertText: [
          "public static void main(String[] args) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Declare the main method",

      },
      {
        label: "public",
        insertText: "public ${1:type} ${2:MethodName}(${3:parameters}) {\n\t$0\n}",
        
        documentation: "Declare a public method",

      },
      {
        label: "private",
        insertText: "private ${1:type} ${2:MethodName}(${3:parameters}) {\n\t$0\n}",
        
        documentation: "Declare a private method",

      },
      {
        label: "protected",
        insertText: "protected ${1:type} ${2:MethodName}(${3:parameters}) {\n\t$0\n}",
        
        documentation: "Declare a protected method",

      },
      {
        label: "static",
        insertText: "static ${1:type} ${2:MethodName}(${3:parameters}) {\n\t$0\n}",
        
        documentation: "Declare a static method",

      },
      {
        label: "void",
        insertText: "void ${1:MethodName}(${2:parameters}) {\n\t$0\n}",
        
        documentation: "Declare a void method",

      },
      {
        label: "return",
        insertText: "return ${1:expression};${0}",
        
        documentation: "Return a value from a method",

      },
      {
        label: "if",
        insertText: [
          "if (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "If statement",

      },
      {
        label: "else",
        insertText: [
          "else {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Else statement",

      },
      {
        label: "while",
        insertText: [
          "while (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "While loop",

      },
      {
        label: "for",
        insertText: [
          "for (${1:int i = 0; i < ${2:length}; i++}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "For loop",

      },
      {
        label: "foreach",
        insertText: [
          "for (${1:type} ${2:element} : ${3:collection}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "For-each loop",

      },
      {
        label: "switch",
        insertText: [
          "switch (${1:expression}) {",
          "\tcase ${2:value1}:",
          "\t\t$0",
          "\t\tbreak;",
          "\tcase ${3:value2}:",
          "\t\t$0",
          "\t\tbreak;",
          "\tdefault:",
          "\t\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Switch statement",

      },
      {
        label: "case",
        insertText: "case ${1:value}: ${2:break;}${0}",
        
        documentation: "Switch case",

      },
      {
        label: "default",
        insertText: "default: ${1:break;}${0}",
        
        documentation: "Default case",

      },
      {
        label: "try",
        insertText: [
          "try {",
          "\t$0",
          "} catch (${1:ExceptionType} ${2:e}) {",
          "\t$0",
          "} finally {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Try-catch-finally block",

      },
      {
        label: "catch",
        insertText: "catch (${1:ExceptionType} ${2:e}) { ${3:$0} }",
        
        documentation: "Catch block",

      },
      {
        label: "finally",
        insertText: "finally { ${1:$0} }",
        
        documentation: "Finally block",

      },
      {
        label: "throw",
        insertText: "throw new ${1:ExceptionType}(${2:\"An error occurred.\"});${0}",
        
        documentation: "Throw an exception",

      },
      {
        label: "import",
        insertText: "import ${1:PackageName}.${2:ClassName};${0}",
        
        documentation: "Import a class",

      },
      {
        label: "package",
        insertText: "package ${1:PackageName};${0}",
        
        documentation: "Declare a package",

      },
      {
        label: "extends",
        insertText: "extends ${1:SuperClassName}${0}",
        
        documentation: "Extend a class",

      },
      {
        label: "implements",
        insertText: "implements ${1:InterfaceName}${0}",
        
        documentation: "Implement an interface",

      },
    ]
    },
    {name:'Java (OpenJDK 13.0.1)',value: 'java',suggestion: [
      {
        label: "public class",
        insertText: [
          "public class ${1:ClassName} {",
          "\tpublic static void main(String[] args) {",
          "\t\t$0",
          "\t}",
          "}",
        ].join("\n"),
        
        documentation: "Declare a public class with a main method",

      },
      {
        label: "public static void main",
        insertText: [
          "public static void main(String[] args) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Declare the main method",

      },
      {
        label: "public",
        insertText: "public ${1:type} ${2:MethodName}(${3:parameters}) {\n\t$0\n}",
        
        documentation: "Declare a public method",

      },
      {
        label: "private",
        insertText: "private ${1:type} ${2:MethodName}(${3:parameters}) {\n\t$0\n}",
        
        documentation: "Declare a private method",

      },
      {
        label: "protected",
        insertText: "protected ${1:type} ${2:MethodName}(${3:parameters}) {\n\t$0\n}",
        
        documentation: "Declare a protected method",

      },
      {
        label: "static",
        insertText: "static ${1:type} ${2:MethodName}(${3:parameters}) {\n\t$0\n}",
        
        documentation: "Declare a static method",

      },
      {
        label: "void",
        insertText: "void ${1:MethodName}(${2:parameters}) {\n\t$0\n}",
        
        documentation: "Declare a void method",

      },
      {
        label: "return",
        insertText: "return ${1:expression};${0}",
        
        documentation: "Return a value from a method",

      },
      {
        label: "if",
        insertText: [
          "if (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "If statement",

      },
      {
        label: "else",
        insertText: [
          "else {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Else statement",

      },
      {
        label: "while",
        insertText: [
          "while (${1:condition}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "While loop",

      },
      {
        label: "for",
        insertText: [
          "for (${1:int i = 0; i < ${2:length}; i++}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "For loop",

      },
      {
        label: "foreach",
        insertText: [
          "for (${1:type} ${2:element} : ${3:collection}) {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "For-each loop",

      },
      {
        label: "switch",
        insertText: [
          "switch (${1:expression}) {",
          "\tcase ${2:value1}:",
          "\t\t$0",
          "\t\tbreak;",
          "\tcase ${3:value2}:",
          "\t\t$0",
          "\t\tbreak;",
          "\tdefault:",
          "\t\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Switch statement",

      },
      {
        label: "case",
        insertText: "case ${1:value}: ${2:break;}${0}",
        
        documentation: "Switch case",

      },
      {
        label: "default",
        insertText: "default: ${1:break;}${0}",
        
        documentation: "Default case",

      },
      {
        label: "try",
        insertText: [
          "try {",
          "\t$0",
          "} catch (${1:ExceptionType} ${2:e}) {",
          "\t$0",
          "} finally {",
          "\t$0",
          "}",
        ].join("\n"),
        
        documentation: "Try-catch-finally block",

      },
      {
        label: "catch",
        insertText: "catch (${1:ExceptionType} ${2:e}) { ${3:$0} }",
        
        documentation: "Catch block",

      },
      {
        label: "finally",
        insertText: "finally { ${1:$0} }",
        
        documentation: "Finally block",

      },
      {
        label: "throw",
        insertText: "throw new ${1:ExceptionType}(${2:\"An error occurred.\"});${0}",
        
        documentation: "Throw an exception",

      },
      {
        label: "import",
        insertText: "import ${1:PackageName}.${2:ClassName};${0}",
        
        documentation: "Import a class",

      },
      {
        label: "package",
        insertText: "package ${1:PackageName};${0}",
        
        documentation: "Declare a package",

      },
      {
        label: "extends",
        insertText: "extends ${1:SuperClassName}${0}",
        
        documentation: "Extend a class",

      },
      {
        label: "implements",
        insertText: "implements ${1:InterfaceName}${0}",
        
        documentation: "Implement an interface",

      },
    ]
    },
    {name:'JavaScript (Node.js 12.14.0)',value: 'javascript',suggestion:[]},
    {name:'JavaScript (Node.js 18.15.0)',value: 'javascript',suggestion:[]},
    {name:'Kotlin (1.3.70)',value: 'kotlin',suggestion:[
      {
        "label": "fun",
        "insertText": "fun ${1:functionName}(${2:params}): ${3:returnType} {\n  ${4:// function body}\n}",
        "documentation": "Define a new function"
      },
      {
        "label": "val",
        "insertText": "val ${1:variableName}: ${2:Type} = ${3:value}",
        "documentation": "Declare an immutable (read-only) property"
      },
      {
        "label": "var",
        "insertText": "var ${1:variableName}: ${2:Type} = ${3:value}",
        "documentation": "Declare a mutable property"
      },
      {
        "label": "class",
        "insertText": "class ${1:ClassName} {\n  ${2:// class body}\n}",
        "documentation": "Define a new class"
      },
      {
        "label": "interface",
        "insertText": "interface ${1:InterfaceName} {\n  ${2:// interface body}\n}",
        "documentation": "Define a new interface"
      },
      {
        "label": "object",
        "insertText": "object ${1:ObjectName} {\n  ${2:// object body}\n}",
        "documentation": "Define a new singleton object"
      },
      {
        "label": "if",
        "insertText": "if (${1:condition}) {\n  ${2:// code to execute}\n}",
        "documentation": "Conditional expression"
      },
      {
        "label": "else",
        "insertText": "else {\n  ${1:// code to execute}\n}",
        "documentation": "Else statement for conditional blocks"
      },
      {
        "label": "when",
        "insertText": "when (${1:value}) {\n  ${2:case1} -> ${3:result1}\n  ${4:case2} -> ${5:result2}\n  else -> ${6:defaultResult}\n}",
        "documentation": "When expression (pattern matching)"
      },
      {
        "label": "for",
        "insertText": "for (${1:item} in ${2:collection}) {\n  ${3:// code to repeat}\n}",
        "documentation": "For loop"
      },
      {
        "label": "while",
        "insertText": "while (${1:condition}) {\n  ${2:// code to execute}\n}",
        "documentation": "While loop"
      },
      {
        "label": "try",
        "insertText": "try {\n  ${1:// code to try}\n} catch (${2:ExceptionType} ${3:exception}) {\n  ${4:// code to handle exception}\n}",
        "documentation": "Exception handling with try-catch"
      },
      {
        "label": "throw",
        "insertText": "throw ${1:ExceptionType}(${2:message})",
        "documentation": "Throw an exception"
      },
      {
        "label": "return",
        "insertText": "return ${1:value}",
        "documentation": "Return a value from a function"
      },
      {
        "label": "override",
        "insertText": "override ${1:member}",
        "documentation": "Override a member (in subclasses or interfaces)"
      },
      {
        "label": "super",
        "insertText": "super.${1:method}(${2:args})",
        "documentation": "Call a superclass method or constructor"
      },
      {
        "label": "is",
        "insertText": "${1:value} is ${2:Type}",
        "documentation": "Check if a value is an instance of a type"
      },
      {
        "label": "in",
        "insertText": "${1:value} in ${2:range}",
        "documentation": "Check if a value is in a range or collection"
      },
      {
        "label": "listOf",
        "insertText": "listOf(${1:values})",
        "documentation": "Create a read-only list"
      }
    ]
    },
    {name:'Lua (5.3.5)',value: 'lua',suggestion:[
      {
        "label": "function",
        "insertText": "function ${1:funcName}(${2:params})\n  ${3:-- function body}\nend",
        "documentation": "Define a new function"
      },
      {
        "label": "local function",
        "insertText": "local function ${1:funcName}(${2:params})\n  ${3:-- function body}\nend",
        "documentation": "Define a new local function"
      },
      {
        "label": "if",
        "insertText": "if ${1:condition} then\n  ${2:-- code to execute}\nend",
        "documentation": "Conditional statement"
      },
      {
        "label": "if-else",
        "insertText": "if ${1:condition} then\n  ${2:-- true branch}\nelse\n  ${3:-- false branch}\nend",
        "documentation": "If-else statement"
      },
      {
        "label": "for",
        "insertText": "for ${1:var} = ${2:start}, ${3:stop}, ${4:step} do\n  ${5:-- code to repeat}\nend",
        "documentation": "For loop"
      },
      {
        "label": "while",
        "insertText": "while ${1:condition} do\n  ${2:-- code to execute}\nend",
        "documentation": "While loop"
      },
      {
        "label": "repeat-until",
        "insertText": "repeat\n  ${1:-- code to execute}\nuntil ${2:condition}",
        "documentation": "Repeat until loop"
      },
      {
        "label": "function call",
        "insertText": "${1:funcName}(${2:args})",
        "documentation": "Call a function"
      },
      {
        "label": "local variable",
        "insertText": "local ${1:varName} = ${2:value}",
        "documentation": "Declare a local variable"
      },
      {
        "label": "table",
        "insertText": "${1:tableName} = {\n  ${2:key1} = ${3:value1},\n  ${4:key2} = ${5:value2}\n}",
        "documentation": "Declare a table"
      },
      {
        "label": "if table has key",
        "insertText": "if ${1:tableName}.${2:key} then\n  ${3:-- code to execute}\nend",
        "documentation": "Check if a table has a key"
      },
      {
        "label": "function definition",
        "insertText": "${1:funcName} = function(${2:params})\n  ${3:-- function body}\nend",
        "documentation": "Define a function using an alternate syntax"
      },
      {
        "label": "return",
        "insertText": "return ${1:value}",
        "documentation": "Return a value from a function"
      },
      {
        "label": "break",
        "insertText": "break",
        "documentation": "Break out of a loop"
      },
      {
        "label": "continue",
        "insertText": "goto ${1:loopLabel}",
        "documentation": "Continue to the next iteration of a labeled loop"
      },
      {
        "label": "require",
        "insertText": "require(\"${1:module}\")",
        "documentation": "Load a Lua module"
      },
      {
        "label": "coroutine.create",
        "insertText": "coroutine.create(function(${1:params})\n  ${2:-- coroutine body}\nend)",
        "documentation": "Create a new coroutine"
      },
      {
        "label": "coroutine.resume",
        "insertText": "coroutine.resume(${1:coroutine}, ${2:args})",
        "documentation": "Resume the execution of a coroutine"
      },
      {
        "label": "coroutine.yield",
        "insertText": "coroutine.yield(${1:args})",
        "documentation": "Yield the current coroutine"
      },
      {
        "label": "module",
        "insertText": "module(\"${1:moduleName}\")",
        "documentation": "Define a Lua module"
      }
    ]
    },
    {name:'Objective-C (Clang 7.0.1)',value: 'objective-c',suggestion:[
      {
        "label": "@interface",
        "insertText": "@interface ${1:ClassName} : ${2:SuperclassName}\n\n${3:// Interface contents}\n@end",
        "documentation": "Define a new Objective-C class interface"
      },
      {
        "label": "@implementation",
        "insertText": "@implementation ${1:ClassName}\n\n${2:// Implementation contents}\n@end",
        "documentation": "Define the implementation of an Objective-C class"
      },
      {
        "label": "@protocol",
        "insertText": "@protocol ${1:ProtocolName} <${2:ParentProtocol}>\n\n${3:// Protocol contents}\n@end",
        "documentation": "Define a new Objective-C protocol"
      },
      {
        "label": "@property",
        "insertText": "@property (${1:attributes}) ${2:Type} ${3:propertyName};",
        "documentation": "Define a property for a class"
      },
      {
        "label": "init",
        "insertText": "- (instancetype)init {\n    self = [super init];\n    if (self) {\n        ${1:// Initialization code}\n    }\n    return self;\n}",
        "documentation": "Define an initializer method"
      },
      {
        "label": "dealloc",
        "insertText": "- (void)dealloc {\n    [super dealloc];\n    ${1:// Cleanup code}\n}",
        "documentation": "Define the dealloc method for memory management"
      },
      {
        "label": "synthesize",
        "insertText": "@synthesize ${1:propertyName} = ${2:_ivarName};",
        "documentation": "Generate getter and setter methods for a property"
      },
      {
        "label": "@selector",
        "insertText": "SEL ${1:methodSelector} = @selector(${2:methodName});",
        "documentation": "Define a selector for a method"
      },
      {
        "label": "IBAction",
        "insertText": "- (IBAction)${1:methodname}:(id)sender {\n    ${2:// Action code}\n}",
        "documentation": "Define an Interface Builder action method"
      },
      {
        "label": "IBOutlet",
        "insertText": "@property (nonatomic, ${1:strong}) IBOutlet ${2:Type} ${3:outletName};",
        "documentation": "Define an Interface Builder outlet"
      },
      {
        "label": "#import",
        "insertText": "#import \"${1:HeaderFileName.h}\"",
        "documentation": "Import a header file"
      },
      {
        "label": "#import (framework)",
        "insertText": "#import <${1:FrameworkName/FrameworkHeader.h}>",
        "documentation": "Import a framework header file"
      },
      {
        "label": "#pragma mark",
        "insertText": "#pragma mark - ${1:SectionName}",
        "documentation": "Add a pragma mark for organizing code"
      },
      {
        "label": "#define",
        "insertText": "#define ${1:MacroName} ${2:MacroValue}",
        "documentation": "Define a preprocessor macro"
      },
      {
        "label": "#ifdef",
        "insertText": "#ifdef ${1:MacroName}\n    ${2:// Code to include if macro is defined}\n#endif",
        "documentation": "Conditional compilation based on macro definition"
      },
      {
        "label": "NSLog",
        "insertText": "NSLog(@\"${1:format}\"${2:, args});",
        "documentation": "Output a formatted log message"
      },
      {
        "label": "NSError",
        "insertText": "NSError *${1:error} = nil;",
        "documentation": "Declare an NSError object"
      },
      {
        "label": "NSString",
        "insertText": "NSString *${1:string} = @\"${2:value}\";",
        "documentation": "Declare an NSString object"
      },
      {
        "label": "NSInteger",
        "insertText": "NSInteger ${1:number} = ${2:value};",
        "documentation": "Declare an NSInteger variable"
      }
    ]
    },
    {name:'OCaml (4.09.0)',value: 'ocaml',suggestion:[
      {
        "label": "let",
        "insertText": "let ${1:variableName} = ${2:value} in ${3:expression}",
        "documentation": "Declare a new variable and binding"
      },
      {
        "label": "let rec",
        "insertText": "let rec ${1:functionName} ${2:params} = ${3:expression}",
        "documentation": "Declare a recursive function"
      },
      {
        "label": "fun",
        "insertText": "fun ${1:params} -> ${2:expression}",
        "documentation": "Define an anonymous function"
      },
      {
        "label": "type",
        "insertText": "type ${1:typeName} = ${2:typeDefinition}",
        "documentation": "Define a new type"
      },
      {
        "label": "module",
        "insertText": "module ${1:ModuleName} = struct\n  ${2:// module contents}\nend",
        "documentation": "Define a new module"
      },
      {
        "label": "open",
        "insertText": "open ${1:ModuleName}",
        "documentation": "Open a module for usage"
      },
      {
        "label": "match",
        "insertText": "match ${1:value} with\n  | ${2:pattern1} -> ${3:result1}\n  | ${4:pattern2} -> ${5:result2}\n  | _ -> ${6:defaultCase}",
        "documentation": "Pattern matching"
      },
      {
        "label": "if",
        "insertText": "if ${1:condition} then ${2:trueBranch} else ${3:falseBranch}",
        "documentation": "Conditional statement"
      },
      {
        "label": "try",
        "insertText": "try ${1:expression} with\n  | ${2:Exception} -> ${3:handler}",
        "documentation": "Exception handling with try...with"
      },
      {
        "label": "raise",
        "insertText": "raise ${1:Exception}(${2:message})",
        "documentation": "Raise an exception"
      },
      {
        "label": "for",
        "insertText": "for ${1:i} = ${2:start} to ${3:stop} do\n  ${4:// code to repeat}\ndone",
        "documentation": "For loop"
      },
      {
        "label": "while",
        "insertText": "while ${1:condition} do\n  ${2:// code to execute}\ndone",
        "documentation": "While loop"
      },
      {
        "label": "do while",
        "insertText": "do\n  ${1:// code to execute}\ndone while ${2:condition}",
        "documentation": "Do...while loop"
      },
      {
        "label": "match case",
        "insertText": "| ${1:pattern} -> ${2:result}",
        "documentation": "Pattern matching case"
      },
      {
        "label": "function signature",
        "insertText": "val ${1:functionName} : ${2:paramType} -> ${3:returnType}",
        "documentation": "Declare a function signature"
      },
      {
        "label": "mutable",
        "insertText": "mutable ${1:variableName} = ${2:value}",
        "documentation": "Declare a mutable variable"
      },
      {
        "label": "List.map",
        "insertText": "List.map (fun ${1:param} -> ${2:mapFunction}) ${3:list}",
        "documentation": "Map a function over a list"
      },
      {
        "label": "List.filter",
        "insertText": "List.filter (fun ${1:param} -> ${2:filterCondition}) ${3:list}",
        "documentation": "Filter elements in a list"
      },
      {
        "label": "List.fold_left",
        "insertText": "List.fold_left (fun ${1:acc} ${2:param} -> ${3:foldFunction}) ${4:initial} ${5:list}",
        "documentation": "Left fold over a list"
      }
    ]
    },
    {name:'Octave (5.1.0)',value: 'octave',suggestion:[
      {
        "label": "function",
        "insertText": "function ${1:result} = ${2:functionName}(${3:params})\n  ${4:// function body}\nendfunction",
        "documentation": "Define a new function"
      },
      {
        "label": "if",
        "insertText": "if ${1:condition}\n  ${2:// code to execute}\nendif",
        "documentation": "Conditional statement"
      },
      {
        "label": "else",
        "insertText": "else\n  ${1:// code to execute}\nendif",
        "documentation": "Else statement for conditional blocks"
      },
      {
        "label": "elseif",
        "insertText": "elseif ${1:condition}\n  ${2:// code to execute}\nendif",
        "documentation": "Elseif statement for conditional blocks"
      },
      {
        "label": "for",
        "insertText": "for ${1:variable} = ${2:start}:${3:step}:${4:stop}\n  ${5:// code to repeat}\nendfor",
        "documentation": "For loop"
      },
      {
        "label": "while",
        "insertText": "while ${1:condition}\n  ${2:// code to execute}\nendwhile",
        "documentation": "While loop"
      },
      {
        "label": "break",
        "insertText": "break",
        "documentation": "Break out of a loop"
      },
      {
        "label": "continue",
        "insertText": "continue",
        "documentation": "Continue to the next iteration of a loop"
      },
      {
        "label": "return",
        "insertText": "return ${1:value}",
        "documentation": "Return a value from a function"
      },
      {
        "label": "disp",
        "insertText": "disp(${1:message})",
        "documentation": "Display a message in the console"
      },
      {
        "label": "fprintf",
        "insertText": "fprintf(${1:\"format\"}, ${2:args})",
        "documentation": "Formatted output to a file"
      },
      {
        "label": "plot",
        "insertText": "plot(${1:x}, ${2:y})",
        "documentation": "Create a 2D plot"
      },
      {
        "label": "xlabel",
        "insertText": "xlabel(${1:\"label\"})",
        "documentation": "Set the label for the x-axis"
      },
      {
        "label": "ylabel",
        "insertText": "ylabel(${1:\"label\"})",
        "documentation": "Set the label for the y-axis"
      },
      {
        "label": "title",
        "insertText": "title(${1:\"title\"})",
        "documentation": "Set the plot title"
      },
      {
        "label": "legend",
        "insertText": "legend(${1:legend})",
        "documentation": "Add a legend to the plot"
      },
      {
        "label": "grid",
        "insertText": "grid(${1:\"on\"})",
        "documentation": "Toggle grid lines on or off"
      },
      {
        "label": "load",
        "insertText": "load(${1:\"filename\"})",
        "documentation": "Load data from a file"
      },
      {
        "label": "save",
        "insertText": "save(${1:\"filename\"}, ${2:data})",
        "documentation": "Save data to a file"
      },
      {
        "label": "rand",
        "insertText": "rand(${1:rows}, ${2:cols})",
        "documentation": "Generate random numbers in a matrix"
      }
    ]
    },
    {name:'Pascal (FPC 3.0.4)',value: 'pascal',suggestion:[
      {
        "label": "program",
        "insertText": "program ${1:ProgramName};\n\n${2:// Program code}\nbegin\n  ${3:// Main program logic}\nend.",
        "documentation": "Define a new Pascal program"
      },
      {
        "label": "procedure",
        "insertText": "procedure ${1:ProcedureName};\nbegin\n  ${2:// Procedure code}\nend;",
        "documentation": "Define a new procedure"
      },
      {
        "label": "function",
        "insertText": "function ${1:FunctionName}(${2:params}): ${3:ReturnType};\nbegin\n  ${4:// Function code}\nend;",
        "documentation": "Define a new function"
      },
      {
        "label": "var",
        "insertText": "var\n  ${1:VariableName}: ${2:VariableType};",
        "documentation": "Declare a variable"
      },
      {
        "label": "const",
        "insertText": "const\n  ${1:ConstantName} = ${2:ConstantValue};",
        "documentation": "Declare a constant"
      },
      {
        "label": "type",
        "insertText": "type\n  ${1:TypeName} = ${2:TypeDefinition};",
        "documentation": "Define a new data type"
      },
      {
        "label": "begin",
        "insertText": "begin\n  ${1:// Code to execute}\nend;",
        "documentation": "Begin a block of code"
      },
      {
        "label": "if",
        "insertText": "if ${1:condition} then\nbegin\n  ${2:// Code to execute}\nend;",
        "documentation": "Conditional statement"
      },
      {
        "label": "else",
        "insertText": "else\nbegin\n  ${1:// Code to execute}\nend;",
        "documentation": "Else statement for conditional blocks"
      },
      {
        "label": "while",
        "insertText": "while ${1:condition} do\nbegin\n  ${2:// Code to execute}\nend;",
        "documentation": "While loop"
      },
      {
        "label": "repeat",
        "insertText": "repeat\nbegin\n  ${1:// Code to execute}\nend\nuntil ${2:condition};",
        "documentation": "Repeat-until loop"
      },
      {
        "label": "for",
        "insertText": "for ${1:variable} := ${2:start} to ${3:stop} do\nbegin\n  ${4:// Code to repeat}\nend;",
        "documentation": "For loop"
      },
      {
        "label": "case",
        "insertText": "case ${1:expression} of\n  ${2:case1}: ${3:// Code for case1}\n  ${4:case2}: ${5:// Code for case2}\n  else ${6:// Default code}\nend;",
        "documentation": "Case statement"
      },
      {
        "label": "break",
        "insertText": "break;",
        "documentation": "Break out of a loop"
      },
      {
        "label": "continue",
        "insertText": "continue;",
        "documentation": "Continue to the next iteration of a loop"
      },
      {
        "label": "exit",
        "insertText": "exit;",
        "documentation": "Exit the program or procedure"
      },
      {
        "label": "writeln",
        "insertText": "writeln(${1:output});",
        "documentation": "Write a line to the standard output"
      },
      {
        "label": "readln",
        "insertText": "readln(${1:input});",
        "documentation": "Read a line from the standard input"
      },
      {
        "label": "randomize",
        "insertText": "randomize;",
        "documentation": "Seed the random number generator"
      }
    ]
    },
    {name:'Perl (5.28.1)',value: 'perl',suggestion:[
      {
        "label": "sub",
        "insertText": "sub ${1:subroutineName} {\n  ${2:# code to execute}\n}",
        "documentation": "Define a new subroutine"
      },
      {
        "label": "my",
        "insertText": "my ${1:variableName} = ${2:value};",
        "documentation": "Declare a lexical variable"
      },
      {
        "label": "our",
        "insertText": "our ${1:variableName} = ${2:value};",
        "documentation": "Declare a package (global) variable"
      },
      {
        "label": "if",
        "insertText": "if (${1:condition}) {\n  ${2:# code to execute}\n}",
        "documentation": "Conditional statement"
      },
      {
        "label": "else",
        "insertText": "else {\n  ${1:# code to execute}\n}",
        "documentation": "Else statement for conditional blocks"
      },
      {
        "label": "elsif",
        "insertText": "elsif (${1:condition}) {\n  ${2:# code to execute}\n}",
        "documentation": "Elsif statement for conditional blocks"
      },
      {
        "label": "unless",
        "insertText": "unless (${1:condition}) {\n  ${2:# code to execute}\n}",
        "documentation": "Unless statement for conditional blocks"
      },
      {
        "label": "while",
        "insertText": "while (${1:condition}) {\n  ${2:# code to execute}\n}",
        "documentation": "While loop"
      },
      {
        "label": "for",
        "insertText": "for my ${1:$element} (${2:@array}) {\n  ${3:# code to repeat}\n}",
        "documentation": "For loop using elements of an array"
      },
      {
        "label": "foreach",
        "insertText": "foreach my ${1:$element} (${2:@array}) {\n  ${3:# code to repeat}\n}",
        "documentation": "Foreach loop using elements of an array"
      },
      {
        "label": "for keys",
        "insertText": "for my ${1:$key} (keys %${2:%hash}) {\n  ${3:# code to repeat}\n}",
        "documentation": "For loop iterating over keys of a hash"
      },
      {
        "label": "for values",
        "insertText": "for my ${1:$value} (values %${2:%hash}) {\n  ${3:# code to repeat}\n}",
        "documentation": "For loop iterating over values of a hash"
      },
      {
        "label": "last",
        "insertText": "last;",
        "documentation": "Exit the innermost loop"
      },
      {
        "label": "next",
        "insertText": "next;",
        "documentation": "Skip the rest of the current iteration and move to the next"
      },
      {
        "label": "print",
        "insertText": "print ${1:\"text\"};",
        "documentation": "Print text to the standard output"
      },
      {
        "label": "say",
        "insertText": "say ${1:\"text\"};",
        "documentation": "Print text followed by a newline to the standard output"
      },
      {
        "label": "open",
        "insertText": "open ${1:FILEHANDLE}, ${2:\"filename\"}, ${3:mode};",
        "documentation": "Open a file"
      },
      {
        "label": "close",
        "insertText": "close ${1:FILEHANDLE};",
        "documentation": "Close a file"
      },
      {
        "label": "chomp",
        "insertText": "chomp(${1:variable});",
        "documentation": "Remove trailing newline from a string"
      },
      {
        "label": "split",
        "insertText": "split /${1:pattern}/, ${2:string};",
        "documentation": "Split a string into an array"
      }
    ]
    },
    {name:'PHP (7.4.1)',value: 'php',suggestion:[
      {
        "label": "echo",
        "insertText": "echo ${1:\"Hello, World!\"};",
        "documentation": "Output text to the browser"
      },
      {
        "label": "print",
        "insertText": "print(${1:\"Hello, World!\"});",
        "documentation": "Output text to the browser (return value is 1)"
      },
      {
        "label": "if",
        "insertText": "if (${1:condition}) {\n  ${2:// code to execute}\n}",
        "documentation": "Conditional statement"
      },
      {
        "label": "else",
        "insertText": "else {\n  ${1:// code to execute}\n}",
        "documentation": "Else statement for conditional blocks"
      },
      {
        "label": "elseif",
        "insertText": "elseif (${1:condition}) {\n  ${2:// code to execute}\n}",
        "documentation": "Elseif statement for conditional blocks"
      },
      {
        "label": "switch",
        "insertText": "switch (${1:variable}) {\n  case ${2:value}:\n    ${3:// code to execute}\n    break;\n  default:\n    ${4:// default code}\n}",
        "documentation": "Switch statement"
      },
      {
        "label": "while",
        "insertText": "while (${1:condition}) {\n  ${2:// code to execute}\n}",
        "documentation": "While loop"
      },
      {
        "label": "do while",
        "insertText": "do {\n  ${1:// code to execute}\n} while (${2:condition});",
        "documentation": "Do...while loop"
      },
      {
        "label": "for",
        "insertText": "for (${1:init}; ${2:condition}; ${3:increment}) {\n  ${4:// code to repeat}\n}",
        "documentation": "For loop"
      },
      {
        "label": "foreach",
        "insertText": "foreach (${1:array} as ${2:$key} => ${3:$value}) {\n  ${4:// code to repeat}\n}",
        "documentation": "Foreach loop"
      },
      {
        "label": "break",
        "insertText": "break;",
        "documentation": "Break out of a loop"
      },
      {
        "label": "continue",
        "insertText": "continue;",
        "documentation": "Continue to the next iteration of a loop"
      },
      {
        "label": "function",
        "insertText": "function ${1:functionName}(${2:params}) {\n  ${3:// function body}\n}",
        "documentation": "Define a new function"
      },
      {
        "label": "class",
        "insertText": "class ${1:ClassName} {\n  ${2:// class body}\n}",
        "documentation": "Define a new class"
      },
      {
        "label": "public function",
        "insertText": "public function ${1:functionName}(${2:params}) {\n  ${3:// function body}\n}",
        "documentation": "Define a public class method"
      },
      {
        "label": "private function",
        "insertText": "private function ${1:functionName}(${2:params}) {\n  ${3:// function body}\n}",
        "documentation": "Define a private class method"
      },
      {
        "label": "protected function",
        "insertText": "protected function ${1:functionName}(${2:params}) {\n  ${3:// function body}\n}",
        "documentation": "Define a protected class method"
      },
      {
        "label": "static function",
        "insertText": "static function ${1:functionName}(${2:params}) {\n  ${3:// function body}\n}",
        "documentation": "Define a static class method"
      },
      {
        "label": "return",
        "insertText": "return ${1:value};",
        "documentation": "Return a value from a function"
      },
      {
        "label": "include",
        "insertText": "include \"${1:filename}\";",
        "documentation": "Include a PHP file"
      }
    ]
    },
    {name:'Prolog (GNU Prolog 1.4.5)',value: 'prolog',suggestion:[
      {
        "label": "consult",
        "insertText": "consult('${1:filename}').",
        "documentation": "Consult (load) a Prolog source file"
      },
      {
        "label": "consult without facts",
        "insertText": "consult('${1:filename}', [noload]).",
        "documentation": "Consult a Prolog source file without loading facts"
      },
      {
        "label": "consult absolute path",
        "insertText": "consult('${1:/absolute/path/to/file}').",
        "documentation": "Consult a Prolog source file using an absolute path"
      },
      {
        "label": "consult relative path",
        "insertText": "consult('${1:relative/path/to/file}').",
        "documentation": "Consult a Prolog source file using a relative path"
      },
      {
        "label": "listing",
        "insertText": "listing.",
        "documentation": "List all predicates in the current knowledge base"
      },
      {
        "label": "listing specific predicate",
        "insertText": "listing(${1:predicateName}).",
        "documentation": "List a specific predicate in the current knowledge base"
      },
      {
        "label": "assert",
        "insertText": "assert(${1:predicate}).",
        "documentation": "Assert a new predicate or fact"
      },
      {
        "label": "retract",
        "insertText": "retract(${1:predicate}).",
        "documentation": "Retract a predicate or fact"
      },
      {
        "label": "retractall",
        "insertText": "retractall(${1:predicate}).",
        "documentation": "Retract all matching predicates or facts"
      },
      {
        "label": ":-",
        "insertText": "${1:head} :-\n  ${2:body}.",
        "documentation": "Define a rule or clause"
      },
      {
        "label": "true",
        "insertText": "true.",
        "documentation": "Succeed unconditionally"
      },
      {
        "label": "fail",
        "insertText": "fail.",
        "documentation": "Fail unconditionally"
      },
      {
        "label": "cut",
        "insertText": "!, ${1:alternative}.",
        "documentation": "Cut operator to prune choice points"
      },
      {
        "label": "not",
        "insertText": "not(${1:condition}).",
        "documentation": "Negation operator"
      },
      {
        "label": "member",
        "insertText": "member(${1:element}, ${2:list}).",
        "documentation": "Check if an element is a member of a list"
      },
      {
        "label": "append",
        "insertText": "append(${1:list1}, ${2:list2}, ${3:concatenatedList}).",
        "documentation": "Concatenate two lists"
      },
      {
        "label": "length",
        "insertText": "length(${1:list}, ${2:length}).",
        "documentation": "Get the length of a list"
      },
      {
        "label": "is",
        "insertText": "${1:Variable} is ${2:Expression}.",
        "documentation": "Evaluate an arithmetic expression"
      },
      {
        "label": "findall",
        "insertText": "findall(${1:Variable}, ${2:Goal}, ${3:List}).",
        "documentation": "Find all solutions to a goal and collect them in a list"
      },
      {
        "label": "bagof",
        "insertText": "bagof(${1:Variable}, ${2:Goal}, ${3:Bag}).",
        "documentation": "Collect solutions to a goal into a bag"
      }
    ]
    },
    {name:'Python (2.7.17)',value: 'python',suggestion: [
      {
        label: "print",
        insertText: "print(${1:message})",
        documentation: "Print to console",
      },
      {
        label: "for",
        insertText: [
          "for ${1:item} in ${2:iterable}:",
          "\t$0",
        ].join("\n"),
        documentation: "For loop",
      },
      {
        label: "if",
        insertText: [
          "if ${1:condition}:",
          "\t$0",
        ].join("\n"),
        documentation: "If statement",
      },
      {
        label: "def",
        insertText: [
          "def ${1:function_name}(${2:parameters}):",
          "\t$0",
        ].join("\n"),
        documentation: "Define function",
      },
      {
        label: "while",
        insertText: [
          "while ${1:condition}:",
          "\t$0",
        ].join("\n"),
        documentation: "While loop",
      },
      {
        label: "class",
        insertText: [
          "class ${1:ClassName}:",
          "\tdef __init__(self, ${2:args}):",
          "\t\t$0",
        ].join("\n"),
        documentation: "Define class",
      },
      
    {
      label: "import",
      insertText: "import ${1:module}",
      documentation: "Import a module",
    },
    {
      label: "try",
      insertText: [
        "try:",
        "\t$0",
        "except ${1:Exception} as ${2:e}:",
        "\t$3",
      ].join("\n"),
      documentation: "Try...except block",
    },
    {
      label: "while",
      insertText: [
        "while ${1:condition}:",
        "\t$0",
      ].join("\n"),
      documentation: "While loop",
    },
    {
      label: "with",
      insertText: [
        "with ${1:context_manager} as ${2:alias}:",
        "\t$0",
      ].join("\n"),
      documentation: "Context manager (with statement)",
    },
    {
      label: "lambda",
      insertText: "lambda ${1:params}: ${2:expression}",
      documentation: "Lambda function",
    },
    {
      label: "__name__",
      insertText: "__name__",
      documentation: "Module name",
    },
    {
      label: "return",
      insertText: "return ${1:value}",
      documentation: "Return statement",
    },
    {
      label: "len",
      insertText: "len(${1:iterable})",
      documentation: "Get length of an iterable",
    },
    {
      label: "range",
      insertText: "range(${1:start}, ${2:stop}, ${3:step})",
      documentation: "Generate a range of numbers",
    },
    {
      label: "input",
      insertText: "input(${1:message})",
      documentation: "Read input from the user",
    },
    {
      label: "open",
      insertText: "open(${1:file_path}, ${2:mode})",
      documentation: "Open a file",
    },
    {
      label: "enumerate",
      insertText: "enumerate(${1:iterable}, ${2:start})",
      documentation: "Iterate with an index",
    },
    {
      label: "zip",
      insertText: "zip(${1:iterable1}, ${2:iterable2})",
      documentation: "Combine iterables",
    },
    {
      label: "sorted",
      insertText: "sorted(${1:iterable}, ${2:key}, ${3:reverse})",
      documentation: "Sort iterable elements",
    },
    {
      label: "map",
      insertText: "map(${1:function}, ${2:iterable})",
      documentation: "Apply a function to each element",
    },
    {
      label: "filter",
      insertText: "filter(${1:function}, ${2:iterable})",
      documentation: "Filter elements based on a function",
    },
    {
      label: "max",
      insertText: "max(${1:iterable}, ${2:key}, ${3:default})",
      documentation: "Get the maximum element",
    },
    {
      label: "min",
      insertText: "min(${1:iterable}, ${2:key}, ${3:default})",
      documentation: "Get the minimum element",
    },
    {
      label: "sum",
      insertText: "sum(${1:iterable}, ${2:start})",
      documentation: "Calculate the sum of elements",
    },
    {
      label: "round",
      insertText: "round(${1:number}, ${2:ndigits})",
      documentation: "Round a number",
    },
    {
      label: "str",
      insertText: "str(${1:value})",
      documentation: "Convert to string",
    },
    ]},
    {name:'Python (3.11.2)',value: 'python',suggestion: [
      {
        label: "print",
        insertText: "print(${1:message})",
        documentation: "Print to console",
      },
      {
        label: "for",
        insertText: [
          "for ${1:item} in ${2:iterable}:",
          "\t$0",
        ].join("\n"),
        documentation: "For loop",
      },
      {
        label: "if",
        insertText: [
          "if ${1:condition}:",
          "\t$0",
        ].join("\n"),
        documentation: "If statement",
      },
      {
        label: "def",
        insertText: [
          "def ${1:function_name}(${2:parameters}):",
          "\t$0",
        ].join("\n"),
        documentation: "Define function",
      },
      {
        label: "while",
        insertText: [
          "while ${1:condition}:",
          "\t$0",
        ].join("\n"),
        documentation: "While loop",
      },
      {
        label: "class",
        insertText: [
          "class ${1:ClassName}:",
          "\tdef __init__(self, ${2:args}):",
          "\t\t$0",
        ].join("\n"),
        documentation: "Define class",
      },
      
    {
      label: "import",
      insertText: "import ${1:module}",
      documentation: "Import a module",
    },
    {
      label: "try",
      insertText: [
        "try:",
        "\t$0",
        "except ${1:Exception} as ${2:e}:",
        "\t$3",
      ].join("\n"),
      documentation: "Try...except block",
    },
    {
      label: "while",
      insertText: [
        "while ${1:condition}:",
        "\t$0",
      ].join("\n"),
      documentation: "While loop",
    },
    {
      label: "with",
      insertText: [
        "with ${1:context_manager} as ${2:alias}:",
        "\t$0",
      ].join("\n"),
      documentation: "Context manager (with statement)",
    },
    {
      label: "lambda",
      insertText: "lambda ${1:params}: ${2:expression}",
      documentation: "Lambda function",
    },
    {
      label: "__name__",
      insertText: "__name__",
      documentation: "Module name",
    },
    {
      label: "return",
      insertText: "return ${1:value}",
      documentation: "Return statement",
    },
    {
      label: "len",
      insertText: "len(${1:iterable})",
      documentation: "Get length of an iterable",
    },
    {
      label: "range",
      insertText: "range(${1:start}, ${2:stop}, ${3:step})",
      documentation: "Generate a range of numbers",
    },
    {
      label: "input",
      insertText: "input(${1:message})",
      documentation: "Read input from the user",
    },
    {
      label: "open",
      insertText: "open(${1:file_path}, ${2:mode})",
      documentation: "Open a file",
    },
    {
      label: "enumerate",
      insertText: "enumerate(${1:iterable}, ${2:start})",
      documentation: "Iterate with an index",
    },
    {
      label: "zip",
      insertText: "zip(${1:iterable1}, ${2:iterable2})",
      documentation: "Combine iterables",
    },
    {
      label: "sorted",
      insertText: "sorted(${1:iterable}, ${2:key}, ${3:reverse})",
      documentation: "Sort iterable elements",
    },
    {
      label: "map",
      insertText: "map(${1:function}, ${2:iterable})",
      documentation: "Apply a function to each element",
    },
    {
      label: "filter",
      insertText: "filter(${1:function}, ${2:iterable})",
      documentation: "Filter elements based on a function",
    },
    {
      label: "max",
      insertText: "max(${1:iterable}, ${2:key}, ${3:default})",
      documentation: "Get the maximum element",
    },
    {
      label: "min",
      insertText: "min(${1:iterable}, ${2:key}, ${3:default})",
      documentation: "Get the minimum element",
    },
    {
      label: "sum",
      insertText: "sum(${1:iterable}, ${2:start})",
      documentation: "Calculate the sum of elements",
    },
    {
      label: "round",
      insertText: "round(${1:number}, ${2:ndigits})",
      documentation: "Round a number",
    },
    {
      label: "str",
      insertText: "str(${1:value})",
      documentation: "Convert to string",
    },
    ]},
    {name:'Python (3.8.1)',value: 'python', suggestion: [
      {
        label: "print",
        insertText: "print(${1:message})",
        documentation: "Print to console",
      },
      {
        label: "for",
        insertText: [
          "for ${1:item} in ${2:iterable}:",
          "\t$0",
        ].join("\n"),
        documentation: "For loop",
      },
      {
        label: "if",
        insertText: [
          "if ${1:condition}:",
          "\t$0",
        ].join("\n"),
        documentation: "If statement",
      },
      {
        label: "def",
        insertText: [
          "def ${1:function_name}(${2:parameters}):",
          "\t$0",
        ].join("\n"),
        documentation: "Define function",
      },
      {
        label: "while",
        insertText: [
          "while ${1:condition}:",
          "\t$0",
        ].join("\n"),
        documentation: "While loop",
      },
      {
        label: "class",
        insertText: [
          "class ${1:ClassName}:",
          "\tdef __init__(self, ${2:args}):",
          "\t\t$0",
        ].join("\n"),
        documentation: "Define class",
      },
      
    {
      label: "import",
      insertText: "import ${1:module}",
      documentation: "Import a module",
    },
    {
      label: "try",
      insertText: [
        "try:",
        "\t$0",
        "except ${1:Exception} as ${2:e}:",
        "\t$3",
      ].join("\n"),
      documentation: "Try...except block",
    },
    {
      label: "while",
      insertText: [
        "while ${1:condition}:",
        "\t$0",
      ].join("\n"),
      documentation: "While loop",
    },
    {
      label: "with",
      insertText: [
        "with ${1:context_manager} as ${2:alias}:",
        "\t$0",
      ].join("\n"),
      documentation: "Context manager (with statement)",
    },
    {
      label: "lambda",
      insertText: "lambda ${1:params}: ${2:expression}",
      documentation: "Lambda function",
    },
    {
      label: "__name__",
      insertText: "__name__",
      documentation: "Module name",
    },
    {
      label: "return",
      insertText: "return ${1:value}",
      documentation: "Return statement",
    },
    {
      label: "len",
      insertText: "len(${1:iterable})",
      documentation: "Get length of an iterable",
    },
    {
      label: "range",
      insertText: "range(${1:start}, ${2:stop}, ${3:step})",
      documentation: "Generate a range of numbers",
    },
    {
      label: "input",
      insertText: "input(${1:message})",
      documentation: "Read input from the user",
    },
    {
      label: "open",
      insertText: "open(${1:file_path}, ${2:mode})",
      documentation: "Open a file",
    },
    {
      label: "enumerate",
      insertText: "enumerate(${1:iterable}, ${2:start})",
      documentation: "Iterate with an index",
    },
    {
      label: "zip",
      insertText: "zip(${1:iterable1}, ${2:iterable2})",
      documentation: "Combine iterables",
    },
    {
      label: "sorted",
      insertText: "sorted(${1:iterable}, ${2:key}, ${3:reverse})",
      documentation: "Sort iterable elements",
    },
    {
      label: "map",
      insertText: "map(${1:function}, ${2:iterable})",
      documentation: "Apply a function to each element",
    },
    {
      label: "filter",
      insertText: "filter(${1:function}, ${2:iterable})",
      documentation: "Filter elements based on a function",
    },
    {
      label: "max",
      insertText: "max(${1:iterable}, ${2:key}, ${3:default})",
      documentation: "Get the maximum element",
    },
    {
      label: "min",
      insertText: "min(${1:iterable}, ${2:key}, ${3:default})",
      documentation: "Get the minimum element",
    },
    {
      label: "sum",
      insertText: "sum(${1:iterable}, ${2:start})",
      documentation: "Calculate the sum of elements",
    },
    {
      label: "round",
      insertText: "round(${1:number}, ${2:ndigits})",
      documentation: "Round a number",
    },
    {
      label: "str",
      insertText: "str(${1:value})",
      documentation: "Convert to string",
    },
    ]},
    {name:'R (4.0.0)',value: 'r',suggestion:[
      {
        "label": "print",
        "insertText": "print(${1:expression});",
        "documentation": "Print the result of an expression to the console"
      },
      {
        "label": "plot",
        "insertText": "plot(${1:x}, ${2:y});",
        "documentation": "Create a scatter plot"
      },
      {
        "label": "hist",
        "insertText": "hist(${1:data}, ${2:breaks});",
        "documentation": "Create a histogram plot"
      },
      {
        "label": "barplot",
        "insertText": "barplot(${1:heights}, ${2:names.arg});",
        "documentation": "Create a barplot"
      },
      {
        "label": "boxplot",
        "insertText": "boxplot(${1:x});",
        "documentation": "Create a boxplot"
      },
      {
        "label": "summary",
        "insertText": "summary(${1:data});",
        "documentation": "Generate summary statistics for data"
      },
      {
        "label": "mean",
        "insertText": "mean(${1:data});",
        "documentation": "Calculate the mean of a vector or data frame"
      },
      {
        "label": "median",
        "insertText": "median(${1:data});",
        "documentation": "Calculate the median of a vector or data frame"
      },
      {
        "label": "sd",
        "insertText": "sd(${1:data});",
        "documentation": "Calculate the standard deviation of a vector or data frame"
      },
      {
        "label": "var",
        "insertText": "var(${1:data});",
        "documentation": "Calculate the variance of a vector or data frame"
      },
      {
        "label": "cor",
        "insertText": "cor(${1:x}, ${2:y});",
        "documentation": "Calculate the correlation coefficient between two vectors"
      },
      {
        "label": "lm",
        "insertText": "lm(${1:formula}, ${2:data});",
        "documentation": "Fit a linear regression model"
      },
      {
        "label": "anova",
        "insertText": "anova(${1:model});",
        "documentation": "Perform analysis of variance"
      },
      {
        "label": "t.test",
        "insertText": "t.test(${1:x}, ${2:y});",
        "documentation": "Perform a t-test"
      },
      {
        "label": "wilcox.test",
        "insertText": "wilcox.test(${1:x}, ${2:y});",
        "documentation": "Perform a Wilcoxon rank sum test"
      },
      {
        "label": "chisq.test",
        "insertText": "chisq.test(${1:x}, ${2:y});",
        "documentation": "Perform a chi-squared test"
      },
      {
        "label": "read.csv",
        "insertText": "read.csv('${1:file.csv}');",
        "documentation": "Read a CSV file into a data frame"
      },
      {
        "label": "write.csv",
        "insertText": "write.csv(${1:data}, '${2:file.csv}');",
        "documentation": "Write a data frame to a CSV file"
      },
      {
        "label": "install.packages",
        "insertText": "install.packages('${1:package}');",
        "documentation": "Install an R package"
      },
      {
        "label": "library",
        "insertText": "library(${1:package});",
        "documentation": "Load an R package"
      }
    ]
    },
    {name:'Ruby (2.7.0)',value: 'ruby',suggestion: [
      {
        label: "def",
        insertText: [
          "def ${1:method_name}(${2:parameters})",
          "\t$0",
          "end",
        ].join("\n"),
        
        documentation: "Define a method",

      },
      {
        label: "class",
        insertText: [
          "class ${1:ClassName}",
          "\tdef initialize(${2:parameters})",
          "\t\t$0",
          "\tend",
          "end",
        ].join("\n"),
        
        documentation: "Define a class with constructor",

      },
      {
        label: "attr_accessor",
        insertText: "attr_accessor :${1:attribute}",
        
        documentation: "Define getter and setter methods for an attribute",

      },
      {
        label: "if",
        insertText: [
          "if ${1:condition}",
          "\t$0",
          "end",
        ].join("\n"),
        
        documentation: "If statement",

      },
      {
        label: "unless",
        insertText: [
          "unless ${1:condition}",
          "\t$0",
          "end",
        ].join("\n"),
        
        documentation: "Unless statement",

      },
      {
        label: "else",
        insertText: [
          "else",
          "\t$0",
          "end",
        ].join("\n"),
        
        documentation: "Else statement",

      },
      {
        label: "elsif",
        insertText: [
          "elsif ${1:condition}",
          "\t$0",
          "end",
        ].join("\n"),
        
        documentation: "Elsif statement",

      },
      {
        label: "case",
        insertText: [
          "case ${1:expression}",
          "\twhen ${2:value1}",
          "\t\t$0",
          "\twhen ${3:value2}",
          "\t\t$0",
          "\telse",
          "\t\t$0",
          "end",
        ].join("\n"),
        
        documentation: "Case statement",

      },
      {
        label: "while",
        insertText: [
          "while ${1:condition}",
          "\t$0",
          "end",
        ].join("\n"),
        
        documentation: "While loop",

      },
      {
        label: "until",
        insertText: [
          "until ${1:condition}",
          "\t$0",
          "end",
        ].join("\n"),
        
        documentation: "Until loop",

      },
      {
        label: "for",
        insertText: [
          "for ${1:item} in ${2:collection}",
          "\t$0",
          "end",
        ].join("\n"),
        
        documentation: "For loop",

      },
      {
        label: "each",
        insertText: [
          "${1:collection}.each do |${2:item}|",
          "\t$0",
          "end",
        ].join("\n"),
        
        documentation: "Each loop",

      },
      {
        label: "times",
        insertText: [
          "${1:number}.times do",
          "\t$0",
          "end",
        ].join("\n"),
        
        documentation: "Times loop",

      },
      {
        label: "begin",
        insertText: [
          "begin",
          "\t$0",
          "rescue ${1:Exception} => ${2:e}",
          "\t$0",
          "end",
        ].join("\n"),
        
        documentation: "Begin-Rescue-End block",

      },
      {
        label: "module",
        insertText: [
          "module ${1:ModuleName}",
          "\t$0",
          "end",
        ].join("\n"),
        
        documentation: "Define a module",

      },
      {
        label: "require",
        insertText: "require '${1:library}'",
        
        documentation: "Require a library",

      },
      {
        label: "include",
        insertText: "include ${1:Module}",
        
        documentation: "Include a module",

      },
      {
        label: "attr_reader",
        insertText: "attr_reader :${1:attribute}",
        
        documentation: "Define a getter method for an attribute",

      },
      {
        label: "attr_writer",
        insertText: "attr_writer :${1:attribute}",
        
        documentation: "Define a setter method for an attribute",

      },
      {
        label: "private",
        insertText: "private ${1:method_name}",
        
        documentation: "Define a private method",

      },
      {
        label: "protected",
        insertText: "protected ${1:method_name}",
        
        documentation: "Define a protected method",

      },
      {
        label: "public",
        insertText: "public ${1:method_name}",
        
        documentation: "Define a public method",

      },
      {
        label: "alias",
        insertText: "alias ${1:new_name} ${2:old_name}",
        
        documentation: "Create an alias for a method or variable",

      },
      // Add more Ruby suggestions here
    ]
    
    // Continue adding more Ruby suggestions...
    // ...
    },
    {name:'Rust (1.40.0)',value: 'rust',suggestion:[
      {
        "label": "fn",
        "insertText": "fn ${1:functionName}(${2:parameters}) {\n    ${3:// function body}\n}",
        "documentation": "Define a new function"
      },
      {
        "label": "struct",
        "insertText": "struct ${1:StructName} {\n    ${2:fields}\n}",
        "documentation": "Define a new struct"
      },
      {
        "label": "enum",
        "insertText": "enum ${1:EnumName} {\n    ${2:variants}\n}",
        "documentation": "Define a new enum"
      },
      {
        "label": "impl",
        "insertText": "impl ${1:Type} {\n    ${2:methods}\n}",
        "documentation": "Implement methods for a type"
      },
      {
        "label": "match",
        "insertText": "match ${1:expression} {\n    ${2:pattern} => ${3:body},\n}",
        "documentation": "Pattern matching"
      },
      {
        "label": "if let",
        "insertText": "if let ${1:pattern} = ${2:expression} {\n    ${3:body}\n}",
        "documentation": "Conditional pattern matching"
      },
      {
        "label": "while",
        "insertText": "while ${1:condition} {\n    ${2:body}\n}",
        "documentation": "While loop"
      },
      {
        "label": "for",
        "insertText": "for ${1:item} in ${2:iterator} {\n    ${3:body}\n}",
        "documentation": "For loop"
      },
      {
        "label": "let",
        "insertText": "let ${1:variable} = ${2:value};",
        "documentation": "Declare a variable"
      },
      {
        "label": "mut",
        "insertText": "let mut ${1:variable} = ${2:value};",
        "documentation": "Declare a mutable variable"
      },
      {
        "label": "const",
        "insertText": "const ${1:CONST_NAME}: ${2:Type} = ${3:value};",
        "documentation": "Declare a constant"
      },
      {
        "label": "return",
        "insertText": "return ${1:value};",
        "documentation": "Return a value from a function"
      },
      {
        "label": "println",
        "insertText": "println!(\"${1:format}\", ${2:args});",
        "documentation": "Print a formatted message to the console"
      },
      {
        "label": "vec",
        "insertText": "vec!(${1:element1}, ${2:element2}, ...)",
        "documentation": "Create a new vector"
      },
      {
        "label": "String",
        "insertText": "String::from(\"${1:text}\")",
        "documentation": "Create a new String"
      },
      {
        "label": "Option",
        "insertText": "Option::Some(${1:value})",
        "documentation": "Create a Some variant of an Option"
      },
      {
        "label": "Result",
        "insertText": "Result::Ok(${1:ok_value})",
        "documentation": "Create an Ok variant of a Result"
      },
      {
        "label": "match Option",
        "insertText": "match ${1:option} {\n    Some(${2:inner}) => ${3:body},\n    None => ${4:default},\n}",
        "documentation": "Pattern match on an Option"
      },
      {
        "label": "match Result",
        "insertText": "match ${1:result} {\n    Ok(${2:inner}) => ${3:body},\n    Err(${4:error}) => ${5:error_handler},\n}",
        "documentation": "Pattern match on a Result"
      },
      {
        "label": "use",
        "insertText": "use ${1:crate}::{${2:items}};",
        "documentation": "Import items from a crate"
      }
    ]
    },
    {name:'Scala (2.13.2)',value: 'scala',suggestion:[
      {
        "label": "def",
        "insertText": "def ${1:functionName}(${2:parameters}): ${3:ReturnType} = {\n  ${4:// function body}\n}",
        "documentation": "Define a new function"
      },
      {
        "label": "val",
        "insertText": "val ${1:variableName}: ${2:Type} = ${3:value}",
        "documentation": "Declare an immutable variable"
      },
      {
        "label": "var",
        "insertText": "var ${1:variableName}: ${2:Type} = ${3:value}",
        "documentation": "Declare a mutable variable"
      },
      {
        "label": "class",
        "insertText": "class ${1:ClassName}(${2:constructorParams}) {\n  ${3:// class body}\n}",
        "documentation": "Define a new class"
      },
      {
        "label": "object",
        "insertText": "object ${1:ObjectName} {\n  ${2:// object body}\n}",
        "documentation": "Define a new object (singleton)"
      },
      {
        "label": "trait",
        "insertText": "trait ${1:TraitName} {\n  ${2:// trait body}\n}",
        "documentation": "Define a new trait"
      },
      {
        "label": "extends",
        "insertText": "extends ${1:BaseClass}",
        "documentation": "Inherit from a base class"
      },
      {
        "label": "with",
        "insertText": "with ${1:Trait}",
        "documentation": "Mix in a trait"
      },
      {
        "label": "if",
        "insertText": "if (${1:condition}) {\n  ${2:// code to execute}\n}",
        "documentation": "Conditional statement"
      },
      {
        "label": "else",
        "insertText": "else {\n  ${1:// code to execute}\n}",
        "documentation": "Else statement for conditional blocks"
      },
      {
        "label": "match",
        "insertText": "match ${1:expression} {\n  case ${2:pattern} => ${3:body}\n}",
        "documentation": "Pattern matching"
      },
      {
        "label": "for",
        "insertText": "for (${1:element} <- ${2:collection}) {\n  ${3:// code to repeat}\n}",
        "documentation": "For loop using elements of a collection"
      },
      {
        "label": "yield",
        "insertText": "yield ${1:expression}",
        "documentation": "Yield a value in a for-comprehension"
      },
      {
        "label": "while",
        "insertText": "while (${1:condition}) {\n  ${2:// code to execute}\n}",
        "documentation": "While loop"
      },
      {
        "label": "try",
        "insertText": "try {\n  ${1:// code that may throw exceptions}\n} catch {\n  case ${2:exceptionType} => ${3:// exception handling}\n}",
        "documentation": "Exception handling with try-catch"
      },
      {
        "label": "throw",
        "insertText": "throw new ${1:ExceptionType}(${2:message})",
        "documentation": "Throw an exception"
      },
      {
        "label": "import",
        "insertText": "import ${1:package}.${2:Symbol}",
        "documentation": "Import a package or symbol"
      },
      {
        "label": "package",
        "insertText": "package ${1:packageName}",
        "documentation": "Define a package"
      },
      {
        "label": "main",
        "insertText": "def main(args: Array[String]): Unit = {\n  ${1:// main program logic}\n}",
        "documentation": "Define the main method"
      }
    ]
    },
    {name:'SQL (SQLite 3.27.2)',value: 'sql',suggestion:[
      {
        "label": "SELECT",
        "insertText": "SELECT ${1:columns}\nFROM ${2:table}\nWHERE ${3:condition};",
        "documentation": "Retrieve data from a table"
      },
      {
        "label": "INSERT INTO",
        "insertText": "INSERT INTO ${1:table} (${2:columns})\nVALUES (${3:values});",
        "documentation": "Insert data into a table"
      },
      {
        "label": "UPDATE",
        "insertText": "UPDATE ${1:table}\nSET ${2:column1} = ${3:newValue1}, ${4:column2} = ${5:newValue2}\nWHERE ${6:condition};",
        "documentation": "Update data in a table"
      },
      {
        "label": "DELETE FROM",
        "insertText": "DELETE FROM ${1:table}\nWHERE ${2:condition};",
        "documentation": "Delete data from a table"
      },
      {
        "label": "CREATE TABLE",
        "insertText": "CREATE TABLE ${1:tableName} (\n  ${2:column1} ${3:dataType1},\n  ${4:column2} ${5:dataType2}${6:,}\n);",
        "documentation": "Create a new table"
      },
      {
        "label": "ALTER TABLE",
        "insertText": "ALTER TABLE ${1:tableName}\nADD COLUMN ${2:columnName} ${3:dataType};",
        "documentation": "Alter an existing table (add column)"
      },
      {
        "label": "DROP TABLE",
        "insertText": "DROP TABLE ${1:tableName};",
        "documentation": "Delete a table"
      },
      {
        "label": "CREATE INDEX",
        "insertText": "CREATE INDEX ${1:indexName} ON ${2:tableName} (${3:columns});",
        "documentation": "Create an index"
      },
      {
        "label": "DROP INDEX",
        "insertText": "DROP INDEX ${1:indexName};",
        "documentation": "Delete an index"
      },
      {
        "label": "INNER JOIN",
        "insertText": "INNER JOIN ${1:table2} ON ${2:table1.column = table2.column}",
        "documentation": "Inner join two tables"
      },
      {
        "label": "LEFT JOIN",
        "insertText": "LEFT JOIN ${1:table2} ON ${2:table1.column = table2.column}",
        "documentation": "Left join two tables"
      },
      {
        "label": "RIGHT JOIN",
        "insertText": "RIGHT JOIN ${1:table2} ON ${2:table1.column = table2.column}",
        "documentation": "Right join two tables"
      },
      {
        "label": "FULL OUTER JOIN",
        "insertText": "FULL OUTER JOIN ${1:table2} ON ${2:table1.column = table2.column}",
        "documentation": "Full outer join two tables"
      },
      {
        "label": "GROUP BY",
        "insertText": "GROUP BY ${1:column}",
        "documentation": "Group rows by a column"
      },
      {
        "label": "HAVING",
        "insertText": "HAVING ${1:aggregateFunction(column) ${2:condition}}",
        "documentation": "Filter groups based on aggregate values"
      },
      {
        "label": "ORDER BY",
        "insertText": "ORDER BY ${1:column1} ${2:ASC/DESC}",
        "documentation": "Sort rows by one or more columns"
      },
      {
        "label": "LIMIT",
        "insertText": "LIMIT ${1:number}",
        "documentation": "Limit the number of rows returned"
      },
      {
        "label": "OFFSET",
        "insertText": "OFFSET ${1:number}",
        "documentation": "Skip a specified number of rows"
      },
      {
        "label": "UNION",
        "insertText": "SELECT ${1:columns}\nFROM ${2:table1}\nUNION\nSELECT ${3:columns}\nFROM ${4:table2};",
        "documentation": "Combine the result sets of two or more SELECT statements"
      }
    ]
    },
    {name:'Swift (5.2.3)',value: 'swift',suggestion:[
      {
        "label": "func",
        "insertText": "func ${1:functionName}(${2:parameters}) {\n    ${3:// function body}\n}",
        "documentation": "Define a new function"
      },
      {
        "label": "var",
        "insertText": "var ${1:variableName}: ${2:Type} = ${3:value}",
        "documentation": "Declare a mutable variable"
      },
      {
        "label": "let",
        "insertText": "let ${1:variableName}: ${2:Type} = ${3:value}",
        "documentation": "Declare an immutable constant"
      },
      {
        "label": "class",
        "insertText": "class ${1:ClassName} {\n    ${2:// class body}\n}",
        "documentation": "Define a new class"
      },
      {
        "label": "struct",
        "insertText": "struct ${1:StructName} {\n    ${2:// struct body}\n}",
        "documentation": "Define a new struct"
      },
      {
        "label": "enum",
        "insertText": "enum ${1:EnumName} {\n    ${2:// enum cases}\n}",
        "documentation": "Define a new enum"
      },
      {
        "label": "protocol",
        "insertText": "protocol ${1:ProtocolName} {\n    ${2:// protocol body}\n}",
        "documentation": "Define a new protocol"
      },
      {
        "label": "extension",
        "insertText": "extension ${1:Type} {\n    ${2:// extension body}\n}",
        "documentation": "Define an extension for a type"
      },
      {
        "label": "if",
        "insertText": "if ${1:condition} {\n    ${2:// code to execute}\n}",
        "documentation": "Conditional statement"
      },
      {
        "label": "else",
        "insertText": "else {\n    ${1:// code to execute}\n}",
        "documentation": "Else statement for conditional blocks"
      },
      {
        "label": "switch",
        "insertText": "switch ${1:value} {\n    case ${2:pattern}:\n        ${3:// code to execute}\n    default:\n        ${4:// default code}\n}",
        "documentation": "Switch statement"
      },
      {
        "label": "for-in",
        "insertText": "for ${1:item} in ${2:sequence} {\n    ${3:// code to repeat}\n}",
        "documentation": "For-in loop"
      },
      {
        "label": "while",
        "insertText": "while ${1:condition} {\n    ${2:// code to execute}\n}",
        "documentation": "While loop"
      },
      {
        "label": "repeat-while",
        "insertText": "repeat {\n    ${1:// code to execute}\n} while ${2:condition}",
        "documentation": "Repeat-while loop"
      },
      {
        "label": "guard",
        "insertText": "guard ${1:condition} else {\n    ${2:// else code}\n}",
        "documentation": "Guard statement"
      },
      {
        "label": "return",
        "insertText": "return ${1:value}",
        "documentation": "Return a value from a function"
      },
      {
        "label": "throw",
        "insertText": "throw ${1:error}",
        "documentation": "Throw an error"
      },
      {
        "label": "try",
        "insertText": "try ${1:expression}",
        "documentation": "Try an expression that can throw an error"
      },
      {
        "label": "do-catch",
        "insertText": "do {\n    ${1:// code that may throw}\n} catch ${2:pattern} {\n    ${3:// error handling}\n}",
        "documentation": "Do-catch error handling"
      },
      {
        "label": "import",
        "insertText": "import ${1:module}",
        "documentation": "Import a module"
      }
    ]
    },
    {name:'TypeScript (3.7.4)',value: 'typescript',suggestion:[]},
    {name:'TypeScript (5.0.3)',value: 'typescript',suggestion:[]},
    {name:'Visual Basic.Net (vbnc 0.0.0.5943)',value: 'vb',suggestion:[
      {
        "label": "Sub",
        "insertText": "Sub ${1:MethodName}(${2:parameters})\n    ${3:' Code here}\nEnd Sub",
        "documentation": "Define a new subroutine"
      },
      {
        "label": "Function",
        "insertText": "Function ${1:FunctionName}(${2:parameters}) As ${3:ReturnType}\n    ${4:' Code here}\n    Return ${5:ReturnValue}\nEnd Function",
        "documentation": "Define a new function"
      },
      {
        "label": "Dim",
        "insertText": "Dim ${1:VariableName} As ${2:DataType} = ${3:InitialValue}",
        "documentation": "Declare a variable"
      },
      {
        "label": "Const",
        "insertText": "Const ${1:ConstantName} As ${2:DataType} = ${3:Value}",
        "documentation": "Declare a constant"
      },
      {
        "label": "If",
        "insertText": "If ${1:Condition} Then\n    ${2:' Code if true}\nElseIf ${3:Condition2} Then\n    ${4:' Code if Condition2 is true}\nElse\n    ${5:' Code if all conditions are false}\nEnd If",
        "documentation": "Conditional statement"
      },
      {
        "label": "Select Case",
        "insertText": "Select Case ${1:Expression}\n    Case ${2:Value1}\n        ${3:' Code for Value1}\n    Case ${4:Value2}\n        ${5:' Code for Value2}\n    Case Else\n        ${6:' Code if none of the cases match}\nEnd Select",
        "documentation": "Select Case statement (switch)"
      },
      {
        "label": "For",
        "insertText": "For ${1:Variable} As ${2:DataType} = ${3:StartValue} To ${4:EndValue}\n    ${5:' Code here}\nNext",
        "documentation": "For loop"
      },
      {
        "label": "For Each",
        "insertText": "For Each ${1:Element} In ${2:Collection}\n    ${3:' Code here}\nNext",
        "documentation": "For Each loop"
      },
      {
        "label": "While",
        "insertText": "While ${1:Condition}\n    ${2:' Code here}\nEnd While",
        "documentation": "While loop"
      },
      {
        "label": "Do While",
        "insertText": "Do\n    ${1:' Code here}\nLoop While ${2:Condition}",
        "documentation": "Do While loop"
      },
      {
        "label": "Exit",
        "insertText": "Exit ${1:LoopType}",
        "documentation": "Exit a loop or subroutine"
      },
      {
        "label": "Continue",
        "insertText": "Continue ${1:LoopType}",
        "documentation": "Continue to the next iteration of a loop"
      },
      {
        "label": "Try",
        "insertText": "Try\n    ${1:' Code that may throw exceptions}\nCatch ${2:ExceptionType} As ${3:Exception}\n    ${4:' Exception handling code}\nFinally\n    ${5:' Cleanup code}\nEnd Try",
        "documentation": "Try-catch-finally error handling"
      },
      {
        "label": "Throw",
        "insertText": "Throw New ${1:ExceptionType}(${2:Message})",
        "documentation": "Throw an exception"
      },
      {
        "label": "Imports",
        "insertText": "Imports ${1:Namespace}",
        "documentation": "Import a namespace"
      },
      {
        "label": "Namespace",
        "insertText": "Namespace ${1:NamespaceName}\n    ${2:' Namespace code here}\nEnd Namespace",
        "documentation": "Define a new namespace"
      },
      {
        "label": "Class",
        "insertText": "Class ${1:ClassName}\n    ${2:' Class code here}\nEnd Class",
        "documentation": "Define a new class"
      },
      {
        "label": "Interface",
        "insertText": "Interface ${1:InterfaceName}\n    ${2:' Interface code here}\nEnd Interface",
        "documentation": "Define a new interface"
      },
      {
        "label": "Module",
        "insertText": "Module ${1:ModuleName}\n    ${2:' Module code here}\nEnd Module",
        "documentation": "Define a new module"
      }
    ]
    },
];
  