import { editor } from "../../types";
import { RootState } from "../root-reducer";

const getAllState = (state: RootState): editor.State => {
  return state.editor;
};

const getEditor = (state: RootState): editor.State["editor"] => {
  return state.editor.editor;
};

const getRemoteSelectionManager = (
  state: RootState
): editor.State["remoteSelectionManager"] => {
  return state.editor.remoteSelectionManager;
};

const getRemoteCursorManager = (
  state: RootState
): editor.State["remoteCursorManager"] => {
  return state.editor.remoteCursorManager;
};

const getContentManager = (
  state: RootState
): editor.State["contentManager"] => {
  return state.editor.contentManager;
};

const getIsReadOnly = (state: RootState): editor.State["isReadOnly"] => {
  return state.editor.isReadOnly;
};

const getSettings = (state: RootState): editor.State["settings"] => {
  return state.editor.settings;
};

const getMarkers = (state: RootState): editor.State["markers"] => {
  return state.editor.markers;
};

const getUserRole = (state: RootState): editor.State["userRole"] => {
  return state.editor.userRole;
};

const getUserName = (state: RootState): editor.State["userName"] => {
  return state.editor.userName;
};

const getOutput = (state: RootState): editor.State["outPut"] => {
  return state.editor.outPut;
};

const getCurrentUser = (state: RootState): editor.State["currentUser"] => {
  return state.editor.currentUser;
};

export const editorSelectors = {
  getAllState,
  getEditor,
  getRemoteSelectionManager,
  getRemoteCursorManager,
  getContentManager,
  getIsReadOnly,
  getSettings,
  getMarkers,
  getUserRole,
  getUserName,
  getCurrentUser
};
