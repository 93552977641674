import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//components
import { EditorHeader } from "./editor-header";
import { EditorFooter } from "./editor-footer";
import { EditorOffline } from "./editor-offline";
import { EditorOnline } from "./editor-online";
import { EditorPlayback } from "./editor-playback";
import { EditorStartOffline } from "./editor-start-offline";
//store
import { assessmentSelectors } from "../../store/assessment/assessment.selectors";
import { editorSelectors } from "../../store/editor/editor.selectors";
import { footerSelectors } from "../../store/footer/footer.selectors";

import Split from "react-split";
import cn from "classnames";
import fetchData from "../../hooks/api/postData";
import { getEnv } from "@urecruits/api";
import { setUserRole } from "../../store/editor/editor.actions";
import { useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const {API_RECRUITMENT}=getEnv()

export const Editor = ({ isWidthChanged,onSubmit }:{isWidthChanged:string,onSubmit?:()=>void}) => {
  const {checkUserPermission} = useHasPermission()
  const [user,setUser]=useState(null)
  //store
  const dispatch=useDispatch()
  const getUserRole = async () => {
    const res = await fetchData(`${API_RECRUITMENT}/api/user`);
    setUser(res)
    if(res?.companyId !== null){
      dispatch(setUserRole("recruiter"))
    }else{
      dispatch(setUserRole("candidate"))
    }
  };
  useEffect(()=>{checkUserPermission(['candidate','recruiter']) && getUserRole()},[])
  

  const { isOnline, isStartedSolving } = useSelector(
    assessmentSelectors.getAllState
  );
  const editorContainerRef = useRef<HTMLDivElement | null>(null);

  const { isReadOnly } = useSelector(editorSelectors.getAllState);

  const { consoleWarningOpen } = useSelector(footerSelectors.getAllState);

  const renderEditor = useCallback(() => {
    if (isOnline) {
      return (
        <EditorOnline
          isWidthChanged={isWidthChanged}
          editorContainerRef={editorContainerRef}
          user={user}
        />
      );
    } else if (isReadOnly) {
      return (
        <EditorPlayback
          isWidthChanged={isWidthChanged}
          editorContainerRef={editorContainerRef}
        />
      );
    } else if (isStartedSolving) {
      return (
        <EditorOffline
          isWidthChanged={isWidthChanged}
          editorContainerRef={editorContainerRef}
        />
      );
    } else {
      return <EditorStartOffline />;
    }
  }, [user, isOnline, isReadOnly, isStartedSolving, isWidthChanged]);

  return (
    <div className="codding__editor-wrapper">
      <EditorHeader />
      <Split
        sizes={[Infinity, 0]}
        direction="vertical"
        className={cn("split-vertical")}
        gutterSize={1}
      >
        <div
          className={
            consoleWarningOpen ? "editor-container consoleActive" : "editor-container"
          }
          ref={editorContainerRef}
        >
          {renderEditor()}
        </div>

        <EditorFooter onSubmit={onSubmit}/>
      </Split>
    </div>
  );
};
