import React, { useState, useEffect } from 'react';
import clockIc from "../../assets/icons/clock_ic.svg";
import { useSelector } from 'react-redux';
import { assessmentSelectors } from '../../store/assessment/assessment.selectors';
import { CodingModalsEnum } from '../../types/editor';
import { setModal } from '../../store/editor/editor.actions';
import { useDispatch } from 'react-redux';

function CountdownTimer() {
  const {duration} = useSelector(assessmentSelectors?.getAllState)
  const dispatch=useDispatch()
  const [time, setTime] = useState(duration * 60 * 60);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      } else {
        dispatch(setModal(CodingModalsEnum.congratulations))
        clearInterval(countdownInterval);
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
    
  }, [time]);

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  return (
    <div style={{ fontSize: '12px', pointerEvents: 'none' }}>
      <img
        src={clockIc}
        alt="clock ic"
        style={{
          minWidth: '12px',
          width: '12px',
          marginRight: '6px',
        }}
      />
      <div style={{ display: 'inline-block' }}>
        <span>{String(hours).padStart(2, '0')}</span>:
        <span>{String(minutes).padStart(2, '0')}</span>:
        <span>{String(seconds).padStart(2, '0')}</span>
      </div>
    </div>
  );
}

export default CountdownTimer;
