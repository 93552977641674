import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editorSelectors } from "../../store/editor/editor.selectors";
import CodeEditor from "../_elements/CodeEditor";
//hooks
import { assessmentSelectors } from "../../store/assessment/assessment.selectors";
import * as monaco from "monaco-editor";
import {
  setMarker, setRoomId
} from "../../store/editor/editor.actions";
import { getEnv } from "@urecruits/api";
import fetchData, { postData } from "../../hooks/api/postData";
import { footerSelectors } from "../../store/footer/footer.selectors";
import { fromMonaco } from "@hackerrank/firepad";
import { useParams } from "react-router-dom";
import 'firebase/database';
import firebase from "../../config/firebaseFirestore";
import { useHasPermission, AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const {API_RECRUITMENT}=getEnv()



export const EditorOffline = ({ editorContainerRef, isWidthChanged }) => {
  const {checkUserPermission} = useHasPermission()
  const editorRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const [userData,setUserData]=useState(null)
  const { editor, localAction, localParticipant,outPut } = useSelector(editorSelectors.getAllState);
  const { activeTask,assessmentTask } = useSelector(assessmentSelectors.getAllState);
  const [roomsCollection,setRoomsCollection]=useState({})
  const { runCode, activeLanguage,activePackage } = useSelector(footerSelectors.getAllState);
  const firepadRef = useRef(null);
  const {jobId,assessmentId}=useParams()
  const [previousTaskId, setPreviousTaskId] = useState(null);

  
 useEffect(()=>{
  // console.log('editor offline')
  let user;
  checkUserPermission('candidate') && fetchData(`${API_RECRUITMENT}/api/user`)
  .then((res) => {
    setUserData(res)
    user=res
  })
  .catch((error) => {
    console.error('Error fetching data:', error);
  });
  return(()=>{
    // console.log('in editor offline return ');
    firebase.database().goOffline();
  });
 },[]);

 useEffect(()=>{
  let roomId;
  if(userData){
    const activeTaskId = activeTask?.data.id;

    roomId=`take-home-${jobId}${userData?.id}${assessmentId}${activeTaskId}`
    
    if(!roomsCollection.hasOwnProperty(activeTaskId) ){
      // console.log('in effect create database ', activeTask?.data.id,' and previous task ',previousTaskId);
    // console.log('roomId',roomId);
      
      const db = firebase.database();
      const ref = db.ref(roomId);
      setRoomsCollection(prev=>{
       return{ 
          ...prev,
          [activeTaskId]:ref
        }
      });
    }
   
  }
  return(()=>{  
    checkUserPermission('candidate') && userData && postData(`${API_RECRUITMENT}/api/playback`,{
      playbackId: roomId,
      assessmentType: "take-home-task",
      candidateId: userData?.id,
        jobId: jobId,
        assessmentId:assessmentId
      })
  })
 },[activeTask,userData])

  useEffect(() => {
    if (!activeTask || !editor) {
      return;
    }

    if (firepadRef.current && previousTaskId) {
      firepadRef.current.dispose();
    }

    editor.focus();
    monaco.editor.setModelLanguage(editor.getModel(), activeTask.data.language);
    editor.setValue(activeTask.data.starterCode);
  }, [activeTask, editor]);

  useEffect(() => {
    if (!editor) {
      return;
    }
    const activeTaskId = activeTask.data.id;
    
    if(roomsCollection.hasOwnProperty(activeTaskId) && activeTaskId && (!previousTaskId || previousTaskId != activeTaskId) && userData){
      const initializeFirebase = async () => {
        const name = userData?.firstname;
        
        const newRoomId=`${jobId}${userData?.id}${assessmentId}${activeTask?.data.id}`
        dispatch(setRoomId(Number(newRoomId)));

        const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        firepadRef.current= fromMonaco(roomsCollection[activeTaskId],editor, {
          userColor: color,
          userId: userData.id,
          userName: name,
        });
      };
      setPreviousTaskId(activeTaskId);
      initializeFirebase();
    }
  }, [roomsCollection, editor, userData, activeTask, previousTaskId]);
 

  useEffect(() => {
    monaco?.editor?.onDidChangeMarkers(async ([uri]) => {
      const markers = monaco?.editor?.getModelMarkers({ resource: uri });
      dispatch(setMarker(markers));
    });
  }, [dispatch]);


  return (
    <AuthGuard module='candidate'>
    <div className="editor">
      <CodeEditor
        editorRef={editorRef}
        editorContainerRef={editorContainerRef}
        isWidthChanged={isWidthChanged}
      />
    </div>
    </AuthGuard>
  );
};
