import { useSelector, useDispatch } from "react-redux";
import { editorSelectors } from "../../store/editor/editor.selectors";
import { setTestCaseStatuses } from "../../store/assessment/assessment.actions";
import { assessmentSelectors } from "../../store/assessment/assessment.selectors";
import {
  TestCaseStatus,
  DataTypes,
} from "../../../coding-assessments/types/assessmentTask";
import { getArrayInputs } from "../../../coding-assessments/utils/testcase";
import { footerSelectors } from "../../store/footer/footer.selectors";
import { encode } from "base-64";
import { postData } from "../api/postData";
import { getEnv } from "@urecruits/api";
import { CodeExecutor, BatchCodeExecutor } from "./code_executor";
import {
  runCodeState,
  isTerminalLoading,
  setError,
  setSubmissionResults,
} from "../../store/footer/footer.actions";
import { getReplacedCodeList } from "../../utils/helpers/getReplacedCodeList";

const { API_ASSESSMENT } = getEnv();

export const useRunTestCases = () => {
  const dispatch = useDispatch();
  const { editor } = useSelector(editorSelectors.getAllState);
  const { testCaseStatuses } = useSelector(assessmentSelectors.getAllState);
  const { activeLanguage, submissionResults } = useSelector(
    footerSelectors.getAllState
  );

  const onRunTestCases = async (testcaseList, testCaseInputs, outputType) => {
    if (!activeLanguage?.id) alert("Please select the language");
    dispatch(isTerminalLoading(true));

    const source_code = editor?.getValue() || "";

    let updatedStatuses = [...testCaseStatuses];

    const replaceCodeList = getReplacedCodeList(
      testcaseList,
      testCaseInputs,
      source_code,
      activeLanguage?.id
    ); 
    const editorData = replaceCodeList.map((item) => ({
      language_id: item.language_id,
      source_code: item.source_code,
    }));
  
    try {
      const token_array = await postData(
        `${API_ASSESSMENT}/api/coding-area/run/batch`,
        editorData
      );
      const tokens = token_array.map((item) => item.token);

      const result: any = await BatchCodeExecutor(tokens);
      const submissionResults = result?.submissions || [];
      for (const submissionResult of submissionResults) {

        const searchToken = submissionResult.token;
        const searchIndex = tokens.findIndex((token) => token === searchToken);
        const searchTestcaseId = replaceCodeList[searchIndex]?.testcaseId;
        const testCase = testcaseList.find(
          (testcase) => testcase.id === searchTestcaseId
        );
        let resultStatus = null;

        if (submissionResult.stdout) {
          let resultOutput = submissionResult.stdout.split("\n")[0];
          let expectedOutput = testCase.output;

          if (
            outputType &&
            (outputType === DataTypes.ARRAY_INTEGER ||
              outputType === DataTypes.ARRAY_STRING)
          ) {
            resultOutput = JSON.parse(resultOutput).join();
            expectedOutput = JSON.parse(expectedOutput).join();
          }

          if (outputType && outputType === DataTypes.INTEGER) {
            resultOutput = Number(resultOutput);
            expectedOutput = Number(expectedOutput);
          }

          if (resultOutput === expectedOutput) {
            resultStatus = TestCaseStatus.PASSED;
          } else {
            resultStatus = TestCaseStatus.FAILED;
          }
        } else if (submissionResult.compile_output || submissionResult.stderr) {
          resultStatus = TestCaseStatus.FAILED;
        }

        updatedStatuses = updatedStatuses.map((status) =>
          status.id === testCase.id
            ? { ...status, status: resultStatus }
            : status
        );
      }
       dispatch(setTestCaseStatuses(updatedStatuses));
       dispatch(setSubmissionResults(submissionResults));
       dispatch(isTerminalLoading(false));
  
    } catch (error) {
      console.error("Error during executing in judge0", error);
    }
  };

  return { onRunTestCases };
};
