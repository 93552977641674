import { forwardRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//hooks
import useInitEditor from "../../hooks/editor/use-init-editor";
import useCursor from "../../hooks/editor/use-cursor";
import useSelection from "../../hooks/editor/use-selection";
import useSharedData from "../../hooks/editor/use-shared-data";
//helpers
import { checkResize } from "../../utils/helpers/checkResize";
//store
import { resetEditorState } from "../../store/editor/editor.actions";
import { editorSelectors } from "../../store/editor/editor.selectors";

// @ts-ignore
self.MonacoEnvironment = {
  getWorkerUrl: function (moduleId, label) {
    if (label === "json") {
      return "/monaco-workers/json.worker.js";
    }
    if (label === "css" || label === "scss" || label === "less") {
      return "/monaco-workers/css.worker.js";
    }
    if (label === "html" || label === "handlebars" || label === "razor") {
      return "/monaco-workers/html.worker.js";
    }
    if (label === "typescript" || label === "javascript") {
      return "/monaco-workers/ts.worker.js";
    }
    return "/monaco-workers/editor.worker.js";
  },
};

const CodeEditor = ({
  editorRef,
  editorContainerRef,
  isWidthChanged,
}) => {
  const dispatch = useDispatch();

  const { editor } = useSelector(editorSelectors.getAllState);
  //hooks
  const [initEditor] = useInitEditor();
  const [initSharedCursors] = useCursor();
  const [initSharedSelection] = useSelection();
  const [initSharedData] = useSharedData();

  useEffect(() => {
    //init editor
    initEditor(editorRef);

    return () => {
      dispatch(resetEditorState());
    };
  }, []);

  useEffect(() => {
    if (!editor) {
      return;
    }
    //init managers
    initSharedCursors();
    initSharedSelection();
    initSharedData();

    //resize events
    const resize = () => {
      editor.layout();
    };

    window.addEventListener("resize", resize);
    editorContainerRef.current.addEventListener("resize", resize);

    const observer = new MutationObserver(checkResize);
    observer.observe(editorContainerRef.current, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ["style"],
    });

    return () => {
      window.removeEventListener("resize", resize);
      editorContainerRef?.current?.removeEventListener("resize", resize);
      observer.disconnect();
      editor?.dispose();
    };
  }, [editor]);

  useEffect(() => {
    if (!editor) {
      return;
    }
    editor.layout();
  }, [isWidthChanged, editor]);

  return (
    <>
      <div id="monaco-editor" className="editor__container" ref={editorRef} />
      <div id="status" className="editor__statusBar" />
    </>
  );
};

export default forwardRef(CodeEditor);
