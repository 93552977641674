import React from 'react'

interface QuestionItemProps {
    title: string,
    description: string,
    languages: string[];
}

function QuestionItem({ title, description, languages }: QuestionItemProps) {

    const info = require('./../../../../../assets/icons/info.svg')

    return(
        <>
            <h6 className='listOfQuestions__question_item__title'>{title}</h6>
            <p className='listOfQuestions__question_item__description'>{description}</p>
            <div className='listOfQuestions__question_item__languages'>
                <span>{languages.length}{languages.length === 1 ? " language":" languages"}</span>
                <img className='listOfQuestions__question_item__info_icon' src={info} alt='info' />
            </div>
        </>
    )
}

export default QuestionItem