import { assessment } from "../../types";
import { RootState } from "../root-reducer";

const getAllState = (state: RootState): assessment.State => {
  return state.assessment;
};

const getAllList = (state: RootState): assessment.State["assessmentList"] => {
  return state.assessment.assessmentList;
};

export const assessmentSelectors = {
  getAllState,
  getAllList,
};
