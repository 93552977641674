import React from 'react';
import '../../../styles/components/_questionLoader.scss';

function QuestionLoader() {
  return (
    <div className="QuestionLoader">
      <div className="loader__spinner"></div>
    </div>
  );
}

export default QuestionLoader;