import React from "react";
import { assessmentSelectors } from "../../../../../store/assessment/assessment.selectors";
import { useSelector } from "react-redux";

function QuestionPageContent({ data }) {
const { isOnline } = useSelector(assessmentSelectors.getAllState);
return (
    <div>
     { isOnline ? 
        <>
            <div className='listOfQuestions__display__label'>#### Intro</div>
            <div className='listOfQuestions__display__text'>
                <p>{data.instruction}</p>
            </div>
            <div className="listOfQuestions__display__label">
            <p >#### Challenge - {data.name}</p>
            </div>

            <div className="listOfQuestions__display__text">
            <p>{data.description}</p>
            </div>
            <div className="listOfQuestions__display__text">
            <p>{data?.examples && data?.examples}</p>
            </div>
        </>
     :
     <>
     {data.questions?.map((question,index)=>{
     return (  
        <div key={index}>
          <p>{`Problem ${index+1}`}</p>
            <div className='listOfQuestions__display__label'>#### Intro</div>
            <div className='listOfQuestions__display__text'>
                <p>{question?.candidateInstruction}</p>
            </div>
            <div className="listOfQuestions__display__label">
            <p >#### Challenge - {question.name}</p>
            </div>
            <div className="listOfQuestions__display__text">
            <p>{question.description}</p>
            </div>
            <div className="listOfQuestions__display__text">
            <p>{data?.examples && data?.examples}</p>
            </div>
        </div>)
     })}
     </>}
    </div>
  );
}

export default QuestionPageContent;
