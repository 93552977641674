import { getEnv } from "@urecruits/api";
import fetchData from "../api/postData";

const {API_ASSESSMENT}=getEnv()

 /*
  * This is used for run code after getting the token 
  */
export const CodeExecutor=async(data)=>{
  const timeout = () => {
      return new Promise((resolve) => {
        setTimeout(async () => {
          // const res = await fetchData(`https://wtt-dev.urecruits.com/api/coding-area/${data?.token}`)
          const res = await fetchData(`${API_ASSESSMENT}/api/coding-area/${data?.token}`)
          if (res?.status?.description === 'Processing' || res?.status?.description === 'In Queue') {
            resolve(timeout())
          } else {
            resolve(res);
          }
        }, 1000);
      });
    }
    const result:any = await timeout()
    return result
}

export const BatchCodeExecutor=async(tokens) => {
  const timeout = () => {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const res = await fetchData(`${API_ASSESSMENT}/api/coding-area/batch/get?${tokens.map(token=> `tokens=${token}`).join("&")}`);
          if (res?.status?.description === 'Processing' || res?.status?.description === 'In Queue') {
            resolve(timeout())
          } else {
            resolve(res);
          }
        }, 1000);
      });
    }
    const result:any = await timeout();
    return result
}
