import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { editorSelectors } from "../../store/editor/editor.selectors";
import { footerSelectors } from "../../store/footer/footer.selectors";
import Checkbox from "../_elements/Checkbox";
import { encode } from "base-64";
import { postData } from "../../hooks/api/postData";
import { getEnv } from "@urecruits/api";
import { CodeExecutor } from "../../hooks/editor/code_executor";
import {
  TestCaseStatus,
  DataTypes,
} from "../../../coding-assessments/types/assessmentTask";
import { getArrayInputs } from "../../../coding-assessments/utils/testcase";
import { useDispatch } from "react-redux";
import { setTestCaseStatuses } from "../../store/assessment/assessment.actions";
import { assessmentSelectors } from "../../store/assessment/assessment.selectors";
import {useRunTestCases} from "../../hooks/editor/use-run-testcases";
const { API_ASSESSMENT } = getEnv();

export const TestCases = (props) => {
  const dispatch = useDispatch();
  const testCases = props.testCases || [];
  const outputType = props.outputType || null;
  const testCaseInputs = JSON.parse(props.testCaseInputs || null) || [];
  const [allChecked, setAllChecked] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const { modal, editor } = useSelector(editorSelectors.getAllState);
  const { activeLanguage } = useSelector(footerSelectors.getAllState);
  const { testCaseStatuses } = useSelector(assessmentSelectors.getAllState);
  const {onRunTestCases} = useRunTestCases();
  const handleSelectAll = (e) => {
    setAllChecked(e.target.checked);
    e.target.checked ? setSelectedList(testCases) : setSelectedList([]);
  };

  const onItemCheck = (item) => {
    const selected = selectedList.find(
      (selectedItem) => selectedItem.id === item.id
    );

    if (!selected) {
      setSelectedList([...selectedList, item]);
    } else {
      const filteredList = selectedList.filter(
        (selectedItem) => selectedItem.id !== item.id
      );
      setSelectedList(filteredList);
    }
  };

  const handleSelectAll1 = (e, testCases) => {
    setAllChecked(e.target.checked);
    e.target.checked ? setSelectedList(testCases) : setSelectedList([]);
  };

  const onItemCheck1 = (item) => {
    const selected = selectedList.find(
      (selectedItem) => selectedItem.id === item.id
    );

    if (!selected) {
      setSelectedList([...selectedList, item]);
    } else {
      const filteredList = selectedList.filter(
        (selectedItem) => selectedItem.id !== item.id
      );
      setSelectedList(filteredList);
    }
  };

  useEffect(() => {
    if (allChecked && selectedList.length < testCases.length) {
      setAllChecked(false);
    }

    if (!allChecked && selectedList.length === testCases.length) {
      setAllChecked(true);
    }
  }, [selectedList, allChecked]);

  useEffect(() => {
    const updatedtestcaseStatues = testCases.map((testcase) => {
      const status  = testCaseStatuses.find(el=> el.id === testcase.id)?.status || null;
      return  { id: testcase.id, status }
    })
    dispatch(
      setTestCaseStatuses(updatedtestcaseStatues)
    );
  }, [testCases]);

  const handleRunTestCases = async () => {
    if (!Array.isArray(selectedList)) return;

    if (selectedList.length === 0) {
      //TODO: code to display info to select atleast one test case
      return;
    }
    onRunTestCases(selectedList, testCaseInputs, outputType);
  };

  return (
    <>
      {modal === "listOfQuestions" ? (
        <div>
          {testCases?.questions?.map((info, index) => {
            return (
              <div key={index} className="test-cases">
                <p className="test-cases__description">
                  Test cases verify your program's functionality. When you press
                  Run, each set of arguments is passed into your program and the
                  output is compared with the expected result.
                </p>
                <div className="test-cases__container scroll">
                  <div className="test-cases__header">
                    <Checkbox
                      id="0"
                      name="task"
                      isChecked={allChecked}
                      onChange={(e) => handleSelectAll1(e, info?.testcases)}
                    />
                    <div className="test-cases__items">
                      <p>Argument</p>
                      <p>Expected result</p>
                    </div>
                  </div>
                  {info?.testcases?.map((task) => (
                    <div className="test-cases__content" key={task.id}>
                      <Checkbox
                        id={task.id}
                        name={task.input}
                        isChecked={selectedList.find(
                          (item) => item.id === task.id
                        )}
                        onChange={() => onItemCheck1(task)}
                      />
                      <div className="test-cases__items">
                        <p>{task.input}</p>

                        <p>{task.output}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="test-cases">
          <p className="test-cases__description">
            Test cases verify your program's functionality. When you press Run,
            each set of arguments is passed into your program and the output is
            compared with the expected result.
          </p>
          <div className="test-cases__container scroll">
            <div className="test-cases__header">
              <Checkbox
                id="0"
                name="task"
                isChecked={allChecked}
                onChange={(e) => handleSelectAll(e)}
              />
              <div className="test-cases__items">
                <p>Argument</p>
                <p>Expected result</p>
                <p>Status</p>
              </div>
            </div>
            {testCases.map((task) => (
              <div className="test-cases__content" key={task.id}>
                <Checkbox
                  id={task.id}
                  name={task.name}
                  isChecked={selectedList.find((item) => item.id === task.id)}
                  onChange={() => onItemCheck(task)}
                />
                <div className="test-cases__items">
                  <p>{task.input}</p>

                  <p>{task.output}</p>
                  {(testCaseStatuses.find((el) => el.id === task.id)?.status ===
                    TestCaseStatus.PASSED && (
                    <p className={`test-cases__status passed`}>Passed</p>
                  )) ||
                    (testCaseStatuses.find((el) => el.id === task.id)
                      ?.status === TestCaseStatus.FAILED && (
                      <p className={`test-cases__status failed`}>Failed</p>
                    )) ||
                    (testCaseStatuses.find((el) => el.id === task.id)
                      ?.status === TestCaseStatus.RUNNING && (
                      <p className={`test-cases__status running`}>Running</p>
                    )) || <p className={`test-cases__status`}>Pending</p>}
                </div>
              </div>
            ))}
          </div>
          {modal !== "listOfQuestions" && (
            <div className="test-cases__buttonWrapper">
              <button
                className="c-btn c-btn--green"
                onClick={handleRunTestCases}
              >
                Run selected test case
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};;
