import { useSelector } from "react-redux";
import { editorSelectors } from "../../store/editor/editor.selectors";
import { videoMeetingSelectors } from "../../store/video-meeting/assessment.selectors";

export const EditorHeader = () => {
  const participants = useSelector(videoMeetingSelectors.getAllParticipants);
  const { isReadOnly } = useSelector(editorSelectors.getAllState);

  return (
    <div className="header-editor">
    {!isReadOnly && participants[0] && (
      <div className="header-editor__me">Me</div>
    )}
    {participants.map((user, index: number) => (
      isReadOnly ? (
        <div className="header-member" key={index}>
          <div className="header-member__icon">
            {user?.charAt(0)}
          </div>
          <p className="header-member__name">{user}</p>
        </div>
      ) : (
       index !==0 && <div className="header-member" key={index}>
          <div className="header-member__icon">
            {user?.charAt(0)}
          </div>
          <p className="header-member__name">{user}</p>
        </div>
      )
      ))}
    </div>
  );
};
