export enum actionTypes {
  SET_ASSESSMENT_TASK = "assessment/SET_ASSESSMENT_TASK",
  SET_ACTIVE_TASK = "assessment/SET_ACTIVE_TASK",
  SET_IS_ONLINE = "assessment/SET_IS_ONLINE",
  SET_IS_START_SOLVING = "assessment/SET_IS_START_SOLVING",
  RESET_STATE = "assessment/RESET_STATE",
  SET_ASSESSMENT_LIST = "assessment/SET_ASSESSMENT_LIST",
  SET_DURATION = "assessment/SET_DURATION",
  SET_TEST_CASE_STATUSES = "assessment/SET_TEST_CASE_STATUSES",
}

export type Actions =
  | SetAssessmentTask
  | SetActiveTask
  | SetIsOnline
  | SetIsStartSolving
  | ResetAssessmentState
  | SetAssessmentList
  | SetDuration;

export interface SetAssessmentTask {
  type: actionTypes.SET_ASSESSMENT_TASK;
  payload: any;
}

export interface SetActiveTask {
  type: actionTypes.SET_ACTIVE_TASK;
  payload: any;
}

export interface SetIsOnline {
  type: actionTypes.SET_IS_ONLINE;
  payload: boolean;
}

export interface ResetAssessmentState {
  type: actionTypes.RESET_STATE;
}

export interface SetIsStartSolving {
  type: actionTypes.SET_IS_START_SOLVING;
  payload: boolean;
}

export interface SetAssessmentList {
  type: actionTypes.SET_ASSESSMENT_LIST;
  payload: any;
}

export interface SetDuration {
  type: actionTypes.SET_DURATION;
  payload: any;
}

export interface SetTestCaseStatuses {
  type: actionTypes.SET_TEST_CASE_STATUSES;
  payload: any;
}
