import { assessment as types } from "../../types";
import * as actions from "../../types/assessment/actions.types";

export const setAssessmentTask = (
  payload: types.State["assessmentTask"]
): actions.SetAssessmentTask => ({
  type: types.actionTypes.SET_ASSESSMENT_TASK,
  payload,
});

export const setActiveTask = (
  payload: types.State["activeTask"]
): actions.SetActiveTask => ({
  type: types.actionTypes.SET_ACTIVE_TASK,
  payload,
});

export const setIsOnline = (
  payload: types.State["isOnline"]
): actions.SetIsOnline => ({
  type: types.actionTypes.SET_IS_ONLINE,
  payload,
});

export const setDuration = (
  payload: types.State["duration"]
): actions.SetDuration => ({
  type: types.actionTypes.SET_DURATION,
  payload,
});

export const setTestCaseStatuses = (
  payload: types.State["testCaseStatuses"]
): actions.SetTestCaseStatuses => ({
  type: types.actionTypes.SET_TEST_CASE_STATUSES,
  payload,
});

export const setStartSolving = (
  payload: types.State["isStartedSolving"]
): actions.SetIsStartSolving => ({
  type: types.actionTypes.SET_IS_START_SOLVING,
  payload,
});

export const resetAssessmentState = (): actions.ResetAssessmentState => ({
  type: types.actionTypes.RESET_STATE,
});

export const setAssessmentList = (
  payload: types.State["assessmentList"]
): actions.SetAssessmentList => ({
  type: types.actionTypes.SET_ASSESSMENT_LIST,
  payload,
});
