import { videoMeeting as types } from "../../types";
import produce from "immer";

export const initialValue: types.State = {
  videoMeetingData:{
    room:{},
    isVideoModalOpen:false,
    isAudioEnable:false,
    isVideoEnable:false,
  },
  isEndInterView:false,
  isSnackBarOpen:{
    status:false,
    message:""
  },
  allParticipants:[],
  stream:null,
  meetingStream:true,
};

const reducer = (state = initialValue, action) => {
  switch (action.type) {
    case types.actionTypes.SET_VIDEO_MEETING:
      return produce(state, (draft) => {
        draft.videoMeetingData = action.payload;
      });
    case types.actionTypes.SET_IS_END_INTERVIEW:
      return produce(state, (draft) => {
        draft.isEndInterView=action.payload
      });
      case types.actionTypes.SET_IS_SNACKBAR_OPEN:
        return produce(state, (draft) => {
          draft.isSnackBarOpen.status = action.payload?.status;
          draft.isSnackBarOpen.message = action.payload?.message;
        });
      case types.actionTypes.SET_STREAM:
        return produce(state, (draft) => {
          draft.stream = action.payload;
        });
      case types.actionTypes.SET_MEETING_STREAM:
        return produce(state, (draft) => {
          draft.meetingStream = action.payload;
        });
        case types.actionTypes.SET_ALL_PARTICIPANTS:
          return produce(state, (draft) => {
            draft.allParticipants=action.payload
          });
    default:
      return state;
  }
};

export default reducer;
