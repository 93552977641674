import React, { useState } from "react";
import CodingSelect from "../../../../_elements/CodingSelect";

const options = [
  { index: 1, label: "Java2" },
];

function QuestionPageHeader({ data }) {
  const [select, setSelect] = useState();

  return (
    <div className="listOfQuestions__header">
      <div className="listOfQuestions__header__content">
        <p className="listOfQuestions__header__title">{data.title}</p>
        <p className="listOfQuestions__header__description">
          {data.description}
        </p>
      </div>
      <div className="listOfQuestions__header__selector">
        <CodingSelect
          placeholder={data.languages}
          options={options as any}
          selected={select}
          setSelected={setSelect}
        />
      </div>
    </div>
  );
}

export default QuestionPageHeader;
