import React, {useEffect, useState} from "react";
import CodingSelect from "../../../../_elements/CodingSelect";
import fetchData from "../../../../../hooks/api/fetchData";
import { getEnv } from "@urecruits/api";
const {API_ASSESSMENT}=getEnv()

function Filters({searchData,filterData}) {

  const [selected, setSelected] = useState();
  const [languagesData, setLanguagesData] = useState([]);

  useEffect(() => {
      fetchData(`${API_ASSESSMENT}/api/languages`, setLanguagesData)
        .then((res) => {res.data})
  }, []);

  useEffect(()=>{
    if(selected){
      filterData(selected)
    }
  },[selected])

  const search = require("./../../../../../assets/icons/search.svg");

  return (
    <div className="listOfQuestions__filters">
      <label
        htmlFor="sarchLanguage"
        className="listOfQuestions__filters__label"
      >
        <input id="sarchLanguage" type="text" placeholder="Search questions" onChange={(e)=>{
          searchData(e?.target?.value)
        }} />
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.20005 13.5998C10.7347 13.5998 13.6 10.7344 13.6 7.1998C13.6 3.66518 10.7347 0.799805 7.20005 0.799805C3.66543 0.799805 0.800049 3.66518 0.800049 7.1998C0.800049 10.7344 3.66543 13.5998 7.20005 13.5998Z"
            stroke="#C1C5CB"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.2 15.2002L11.72 11.7202"
            stroke="#C1C5CB"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </label>

      <CodingSelect
        placeholder="Filter by languages"
        options={languagesData}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
}

export default Filters;
