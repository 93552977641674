import React, { FC, useState, Fragment } from "react";
import * as monaco from "monaco-editor";

interface IWarnings {
  consoleIcon: any;
  consoleArrow: any;
  color?: string | null;
  lightColor?: string;
  marker?: monaco.editor.IMarkerData | null;
  editor: string | null;
}

const Warnings: FC<IWarnings> = ({
  consoleIcon,
  consoleArrow,
  color,
  lightColor,
  marker,
  editor,
}) => {
  const [isArrowClicked, setIsArrowClicked] = useState(false);
  return (
    <>
      <div
        className="warning"
        style={{
          background: lightColor,
          borderTop: `1px solid ${color}`,
        }}
      >
        <img
          className="warning__img"
          src={consoleIcon}
          alt="consoleErrorIcon"
        />
        <button
          className="warning__button"
          onClick={() => setIsArrowClicked(!isArrowClicked)}
        >
          <img
            className={`console-arrow ${
              isArrowClicked && "console-arrow__turn"
            }`}
            src={consoleArrow}
            alt="console_red_arrow"
          />
          <span style={{ color: color }}>
            {marker?.message} ({marker?.endLineNumber}:{marker.startColumn})
          </span>
        </button>
        {isArrowClicked && (
          <div className="warning__arrow">
            <div className="ul-warning-arrow">
              {editor?.split("\n").map((item, id) => (
                <Fragment key={id}>
                  {id + 1 === marker?.endLineNumber ? (
                    <div style={{ color: color }}> {">"} </div>
                  ) : (
                    <div>&nbsp;</div>
                  )}
                </Fragment>
              ))}
            </div>
            <ul className="ul-warning">
              <div className="warning__code">
                <div>
                  {editor?.split("\n").map((item, id) => (
                    <li key={id} style={{ color: color }}></li>
                  ))}
                </div>
                <pre className="warning__editor" style={{ color: color }}>
                  {editor}
                </pre>
              </div>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(Warnings);
