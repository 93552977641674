import React from "react";
import { useSelector } from "react-redux";
import { assessmentSelectors } from "../../../../../store/assessment/assessment.selectors";

function StarterCode({ data }) {
  const { isOnline } = useSelector(assessmentSelectors.getAllState);

  return (
    <>
      {isOnline ? (
        <div>
          <div className="listOfQuestions__display__text">
            <p>{data?.starterCode}</p>
          </div>
        </div>
        ) : (
          <div>
            {data?.questions?.map((question, index) => {
            return (
                <div key={index}>
                  <p>{`Problem ${index + 1}`}</p>
                  <div className="listOfQuestions__display__text">
                    <p>{question?.starterCode}</p>
                  </div>
                </div>
              );
            })}
          </div>
      )}
    </>
  );
}

export default StarterCode;
