import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//components
import InterviewJoinRoom from "../components/video-meeting/InterviewJoinRoom";
import MeetingPreview from "../components/video-meeting/meetingPreview";
import MessageDrawer from "../components/video-meeting/chat";
import { videoMeetingSelectors } from "../store/video-meeting/assessment.selectors";
import { conversationSelectors } from "../store/conversation/conversation.selectors";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { useLocation, useParams } from "react-router-dom";
import { SetStream, setIsSnackBarOpen } from "../store/video-meeting/assessment.actions";
import fetchData from "../hooks/api/postData";
import { getEnv } from "@urecruits/api";
import { useHasPermission, AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_RECRUITMENT, API_ASSESSMENT } = getEnv()

export const VideoInterview = (props) => {

  const {checkUserPermission} = useHasPermission()
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { room } = useParams();
  const params = useLocation();

  const state: any = (params.state)
  // let candidateId;
  // let jobId
  const [candidateId, setCandidateId] = useState<number>();
  const [jobId, setJobId] = useState<number>();
  const [meetingType, setMeetingType] = useState<string>();
  const [roomStatus, setRoomStatus] = useState<string>();

  const getEventByRoom = async () => {
    // await fetchData(`https://wtt-dev.urecruits.com/api/calendar/room-event/${room}`)
     await fetchData(`${API_ASSESSMENT}/api/calendar/room-event/${room}`)
      .then((res) => {
        if (res) {
          setCandidateId(res.candidateId);
          setJobId(res.jobId);
          setMeetingType(res.description);
        }
      })
  }
  const fetchEvents = async () => {
    !roomStatus &&  await fetchData(
      // `https://wtt-dev.urecruits.com/api/calendar/get?candidateId=${candidateId}&jobId=${jobId}&description=${meetingType}`
      `${API_ASSESSMENT}/api/calendar/get?candidateId=${candidateId}&jobId=${jobId}&description=${meetingType}`
    ).then((a) => {
      setRoomStatus(a?.data?.status);
      // console.log('status ',a?.data?.status);
    });
  };
  useEffect(() => {
    if (!state && candidateId && jobId && meetingType && checkUserPermission(['recruiter','candidate'])) {
      fetchEvents();
    }
  }, [candidateId,jobId, meetingType]);

  useEffect(() => {
    if (!state && checkUserPermission(['recruiter','candidate'])) {
      getEventByRoom();
    }
    else {
      for (const key in state) {
        if (state.hasOwnProperty(key)) {
          if (key === "candidateId") {
            setCandidateId(state[key]);
          }
          else if (key === "jobId") {
            setJobId(state[key]);
          }
          else if (key === "type") {
            setMeetingType(state[key]);
          }
          else if (key === "roomStatus") {
            setRoomStatus(state[key]);
          }
        }
      }
    }
  }, [])
  const { isOpen, channel } = useSelector(conversationSelectors.getAllState);
  const { status, message } = useSelector(videoMeetingSelectors.getIsSnackBarOpen);
  const {
    videoMeetingData,
  } = useSelector(videoMeetingSelectors.getAllState);

  useEffect(() => {
    if (videoMeetingData?.isVideoModalOpen === false && state === null) {
      dispatch(SetStream(null));
    }
    const handleBeforeUnload = () => {
      return true;
    };
    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  // useEffect(() => {
  //   if (!channel) return;
  //   const listener = async (msg: Message) => {
  //     const newMessage = {
  //       sid: msg.sid,
  //       text: msg.body,
  //       username: msg.author,
  //       dateCreated: msg.dateCreated,
  //       timeAgo: calculateTimeAgo(msg.dateCreated),
  //       media: msg.media,
  //       memberSid: msg.memberSid,
  //     };

  //     dispatch(addMessage(newMessage));
  //   };

  //   channel.on("messageAdded", listener);
  //   return () => {
  //     channel.removeListener("messageAdded", listener);
  //   };
  // }, [channel]);

  // const calculateTimeAgo = (date: Date) => {
  //   const dateObject = new Date(date);
  //   const hours = dateObject.getHours();
  //   const minutes = dateObject.getMinutes();
  //   const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  //   return formattedTime;
  // };


  useEffect(() => {
    const { room, isVideoEnable, isAudioEnable, isVideoModalOpen } = videoMeetingData || {};

    if (room?.localParticipant) {
      const enableTracks = (tracks) => {
        tracks.forEach((publication) => {
          publication?.track?.enable();
        });
      };

      const disableTracks = (tracks) => {
        tracks.forEach((publication) => {
          publication?.track?.disable();
        });
      };

      if (isVideoModalOpen === true) {
        if (isVideoEnable) {
          enableTracks(room.localParticipant.videoTracks);
        } else {
          disableTracks(room?.localParticipant?.videoTracks);
        }

        if (isAudioEnable) {
          enableTracks(room.localParticipant.audioTracks);
        } else {
          disableTracks(room?.localParticipant?.audioTracks);
        }
      }
    }
  }, [videoMeetingData?.isVideoModalOpen]);
  
  return (
    <AuthGuard module={['recruiter','candidate']}>
    <div className={"full-background-interview"}>
      {videoMeetingData?.isVideoModalOpen === false && <MeetingPreview id={room} candidateId={candidateId} jobId={jobId} meetingType={meetingType} roomStatus={roomStatus} />}
      {videoMeetingData?.isVideoModalOpen === true &&
        <div className="interview-container">
          <div className="interview-container__video" style={{ width: !isOpen && "100%" }}>
            <InterviewJoinRoom roomId={videoMeetingData?.room?.name} roomObj={videoMeetingData?.room} isVideoEnable={videoMeetingData?.isVideoEnable} isAudioEnable={videoMeetingData?.isAudioEnable} />
          </div>
          {isOpen && <div className={`interview-container__chat ${isOpen ? 'opened' : ''}`}>
            <MessageDrawer room={room} />
          </div>}
        </div>
      }
      <Snackbar
        open={status}
        autoHideDuration={3000}
        onClose={() => dispatch(setIsSnackBarOpen({ status: false, message: "" }))}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => dispatch(setIsSnackBarOpen({ status: false, message: "" }))}
          style={{ backgroundColor: "#099C73" }}
        >
          {message}
        </MuiAlert>
      </Snackbar>
    </div>
    </AuthGuard>
  );
};
