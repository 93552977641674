import { conversation as types } from "../../types";
import * as actions from "../../types/conversation/actions.types"

export const setChatOpen = (
  payload: types.State["isOpen"]
): actions.SetIsChatOpen => ({
  type: types.actionTypes.SET_CHAT_OPEN,
  payload,
});

export const setRoom = (
  payload: types.State["room"]
): actions.SetRoom => ({
  type: types.actionTypes.SET_ROOM,
  payload,
});

export const setUserIdentity = (
  payload: types.State["userIdentity"]
): actions.SetUserIdentity => ({
  type: types.actionTypes.SET_USER_IDENTITY,
  payload,
});

export const setClient = (
  payload: types.State["client"]
): actions.SetClient => ({
  type: types.actionTypes.SET_CLIENT,
  payload,
});

export const setChannel = (
  payload: types.State["channel"]
): actions.SetChannel => ({
  type: types.actionTypes.SET_CONVERSATION,
  payload,
});

export const addMessage = (
  payload: types.State["messagesList"]
): actions.SetMessage => ({
  type: types.actionTypes.SET_MESSAGE,
  payload,
});

export const setChatToken = (
  payload: types.State["token"]
): actions.SetChatToken => ({
  type: types.actionTypes.SET_CHAT_TOKEN,
  payload,
});

