import { editor as types } from "../../types";
import produce from "immer";

export const initialValue: types.State = {
  editor: null,
  remoteSelectionManager: null,
  remoteCursorManager: null,
  contentManager: null,
  isReadOnly: false,
  localAction: null,
  participants: [],
  localParticipant: null,
  roomId: null,
  modal: null,
  markers: null,
  modalClose:false,
  userRole:"",
  userName:"",
  outPut :null,
  settings: {
    autoBrackers: true,
    minimap: true,
    codeCompletion: true,
    errorHighlightning: true,
    colorScheme: types.colorSchemeEnun.light,
    view: types.viewEnum.left,
    mode: {
      id: 0,
      name: "VS Code",
      data: "vscode",
    },
    fontSize: {
      id: 4,
      name: "16 px",
      data: "16px",
    },
    tabSpacing: {
      id: 0,
      name: "2 (default)",
      data: 2,
    },
  },
  currentUser:""
};

const reducer = (state = initialValue, action) => {
  switch (action.type) {
    case types.actionTypes.SET_EDITOR:
      return produce(state, (draft) => {
        draft.editor = action.payload;
      });
    case types.actionTypes.SET_SELECTION_MANAGER:
      return produce(state, (draft) => {
        draft.remoteSelectionManager = action.payload;
      });
    case types.actionTypes.SET_CURSOR_MANAGER:
      return produce(state, (draft) => {
        draft.remoteCursorManager = action.payload;
      });
    case types.actionTypes.SET_CONTENT_MANAGER:
      return produce(state, (draft) => {
        draft.contentManager = action.payload;
      });
    case types.actionTypes.SET_READ_ONLY:
      return produce(state, (draft) => {
        draft.isReadOnly = action.payload;
      });
    case types.actionTypes.SET_LOCAL_ACTION:
      return produce(state, (draft) => {
        draft.localAction = action.payload;
      });
    case types.actionTypes.SET_REMOTE_PARTICIPANTS:
      return produce(state, (draft) => {
        draft.participants = 
          [...state?.participants,action.payload] as any
      });
    case types.actionTypes.SET_LOCAL_PARTICIPAN:
      return produce(state, (draft) => {
        draft.localParticipant = action.payload;
      });
    case types.actionTypes.SET_ROOM_ID:
      return produce(state, (draft) => {
        draft.roomId = action.payload;
      });
    case types.actionTypes.SET_MODAL:
      return produce(state, (draft) => {
        draft.modal = action.payload;
      });
    case types.actionTypes.SET_MODAL_CLOSE:
      return produce(state, (draft) => {
        draft.modalClose = action.payload;
      });
    case types.actionTypes.SET_SETTING:
      return produce(state, (draft) => {
        draft.settings[action.payload.key] = action.payload.data;
      });
    case types.actionTypes.SET_MARKERS:
      return produce(state, (draft) => {
        draft.markers = action.payload;
      });
    case types.actionTypes.SET_USER_ROLE:
      return produce(state, (draft) => {
        draft.userRole = action.payload;
      });
    case types.actionTypes.SET_OUTPUT:
      return produce(state, (draft) => {
        draft.outPut = action.payload;
      });
    case types.actionTypes.SET_CURRENT_USER:
      return produce(state, (draft) => {
        draft.currentUser = action.payload;
      });
    case types.actionTypes.RESET_EDITOR_STATE:
      return { ...initialValue };

    default:
      return state;
  }
};

export default reducer;
