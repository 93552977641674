export const ArrowRight = () => {
  return (
    <svg
      width="5"
      height="8"
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.52679 4L0.687499 6.83929C0.473213 7.05357 0.473213 7.375 0.687499 7.58929C0.901785 7.80357 1.22321 7.80357 1.4375 7.58929L4.65179 4.375C4.86607 4.16071 4.86607 3.83929 4.65179 3.625L1.4375 0.410713C1.33036 0.30357 1.16964 0.25 1.0625 0.25C0.955356 0.25 0.794642 0.30357 0.687499 0.410713C0.473213 0.624999 0.473213 0.946428 0.687499 1.16071L3.52679 4Z"
        fill="#C1C5CB"
      />
    </svg>
  );
};

export const ArrowLeft = () => {
  return (
    <svg
      width="5"
      height="8"
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.47321 4L4.3125 1.16071C4.52679 0.946429 4.52679 0.625 4.3125 0.410714C4.09822 0.196429 3.77679 0.196429 3.5625 0.410714L0.348214 3.625C0.133929 3.83929 0.133929 4.16071 0.348214 4.375L3.5625 7.58929C3.66964 7.69643 3.83036 7.75 3.9375 7.75C4.04464 7.75 4.20536 7.69643 4.3125 7.58929C4.52679 7.375 4.52679 7.05357 4.3125 6.83929L1.47321 4Z"
        fill="#C1C5CB"
      />
    </svg>
  );
};
